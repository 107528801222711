import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row, Button, ButtonGroup, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import React, { useEffect, useState, useCallback, Fragment } from 'react';

import Loader from '../common/Loader';
import { approveDocument, rejectDocument, getUserData, updateUserDataById, approveWorkerPremier } from '../../api/user';
import { toast } from 'react-toastify';
import FalconCardHeader from '../common/FalconCardHeader';
import DatePromptModal from '../common/DatePromptModal';
import moment from 'moment';
import { getShiftDiscontentByUId } from '../../api/shiftdiscontents';
import UserShiftsTable from './UserShiftsTable';
import { firebase } from '../../firebase';
import { AdditionalCapabilities, NurseType, ShiftReviewQuestion } from '../../constants';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import CustomInput from 'reactstrap/lib/CustomInput';
import ModalBody from 'reactstrap/lib/ModalBody';
import { capitalize } from 'lodash';
import SuspendEventsTable from '../common/SuspendEventsTable';
import ChangeEmailModal from '../common/ChangeEmailModal';
import { changeEmail, getAuthUser } from '../../api/auth';
import { getReviewsByNurseId } from '../../api/reviews';
import { average } from '../../helpers/utils';
import { Badge } from '@chakra-ui/react';
import { PremierStatus } from '../../types';
import UserAccountEditModal from '../common/UserAccountEditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LicensedStates } from '../common/LicensedStates';

const CustomerDetailRow = ({ title, text }) => (
  <Row>
    <Col xs={5} sm={4}>
      {' '}
      <p className="font-weight-semi-bold fs--1"> {title} </p>{' '}
    </Col>
    <Col>
      <p className="fs--1">{text}</p>{' '}
    </Col>
  </Row>
);

const NurseTypeRow = ({ title, nurseType, setNurseType, userId }) => (
  <Row>
    <Col xs={5} sm={4}>
      {' '}
      <p className="font-weight-semi-bold fs--1"> {title} </p>{' '}
    </Col>
    <Col>
      <CustomInput
        type="select"
        id="eventNurseType"
        name="nurseType"
        value={nurseType}
        onChange={async ({ target }) => {
          try {
            const newNurseType = target.value;
            await updateUserDataById({ nurseType: newNurseType }, userId);
            setNurseType(newNurseType);
            toast.success('Nurse type updated successfully');
          } catch (e) {
            toast.error('Failed to update nurseType');
          }
        }}
      >
        <option value={NurseType.CAREGIVER}>{NurseType.CAREGIVER}</option>
        <option value={NurseType.CNA}>{NurseType.CNA}</option>
        <option value={NurseType.LPN}>{NurseType.LPN}</option>
        <option value={NurseType.RN}>{NurseType.RN}</option>
        <option disabled value="None">
          None
        </option>
      </CustomInput>
    </Col>
  </Row>
);

const SuspendModal = ({ isSuspend, isOpen, setIsOpen, onSave }) => {
  const actionText = isSuspend ? 'suspend' : 'rehire';

  const [reason, setReason] = useState('');

  const closeBtn = (
    <button className="close font-weight-normal" onClick={() => setIsOpen(false)}>
      &times;
    </button>
  );
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader close={closeBtn}>{capitalize(actionText)}</ModalHeader>
      <ModalBody>
        <p>{`Why should we ${actionText} this employee?`}</p>
        <Input onChange={(e) => setReason(e.target.value)} value={reason} />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={async () => {
            if (!reason) {
              toast.error('Reason is required');
              return;
            }
            const pastActionText = isSuspend ? 'suspended' : 'rehired';
            await onSave(reason);
            setIsOpen(false);
            toast.success(`Employee has been ${pastActionText} successfully`);
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const UserDetail = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingAction, setLoadingAction] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [loadingMedPass, setLoadingMedPass] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isOpenChangeEmailModal, setIsOpenChangeEmailModal] = useState(false);
  const [isOpenAccountEditModal, setIsOpenAccountEditModal] = useState(false);
  const [internalNotes, setInternalNotes] = useState(user?.internalNotes || '');
  const [discontents, setDiscontents] = useState([]);
  const [reviewData, setReviewData] = useState({ onTimePct: null, avgRating: null, reviewCount: null });
  const [show, setShow] = useState(false);
  const [nurseType, setNurseType] = useState('None');
  const hasMedPassCapability =
    user?.additionalCapabilities && user.additionalCapabilities.includes(AdditionalCapabilities.MED_PASS);
  useEffect(() => setNurseType(user?.nurseType || 'None'), [user]);

  const fetchUser = useCallback(async () => {
    const result = await getUserData(id);
    const authUser = await getAuthUser(id);
    return { ...result.data(), id: result.id, email: authUser.data.email };
  }, [id]);

  const { onTimePct, avgRating, reviewCount } = reviewData;

  const initialAccountInformation = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
  };

  useEffect(() => {
    const getReviews = async () => {
      const ratings = [];
      let onTimeCount = 0;
      let onTimeTotalCount = 0;

      const reviews = await getReviewsByNurseId(id);
      if (reviews.docs.length) {
        reviews.docs.forEach((review) => {
          const { rating, responses } = review.data();
          if (rating) {
            ratings.push(rating);
          }
          responses.forEach((res) => {
            if (res.questionId === ShiftReviewQuestion.ON_TIME) {
              onTimeTotalCount += 1;
              if (res.responseValue) {
                onTimeCount += 1;
              }
            }
            if (res.questionId === ShiftReviewQuestion.RATING && !rating) {
              ratings.push(res.responseValue);
            }
          });
        });
        setReviewData({
          onTimePct: `${Math.round((onTimeCount / onTimeTotalCount) * 100).toString()}%`,
          avgRating: average(ratings).toFixed(2),
          reviewCount: ratings.length,
        });
      }
    };
    getReviews();
  }, [id]);

  const updateUser = useCallback(async () => {
    setLoading(true);

    const result = await fetchUser();
    setUser(result);
    setIsSuspended(!!result.suspended);
    setInternalNotes(result.internalNotes || '');
    const discontents = await fetchDiscontents(result.id);
    const promises = [];
    discontents.docs.forEach((doc) => {
      promises.push(doc.data());
    });
    const allDiscontents = await Promise.all(promises);
    setDiscontents([...allDiscontents]);
    setLoading(false);
  }, [fetchUser]);

  useEffect(() => {
    updateUser();
  }, [updateUser]);

  const sendResetPassword = async () => {
    setLoadingResend(true);
    try {
      const resendFn = firebase.functions().httpsCallable('resendPasswordRequest');
      await resendFn({
        userId: user.id,
      });
      toast.success('Successfully sent reset password email');
    } catch (e) {
      console.error(e);
      toast.error('Failed to send reset password email');
    } finally {
      setLoadingResend(false);
    }
  };

  const setMedPassCapability = async (hasMedPassCapability) => {
    setLoadingMedPass(true);
    try {
      const userSnapshot = await getUserData(id);
      const userData = userSnapshot.data();
      if (!userData.additionalCapabilities) {
        userData.additionalCapabilities = [];
      }
      if (hasMedPassCapability && !userData.additionalCapabilities.includes(AdditionalCapabilities.MED_PASS)) {
        userData.additionalCapabilities.push(AdditionalCapabilities.MED_PASS);
      } else if (!hasMedPassCapability && userData.additionalCapabilities.includes(AdditionalCapabilities.MED_PASS)) {
        const index = userData.additionalCapabilities.indexOf(AdditionalCapabilities.MED_PASS);
        if (index > -1) {
          userData.additionalCapabilities.splice(index, 1);
        }
      }
      await updateUserDataById(userData, id);
      setUser({ ...userData, id });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingMedPass(false);
    }
  };

  const onSaveInternalNotes = async () => {
    try {
      await updateUserDataById({ internalNotes }, id);
      toast.success('Internal notes saved successfully');
    } catch (e) {
      console.error(e);
      toast.error('Failed to save internal notes');
    }
  };

  const fetchDiscontents = async (uId) => {
    return await getShiftDiscontentByUId(uId);
  };

  const onApprove = (user, type) => {
    setType(type);
    setShow(true);
  };

  const onReject = (user, type) => {
    rejectDocument(user, type);
    toast.success('User rejected');
  };

  const suspendAction = async (reason) => {
    setLoadingAction(true);
    if (isSuspended) {
      await updateUserDataById(
        {
          suspended: firebase.firestore.FieldValue.delete(),
          suspendEvents: firebase.firestore.FieldValue.arrayUnion({
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            reason,
            action: 'rehired',
          }),
        },
        user.id
      );
    } else {
      await updateUserDataById(
        {
          suspended: true,
          suspendEvents: firebase.firestore.FieldValue.arrayUnion({
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            reason,
            action: 'suspended',
          }),
        },
        user.id
      );
    }
    setIsSuspended((a) => !a);
    setLoadingAction(false);
  };

  return (
    <>
      {show && (
        <DatePromptModal
          onClose={() => setShow(false)}
          onApprove={(date) => {
            approveDocument(user, type, date);
            toast.success('User approved');
            setShow(false);
          }}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card className="mb-3">
            <CardBody className="bg-light border-top">
              {user.workerTier?.tier && (
                <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                  <Badge colorScheme={'gray'}>{user.workerTier?.tier}</Badge>
                </div>
              )}
              <Row>
                <Col lg className="col-xxl-5">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '16px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Account Information</h6>
                    <button
                      style={{
                        background: 'none',
                        color: 'inherit',
                        border: 'none',
                        padding: '0',
                        font: 'inherit',
                        cursor: 'pointer',
                        outline: 'inherit',
                        marginLeft: '10px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        display: 'block',
                      }}
                      color="primary"
                      size="sm"
                      onClick={() => setIsOpenAccountEditModal(true)}
                    >
                      <FontAwesomeIcon icon="edit" className="mr-1" />
                    </button>
                  </div>
                  <CustomerDetailRow title="ID" text={user.id} />
                  <CustomerDetailRow title="Name" text={user.firstName + ' ' + user.lastName} />
                  <CustomerDetailRow title="Phone Number" text={user.phoneNumber} />
                  <CustomerDetailRow title="Email" text={user.email} />
                  <NurseTypeRow title="Nurse Type" nurseType={nurseType} userId={user.id} setNurseType={setNurseType} />
                  <CustomerDetailRow
                    title="Has COVID vaccination"
                    text={
                      (user?.additionalCapabilities || []).includes(AdditionalCapabilities.COVID_VACCINATION) ? (
                        <>
                          <Badge colorScheme="green">Yes</Badge>
                          <button
                            onClick={async () => {
                              await updateUserDataById(
                                {
                                  additionalCapabilities: firebase.firestore.FieldValue.arrayRemove(
                                    AdditionalCapabilities.COVID_VACCINATION
                                  ),
                                },
                                user.id
                              );
                              await updateUser();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      ) : (
                        <>
                          <Badge colorScheme="purple">No</Badge>
                          <button
                            onClick={async () => {
                              await updateUserDataById(
                                {
                                  additionalCapabilities: firebase.firestore.FieldValue.arrayUnion(
                                    AdditionalCapabilities.COVID_VACCINATION
                                  ),
                                },
                                user.id
                              );
                              await updateUser();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      )
                    }
                  />
                  {user && user.brookdalePremier?.status && (
                    <CustomerDetailRow
                      title="Brookdale Premier Status"
                      text={
                        <>
                          <Badge
                            colorScheme={user?.brookdalePremier?.status === PremierStatus.APPROVED ? 'green' : 'gray'}
                          >
                            {user.brookdalePremier?.status}
                          </Badge>
                          {user.brookdalePremier?.status === PremierStatus.REQUESTED && (
                            <button
                              onClick={async () => {
                                await approveWorkerPremier(user.id, user);
                                await updateUser();
                              }}
                              className="btn fs--2 btn-link"
                            >
                              Approve
                            </button>
                          )}
                        </>
                      }
                    />
                  )}
                  <CustomerDetailRow
                    title=""
                    text={
                      <button
                        className={`btn fs--1 btn-info`}
                        onClick={() => {
                          sendResetPassword();
                        }}
                        disabled={loadingResend}
                        style={{ marginTop: '16px' }}
                      >
                        {loadingResend ? 'Loading...' : 'Send reset password email'}
                      </button>
                    }
                  />
                  <CustomerDetailRow
                    title=""
                    text={
                      <button
                        className={`btn fs--1 btn-info`}
                        onClick={() => {
                          setIsOpenChangeEmailModal(true);
                        }}
                        style={{ marginTop: '16px' }}
                      >
                        {'Change Email'}
                      </button>
                    }
                  />
                </Col>
                <Col style={{ paddingTop: '31px' }} lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <CustomerDetailRow title="Address" text={user.streetAddress} />
                  <CustomerDetailRow title="Address 2" text={user.streetAddress2} />
                  <CustomerDetailRow title="City" text={user.city} />
                  <CustomerDetailRow title="State" text={user.state} />
                  {reviewCount !== null && <CustomerDetailRow title="Review Count" text={reviewCount} />}
                  {avgRating !== null && <CustomerDetailRow title="Average Rating" text={avgRating} />}
                  {onTimePct !== null && <CustomerDetailRow title="On time %" text={onTimePct} />}
                  <LicensedStates
                    onSave={async (data) => {
                      await updateUserDataById(data, id);
                    }}
                    onComplete={updateUser}
                    licensedStates={user.licensedStates}
                    homeState={user.state}
                  />
                  <CustomerDetailRow
                    title="Action"
                    text={
                      <button
                        className={`btn fs--1 p-0 btn-link ${isSuspended ? 'text-success' : 'text-danger'}`}
                        onClick={() => {
                          setIsSuspendModalOpen(true);
                        }}
                        disabled={loadingAction}
                      >
                        {loadingAction ? 'Loading...' : isSuspended ? 'Rehire' : 'Suspend'}
                      </button>
                    }
                  />
                  <CustomerDetailRow
                    title=""
                    text={
                      <button
                        style={{ marginTop: '16px' }}
                        className={`btn fs--1 btn-${hasMedPassCapability ? 'danger' : 'info'}`}
                        onClick={() => {
                          hasMedPassCapability ? setMedPassCapability(false) : setMedPassCapability(true);
                        }}
                        disabled={loadingMedPass}
                      >
                        {hasMedPassCapability
                          ? `Remove ${AdditionalCapabilities.MED_PASS} Capability`
                          : `Add ${AdditionalCapabilities.MED_PASS} Capability`}
                      </button>
                    }
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mt-3">
            <FalconCardHeader title="Internal Notes" />
            <CardBody className="bg-light border-top">
              <Label className="fs-0" for="eventDescription">
                Notes only visible to admins
              </Label>
              <Input
                type="textarea"
                name="internalNotes"
                id="internalNotes"
                value={internalNotes}
                onChange={({ target }) => setInternalNotes(target.value)}
                style={{ fontSize: '12px', minHeight: '75px' }}
              />
              <Button style={{ marginTop: '10px' }} size="sm" onClick={onSaveInternalNotes}>
                Save
              </Button>
            </CardBody>
          </Card>
          <Card className="mt-3">
            <FalconCardHeader title="Documents" />
            <CardBody className="border-top p-0">
              {user.cprCertificationImage && (
                <Row className="align-items-center border-bottom py-2 px-3">
                  <Col xs={2}>
                    <Badge className="font-weight-semi-bold text-capitalize fs--1 mb-1">
                      {' '}
                      {user.cprCertificationImage.status}{' '}
                    </Badge>{' '}
                  </Col>
                  <Col xs={4}>
                    {' '}
                    <a className="fs--1" target="_blank" rel="noreferrer" href={user.cprCertificationImage.fileUrl}>
                      CPR Certifications
                    </a>{' '}
                  </Col>
                  <Col xs={2}>
                    {user.cprCertificationImage.expirationDate &&
                      moment(user.cprCertificationImage.expirationDate?.toDate()).format('MM/DD/YYYY')}
                  </Col>
                  <Col xs={4}>
                    <ButtonGroup>
                      <Button
                        disabled={user.cprCertificationImage.status === 'accepted'}
                        color="success"
                        size="sm"
                        onClick={() => onApprove(user, 'cprCertificationImage')}
                      >
                        Approve
                      </Button>
                      <Button
                        disabled={user.cprCertificationImage.status === 'rejected'}
                        color="danger"
                        size="sm"
                        onClick={() => onReject(user, 'cprCertificationImage')}
                      >
                        Reject
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              )}
              {user.licenseImage && (
                <Row className="align-items-center border-bottom py-2 px-3">
                  <Col xs={2}>
                    {' '}
                    <Badge className="font-weight-semi-bold text-capitalize fs--1 mb-1">
                      {' '}
                      {user.licenseImage.status}{' '}
                    </Badge>{' '}
                  </Col>
                  <Col xs={4}>
                    {' '}
                    <a className="fs--1" target="_blank" rel="noreferrer" href={user.licenseImage.fileUrl}>
                      License Image
                    </a>{' '}
                  </Col>
                  <Col xs={2}>
                    {user.licenseImage.expirationDate &&
                      moment(user.licenseImage.expirationDate?.toDate()).format('MM/DD/YYYY')}
                  </Col>
                  <Col xs={4}>
                    <ButtonGroup>
                      <Button
                        disabled={user.licenseImage.status === 'accepted'}
                        color="success"
                        size="sm"
                        onClick={() => onApprove(user, 'licenseImage')}
                      >
                        Approve
                      </Button>
                      <Button
                        disabled={user.licenseImage.status === 'rejected'}
                        color="danger"
                        size="sm"
                        onClick={() => onReject(user, 'licenseImage')}
                      >
                        Reject
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              )}
              {user.idImage && (
                <Row className="align-items-center border-bottom py-2 px-3">
                  <Col xs={2}>
                    {' '}
                    <Badge className="font-weight-semi-bold text-capitalize fs--1 mb-1">
                      {' '}
                      {user.idImage.status}{' '}
                    </Badge>{' '}
                  </Col>
                  <Col xs={4}>
                    {' '}
                    <a className="fs--1" target="_blank" rel="noreferrer" href={user.idImage.fileUrl}>
                      ID Image
                    </a>{' '}
                  </Col>
                  <Col xs={2}>
                    {user.idImage.expirationDate && moment(user.idImage.expirationDate?.toDate()).format('MM/DD/YYYY')}
                  </Col>
                  <Col xs={4}>
                    <ButtonGroup>
                      <Button
                        disabled={user.idImage.status === 'accepted'}
                        color="success"
                        size="sm"
                        onClick={() => onApprove(user, 'idImage')}
                      >
                        Approve
                      </Button>
                      <Button
                        disabled={user.idImage.status === 'rejected'}
                        color="danger"
                        size="sm"
                        onClick={() => onReject(user, 'idImage')}
                      >
                        Reject
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              )}
              {user.tuberculosisTestImage && (
                <Row className="align-items-center border-bottom py-2 px-3">
                  <Col xs={2}>
                    {' '}
                    <Badge className="font-weight-semi-bold text-capitalize fs--1 mb-1">
                      {' '}
                      {user.tuberculosisTestImage.status}{' '}
                    </Badge>{' '}
                  </Col>
                  <Col xs={4}>
                    {' '}
                    <a className="fs--1" target="_blank" rel="noreferrer" href={user.tuberculosisTestImage.fileUrl}>
                      Tuberculosis Image
                    </a>{' '}
                  </Col>
                  <Col xs={2}>
                    {user.tuberculosisTestImage.expirationDate &&
                      moment(user.tuberculosisTestImage.expirationDate?.toDate()).format('MM/DD/YYYY')}
                  </Col>
                  <Col xs={4}>
                    <ButtonGroup>
                      <Button
                        color="success"
                        size="sm"
                        disabled={user.tuberculosisTestImage.status === 'accepted'}
                        onClick={() => onApprove(user, 'tuberculosisTestImage')}
                      >
                        Approve
                      </Button>
                      <Button
                        color="danger"
                        disabled={user.tuberculosisTestImage.status === 'rejected'}
                        size="sm"
                        onClick={() => onReject(user, 'tuberculosisTestImage')}
                      >
                        Reject
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
          <Card className="mt-3">
            <FalconCardHeader title="Discontents" />
            <CardBody style={{ zoom: 0.8 }}>
              {discontents.flatMap((discontent, idx) => (
                <Fragment key={idx}>
                  <Row>
                    <Col>
                      Facility:{' '}
                      <a href={`/facilities/${discontent.facilityIdentifier}`}>
                        <b> {discontent.facilityIdentifier}</b>
                      </a>
                    </Col>
                    <Col>
                      Shift:{' '}
                      <a href={`/shifts/${discontent.shiftIdentifier}`}>
                        <b> {discontent.shiftIdentifier}</b>
                      </a>
                    </Col>
                    <Col>
                      Reason:{' '}
                      <b>
                        {discontent.shiftDiscontentReason.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                          return str.toUpperCase();
                        })}
                      </b>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col style={{ whiteSpace: 'pre' }}>
                      <b>Details:</b> {discontent.shiftDiscontentReasonDetails}
                    </Col>
                  </Row>
                  <hr />
                </Fragment>
              ))}
            </CardBody>
          </Card>
          <Card className="mt-3">
            <UserShiftsTable uid={user.id} type="nurse" />
          </Card>
          {user.suspendEvents && user.suspendEvents.length > 0 && (
            <SuspendEventsTable suspendEvents={user.suspendEvents} />
          )}
        </>
      )}
      <SuspendModal
        isOpen={isSuspendModalOpen}
        setIsOpen={setIsSuspendModalOpen}
        isSuspend={!isSuspended}
        onSave={suspendAction}
      />
      <ChangeEmailModal
        isOpen={isOpenChangeEmailModal}
        setIsOpen={setIsOpenChangeEmailModal}
        onSave={async (data) => {
          try {
            await changeEmail(data);
            setIsOpenChangeEmailModal(false);
            updateUser();
            toast.success(`Successfully updated email to ${data.newEmail}`);
          } catch (e) {
            toast.error(`Failed to update email: ${e.message}`);
          }
        }}
        userId={user?.id}
      />
      <UserAccountEditModal
        isOpen={isOpenAccountEditModal}
        setIsOpen={setIsOpenAccountEditModal}
        initialAccountInformation={initialAccountInformation}
        onSave={() => {
          updateUser();
          setIsOpenAccountEditModal(false);
        }}
        userId={id}
      />
    </>
  );
};

export default UserDetail;
