import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { getTokensByLocation } from '../../api/shifts';
import { AdditionalRequirements, NurseType } from '../../constants';
import { functions } from '../../firebase';
import FacilityNotificationsModal from '../common/FacilityNotificationsModal';

const radiusesInMiles = [10, 25, 50];
const initialTokenCounts = radiusesInMiles.reduce((acc, val) => ({ ...acc, [val]: 0 }), {});
const initialTokens = radiusesInMiles.reduce((acc, val) => ({ ...acc, [val]: [] }), {});

const FacilityNotifications = ({ facility }) => {
  const [tokenCounts, setTokenCounts] = useState(initialTokenCounts);
  const [tokens, setTokens] = useState(initialTokens);
  const [isTest, setIsTest] = useState(false);
  const [clickState, setClickState] = useState({
    isOpenModal: false,
    mi: radiusesInMiles[0],
  });
  const [title, setTitle] = useState(`New shifts at ${facility.facilityName} in ${facility.facilityCity}!`);
  const [body, setBody] = useState('');
  const [notificationNurseType, setNotificationNurseType] = useState(null);
  const [requiresMedPass, setRequiresMedPass] = useState(false);
  useEffect(() => {
    const asyncGetShift = async () => {
      const fakeShift = {
        additionalRequirements: requiresMedPass ? [AdditionalRequirements.MED_PASS] : [],
        nurseType: notificationNurseType,
      };
      const tokens = await getTokensByLocation(facility.coordinates, radiusesInMiles, fakeShift, facility, isTest);
      setTokenCounts(Object.keys(tokens).reduce((acc, mi) => ({ ...acc, [mi]: tokens[mi].length }), {}));
      setTokens(tokens);
    };
    asyncGetShift();
  }, [facility, isTest, notificationNurseType, requiresMedPass]);
  const onNotificationClick = (mi) => {
    setClickState({ isOpenModal: true, mi });
  };
  const setIsOpenModal = (isOpen) => {
    setClickState({ ...clickState, isOpenModal: isOpen });
  };
  const onSendIt = async () => {
    try {
      const shiftNotification = functions.httpsCallable('user-shiftNotification');
      await shiftNotification({
        tokens: tokens[clickState.mi],
        notification: {
          title,
          body,
        },
        data: {
          route: 'HOME',
        },
        android: { notification: { channelId: 'default', priority: 'max' } },
        fcmOptions: {
          analyticsLabel: 'facility_notification',
        },
      });
      setIsOpenModal(false);
      toast.success('Notifications sent successfully');
    } catch (e) {
      console.error(e);
      toast.error('Notifications failed to be sent');
    }
  };

  const formIsValid = title && body && notificationNurseType;
  const onNurseTypeChange = (e) => {
    setNotificationNurseType(e.target.id);
    if (e.target.id === 'LPN' || e.target.id === 'RN') {
      setRequiresMedPass(true);
    }
  };
  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <Label>Notification Title</Label>
        <Input value={title} onChange={({ target }) => setTitle(target.value)} />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Label>Notification Body</Label>
        <Input
          placeholder="New AM and PM shifts from 10/1 through 10/5 now available. Get them while they are available!"
          value={body}
          onChange={({ target }) => setBody(target.value)}
          type="textarea"
        />
      </div>
      <FormGroup>
        <Label for="selectOption">Which nurse types should we send the notification to?</Label>
        <div>
          <CustomInput
            checked={notificationNurseType === 'Caregiver'}
            onChange={onNurseTypeChange}
            type="radio"
            name="nurseType"
            id={NurseType.CAREGIVER}
            label="All"
          />
          <CustomInput
            checked={notificationNurseType === 'CNA'}
            onChange={onNurseTypeChange}
            type="radio"
            name="nurseType"
            id={NurseType.CNA}
            label="CNA, LPN, and RN"
          />
          <CustomInput
            checked={notificationNurseType === 'LPN'}
            onChange={onNurseTypeChange}
            type="radio"
            name="nurseType"
            id={NurseType.LPN}
            label="LPN and RN"
          />
          <CustomInput
            checked={notificationNurseType === 'RN'}
            onChange={onNurseTypeChange}
            type="radio"
            name="nurseType"
            id={NurseType.RN}
            label="RN only"
          />
        </div>
      </FormGroup>
      <div style={{ marginLeft: '20px' }}>
        <Label check>
          <Input
            disabled={notificationNurseType === 'LPN' || notificationNurseType === 'RN'}
            type="checkbox"
            checked={requiresMedPass}
            onChange={(event) => setRequiresMedPass(event.target.checked)}
          />
          Only send to MeD Pass employees?
        </Label>
      </div>
      <div style={{ marginLeft: '20px' }}>
        <Label check>
          <Input type="checkbox" checked={isTest} onChange={(event) => setIsTest(event.target.checked)} />
          Only send to test users?
        </Label>
      </div>
      <Row style={{ marginTop: '20px' }}>
        <Col lg className="col-xxl-5">
          <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Radius</h6>
        </Col>
        <Col lg className="col-xxl-5">
          <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">User Count</h6>
        </Col>
        <Col lg className="col-xxl-5">
          <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Action</h6>
        </Col>
      </Row>
      {radiusesInMiles.map((mi) => (
        <Row style={{ marginBottom: '15px' }} key={mi}>
          <Col>{`${mi} miles`}</Col>
          <Col>{`${tokenCounts[mi]}`}</Col>
          <Col>
            <Button
              disabled={!formIsValid || tokenCounts[mi] === 0}
              color="secondary"
              className="px-4"
              onClick={() => onNotificationClick(mi)}
            >
              Send Notifications
            </Button>
          </Col>
        </Row>
      ))}
      <FacilityNotificationsModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={clickState.isOpenModal}
        tokens={tokens[clickState.mi]}
        title={title}
        body={body}
        radius={clickState.mi}
        onSendIt={onSendIt}
        isTest={isTest}
      />
    </>
  );
};

export default FacilityNotifications;
