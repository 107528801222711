import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDwH3FKT_ITkN6TTZ3ayOy8EDt1u1sXRMc',
  authDomain: 'pt-pickup.firebaseapp.com',
  databaseURL: 'https://pt-pickup-default-rtdb.firebaseio.com',
  projectId: 'pt-pickup',
  storageBucket: 'pt-pickup.appspot.com',
  messagingSenderId: '666139681529',
  appId: '1:666139681529:web:dbd40f2001104b82b3b828',
  measurementId: 'G-9C3G9FVKT6',
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const analytics = firebase.analytics();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

// if (window.location.hostname === 'localhost') {
//   db.useEmulator('localhost', 8080);
//   auth.useEmulator('http://localhost:9099');
//   functions.useEmulator('localhost', 5001);
// }

export { db, googleAuthProvider, facebookAuthProvider, firebase, auth, functions, analytics };
