import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';

interface PrivateRouteProps {
  isAuthenticated: boolean;
  component: React.FunctionComponent;
  path: string;
}

export const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({ isAuthenticated }) => {
  console.log('PrivateRoute');
  return (
    <Routes>
      {isAuthenticated ? (
        <Route path="*" element={<DashboardLayout />} />
      ) : (
        <Route path="*" element={<Navigate to="/authentication/basic/login" />} />
      )}
    </Routes>
  );
};
