import { CustomerDetailRow } from '../page/ShiftDetail';
import { firebase } from '../../firebase';
import { Badge } from '@chakra-ui/layout';
import { NurseType, Shift } from '../../types';
import { checkShiftRequiresMedpass, updateShift } from '../../api/shifts';
import { AdditionalRequirements } from '../../types/shifts';
import { sleep } from '../../helpers/utils';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface ShiftMedPassProps {
  shift: Shift | undefined;
  shiftId: string;
  loadShift: () => void;
}

// Needed so that there is time for firebase function
// to run and update the payouts
const SLEEP_TIME_IN_MS = 4000;

const ShiftMedPass = ({ shift, loadShift, shiftId }: ShiftMedPassProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <CustomerDetailRow
      isLastItem={false}
      title="Requires MeD Pass"
      text={
        checkShiftRequiresMedpass(shift) ? (
          <>
            <Badge colorScheme="green">Yes</Badge>
            {loading ? (
              'Loading...'
            ) : (
              <button
                onClick={async () => {
                  setLoading(true);
                  try {
                    await updateShift(shiftId, {
                      additionalRequirements: firebase.firestore.FieldValue.arrayRemove(
                        AdditionalRequirements.MED_PASS
                      ),
                    });
                    await sleep(SLEEP_TIME_IN_MS);
                    await loadShift();
                  } catch (e: any) {
                    toast.error(e.message);
                  }
                  setLoading(false);
                }}
                className="btn fs--2 btn-link"
                disabled={shift?.nurseType && [NurseType.LPN, NurseType.RN].includes(shift.nurseType)}
              >
                Toggle
              </button>
            )}
          </>
        ) : (
          <>
            <Badge colorScheme="purple">No</Badge>
            {loading ? (
              'Loading...'
            ) : (
              <button
                onClick={async () => {
                  setLoading(true);
                  try {
                    await updateShift(shiftId, {
                      additionalRequirements: firebase.firestore.FieldValue.arrayUnion(AdditionalRequirements.MED_PASS),
                    });
                    await sleep(SLEEP_TIME_IN_MS);
                    await loadShift();
                  } catch (e: any) {
                    toast.error(e.message);
                  }
                  setLoading(false);
                }}
                className="btn fs--2 btn-link"
              >
                Toggle
              </button>
            )}
          </>
        )
      }
    />
  );
};
export default ShiftMedPass;
