import React, { useState } from 'react';
import ReactDatetime from 'react-datetime';
import { InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import ButtonIcon from './ButtonIcon';

const StartDateFilter = ({ onSubmit }) => {
  const [fromQuery, setFromQuery] = useState('');
  const [toQuery, setToQuery] = useState('');
  const columns = [
    {
      label: 'Today',
      value: () => {
        const start = new Date();
        const end = new Date(start.getTime() + 1000 * 60 * 60 * 24);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return [start, end];
      },
    },
    {
      label: 'Tomorrow',
      value: () => {
        const today = new Date();
        const start = new Date(today.getTime() + 1000 * 60 * 60 * 24);
        const end = new Date(start.getTime() + 1000 * 60 * 60 * 24);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return [start, end];
      },
    },
    {
      label: 'This week',
      value: () => {
        const start = new Date();
        const day = start.getDay();
        if (day !== 0) {
          start.setHours(-24 * day);
        }
        const end = new Date();
        end.setHours(24 * (7 - day));
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return [start, end];
      },
    },
    {
      label: 'This month',
      value: () => {
        const start = new Date();
        start.setDate(1);
        const end = new Date(start);
        end.setMonth(end.getMonth() + 1);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return [start, end];
      },
    },
    {
      label: 'Custom',
      type: 'custom',
      value: () => null,
    },
    {
      label: 'Clear',
      value: () => {
        return [];
      },
    },
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState();
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const formSubmit = (column) => {
    if (!column.value().length) {
      onSubmit([]);
      return;
    }
    const [start, end] = column.value();
    onSubmit([
      ['start', '>=', start],
      ['start', '<=', end],
    ]);
  };
  const label = selectedFilter?.label;
  const title = label && label !== 'Clear' ? label : 'Start Date Filter';
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (selectedFilter.type === 'custom') {
          onSubmit([
            ['start', '>=', fromQuery.toDate()],
            ['start', '<=', toQuery.toDate()],
          ]);
        }
      }}
    >
      <InputGroup style={{ zoom: 0.9 }}>
        <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
          <DropdownToggle caret>{title}</DropdownToggle>
          <DropdownMenu>
            {columns.flatMap((column, index) => (
              <DropdownItem
                key={index}
                onClick={() => {
                  setSelectedFilter(column);
                  if (column.type !== 'custom') {
                    formSubmit(column);
                  }
                }}
              >
                {column.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </InputGroupButtonDropdown>
        {selectedFilter?.type === 'custom' && (
          <>
            <ReactDatetime
              timeFormat={true}
              value={fromQuery}
              onChange={(dateTime) => {
                if (dateTime._isValid) {
                  setFromQuery(dateTime);
                }
              }}
              dateFormat="MM-DD-YYYY"
              inputProps={{ required: true, placeholder: 'From', id: 'eventStart' }}
            />
            <ReactDatetime
              timeFormat={true}
              value={toQuery}
              onChange={(dateTime) => {
                if (dateTime._isValid) {
                  setToQuery(dateTime);
                }
              }}
              placeholder="To"
              dateFormat="MM-DD-YYYY"
              inputProps={{ required: true, placeholder: 'To', id: 'eventStart' }}
            />
            <ButtonIcon icon="search" />
          </>
        )}
      </InputGroup>
    </form>
  );
};
export default StartDateFilter;
