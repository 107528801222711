import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { changeEmail } from '../../api/auth';
import _ from 'lodash';
import { firebase } from '../../firebase';
import { validateEmail } from '../../helpers/utils';
import { updateFacilityAndUserDataById } from '../../api/facility';
import { updateUserDataById } from '../../api/user';

interface AccountInformation {
  firstName: string;
  lastName: string;
}

interface UserAccountEditModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  initialAccountInformation: AccountInformation;
  onSave: () => void;
  userId: string;
}

const UserAccountEditModal = ({
  isOpen,
  setIsOpen,
  initialAccountInformation,
  onSave,
  userId,
}: UserAccountEditModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accountInformation, setAccountInformation] = useState({
    firstName: '',
    lastName: '',
  });
  const toggle = () => setIsOpen(!isOpen);
  const closeBtn = (
    <button
      className="close font-weight-normal"
      onClick={() => {
        setAccountInformation(initialAccountInformation);
        toggle();
      }}
    >
      &times;
    </button>
  );

  useEffect(() => {
    setAccountInformation(initialAccountInformation);
  }, [initialAccountInformation]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await updateUserDataById(
        {
          firstName: accountInformation.firstName,
          lastName: accountInformation.lastName,
        },
        userId
      );
      toast.success('Successfully updated user account information');
      onSave();
    } catch (e: any) {
      toast.error(`Failed to update user account information: ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  const onFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountInformation({ ...accountInformation, [event.target.id]: event.target.value });
  };
  const hasChanged = !_.isEqual(accountInformation, initialAccountInformation);
  const requiredFields: (keyof AccountInformation)[] = ['firstName', 'lastName'];
  const isNotEmpty = requiredFields.every((key) => Boolean(accountInformation[key]));
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader close={closeBtn}>Edit Account Information</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>
              First Name <span style={{ color: 'red' }}>*</span>
            </Label>
            <Input id="firstName" value={accountInformation.firstName} onChange={onFormChange} />
          </FormGroup>
          <FormGroup>
            <Label>
              Last Name <span style={{ color: 'red' }}>*</span>
            </Label>
            <Input id="lastName" value={accountInformation.lastName} onChange={onFormChange} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!hasChanged || !isNotEmpty || isLoading} onClick={handleSubmit}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserAccountEditModal;
