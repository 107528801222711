import React, { useState } from 'react';
import { InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';

const ExpiredFilter = ({ onSubmit }) => {
  const columns = [
    {
      label: 'Expired',
      value: 'expired',
    },
    {
      label: 'Future',
      value: 'future',
    },
    {
      label: 'Clear',
      value: 'clear',
    },
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState();
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const formSubmit = (column) => {
    if (column.value === 'clear') {
      onSubmit([]);
    }
    if (column.value === 'expired') {
      onSubmit([['start', '<=', new Date()]]);
    }
    if (column.value === 'future') {
      onSubmit([['start', '>=', new Date()]]);
    }
  };
  const label = selectedFilter?.label;
  const title = label && label !== 'Clear' ? label : 'Expired Filter';
  return (
    <form>
      <InputGroup style={{ zoom: 0.9 }}>
        <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
          <DropdownToggle caret>{title}</DropdownToggle>
          <DropdownMenu>
            {columns.flatMap((column, index) => (
              <DropdownItem
                key={index}
                onClick={() => {
                  setSelectedFilter(column);
                  formSubmit(column);
                }}
              >
                {column.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </InputGroupButtonDropdown>
      </InputGroup>
    </form>
  );
};
export default ExpiredFilter;
