import React from 'react';

export default function DiscontentDetailsModal({ onClose, discontent }) {
  return (
    <>
      <div style={{ opacity: 0.3 }} className="modal-backdrop" />
      <div
        className="modal"
        style={{ display: 'block' }}
        id="modelId"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Details</h5>
              <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div style={{ zoom: 0.8 }}>
                <p>
                  <b>
                    {discontent.shiftDiscontentReason.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                      return str.toUpperCase();
                    })}
                  </b>
                </p>
                <p style={{ whiteSpace: 'pre' }}>Details: {discontent.shiftDiscontentReasonDetails}</p>
              </div>{' '}
            </div>
            <div className="modal-footer">
              <button type="button" onClick={() => onClose()} className="btn btn-secondary" data-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
