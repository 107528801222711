import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col } from 'reactstrap';
import Flex from '../common/Flex';
import classNames from 'classnames';

const ShiftNotificationsRow = ({ title, isLastItem, text }) => (
  <Row>
    <Col xs={5} sm={5}>
      <p className={classNames('font-weight-semi-bold', 'fs--1', { 'mb-0': isLastItem, 'mb-1': !isLastItem })}>
        {title}
      </p>
    </Col>
    <Col className="fs--1">{text}</Col>
  </Row>
);

//on the AddSchedule Modal, we need to add the ability for the shifts to be stored in firebase. Be sure to import firebase into this component.

const ShiftNotificationsModal = ({ setIsOpenModal, isOpenModal, tokens, title, body, radius, onSendIt, isTest }) => {
  const toggle = () => setIsOpenModal(!isOpenModal);

  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      modalClassName="theme-modal"
      contentClassName="border"
      style={{ width: '750px' }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setIsOpenModal(false);
        }}
      >
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>
          Confirm
        </ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: '15px' }}>
            <h5>Are you sure you want to send push notifications to users for this shift?</h5>
            {isTest && <p>Only test users will receive the notification</p>}
          </div>
          <Row>
            <Col lg className="col-xxl-5">
              <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Notification Information</h6>
              <ShiftNotificationsRow title="Title" text={title} />
              <ShiftNotificationsRow title="Body" text={body} />
              <ShiftNotificationsRow title="Radius from facility" text={`${radius} miles`} />
              <ShiftNotificationsRow title="Number of users to be notified" text={tokens ? tokens.length : 0} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter tag={Flex} justify="end" align="center" className="bg-light border-top-0">
          <Button color="primary" onClick={onSendIt} className="px-4">
            SEND IT
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ShiftNotificationsModal;
