import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import _ from 'lodash';
import { firebase } from '../../firebase';
import { updateFacilityDataById } from '../../api/facility';
import { FacilityBasePayoutRateConfig, NurseType } from '../../types';

const getCurrentBasePayoutRateConfig = (
  basePayoutRateConfigs: FacilityBasePayoutRateConfig[]
): { [key in NurseType]?: number } | null => {
  if (basePayoutRateConfigs.length === 0) {
    return null;
  }
  basePayoutRateConfigs.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));
  return toDollars(basePayoutRateConfigs[0].centsPerHour);
};

const toCents = (basePayoutRateConfig: { [key in NurseType]?: number }) => {
  return Object.keys(basePayoutRateConfig).reduce((acc, nurseType) => {
    const nType: NurseType = nurseType as NurseType;
    if (nType in basePayoutRateConfig) {
      acc[nType] = Math.round((basePayoutRateConfig as any)[nType] * 100);
    }
    return acc;
  }, {} as { [key in NurseType]?: number });
};

const toDollars = (basePayoutRateConfig: { [key in NurseType]?: number }) => {
  return Object.keys(basePayoutRateConfig).reduce((acc, nurseType) => {
    const nType: NurseType = nurseType as NurseType;
    if (nType in basePayoutRateConfig) {
      acc[nType] = Math.round((basePayoutRateConfig as any)[nType] / 100);
    }
    return acc;
  }, {} as { [key in NurseType]?: number });
};

interface EditBasePayoutRatesModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: () => void;
  facilityId: string | undefined;
  basePayoutRateConfigs: FacilityBasePayoutRateConfig[];
}

const defaultConfig: { [key in NurseType]?: number } = {};

const EditBasePayoutRatesModal = ({
  isOpen,
  setIsOpen,
  basePayoutRateConfigs,
  onSave,
  facilityId,
}: EditBasePayoutRatesModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [basePayoutRateConfig, setBasePayoutRateConfig] = useState<{ [key in NurseType]?: number }>(defaultConfig);
  const toggle = () => setIsOpen(!isOpen);
  const closeBtn = (
    <button
      className="close font-weight-normal"
      onClick={() => {
        setBasePayoutRateConfig(getCurrentBasePayoutRateConfig(basePayoutRateConfigs) || defaultConfig);
        toggle();
      }}
    >
      &times;
    </button>
  );

  useEffect(() => {
    setBasePayoutRateConfig(getCurrentBasePayoutRateConfig(basePayoutRateConfigs) || defaultConfig);
  }, [basePayoutRateConfigs]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await updateFacilityDataById(
        {
          basePayoutRateConfigs: firebase.firestore.FieldValue.arrayUnion({
            centsPerHour: toCents(basePayoutRateConfig),
            updatedAt: new Date(),
          }),
        },
        facilityId
      );
      toast.success('Successfully updated facility base pay rates');
      onSave();
    } catch (e: any) {
      toast.error(`Failed to update facility base pay rates: ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  const onFormChange = (event: any) => {
    if (event.target.value === '') {
      const newBasePayoutRateConfig = { ...basePayoutRateConfig };
      delete newBasePayoutRateConfig[event.target.id as NurseType];
      setBasePayoutRateConfig(newBasePayoutRateConfig);
    } else {
      setBasePayoutRateConfig({ ...basePayoutRateConfig, [event.target.id]: parseInt(event.target.value) });
    }
  };
  const hasChanged = !_.isEqual(
    basePayoutRateConfig,
    getCurrentBasePayoutRateConfig(basePayoutRateConfigs) || defaultConfig
  );
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader close={closeBtn}>Edit Base Payout Rates</ModalHeader>
      <ModalBody>
        <p>Add/Edit values below in $/hr.</p>
        <p>If the form input is blank, then the default base payout rate for the state will be used.</p>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Caregiver</Label>
            <Input
              id="Caregiver"
              value={basePayoutRateConfig[NurseType.CAREGIVER]}
              onChange={onFormChange}
              type="number"
            />
          </FormGroup>
          <FormGroup>
            <Label>CNA</Label>
            <Input id="CNA" value={basePayoutRateConfig[NurseType.CNA]} onChange={onFormChange} type="number" />
          </FormGroup>
          <FormGroup>
            <Label>LPN</Label>
            <Input id="LPN" value={basePayoutRateConfig[NurseType.LPN]} onChange={onFormChange} type="number" />
          </FormGroup>
          <FormGroup>
            <Label>RN</Label>
            <Input id="RN" value={basePayoutRateConfig[NurseType.RN]} onChange={onFormChange} type="number" />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!hasChanged || isLoading} onClick={handleSubmit}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditBasePayoutRatesModal;
