import { useNavigate } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col } from 'reactstrap';

import Loader from '../common/Loader';
import FalconCardHeader from '../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap/lib';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicants } from '../../redux/actions/applicantActions';
import DataFilter from '../common/DataFilter';
import WorkersExportButton from '../common/WorkersExportButton';
import { TestContext } from '../../context/Context';

const anchorFormatter = (name, { id }) => (
  <a href={`/users/${id}`}>
    <b>{name}</b>
  </a>
);

const actionFormatter = (dataField, { id }) => (
  // Control your row with this id
  <UncontrolledDropdown>
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
      <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right className="border py-2">
      <DropdownItem onClick={() => console.log('Edit: ', id)}>Edit</DropdownItem>
      <DropdownItem onClick={() => console.log('Delete: ', id)} className="text-danger">
        Delete
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const statusFormatter = (status) => {
  return (
    <Badge color={status === 'pending' ? 'warning' : status === 'accepted' ? 'success' : 'secondary'}>
      {status === 'pending' ? 'Pending' : status === 'accepted' ? 'Accepted' : 'Not Uploaded'}
    </Badge>
  );
};

const columnsApplicants = [
  {
    dataField: 'firstName',
    text: 'Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: anchorFormatter,
    sort: true,
  },
  {
    dataField: 'lastName',
    text: 'Last Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'state',
    text: 'State',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'idImage.status',
    text: 'ID Card',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: statusFormatter,
    sort: true,
  },
  {
    dataField: 'cprCertificationImage.status',
    text: 'CPR',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: statusFormatter,
    sort: true,
  },
  {
    dataField: 'licenseImage.status',
    text: 'License',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: statusFormatter,
    sort: true,
  },
  {
    dataField: 'tuberculosisTestImage.status',
    text: 'Tuberculosis Test',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: statusFormatter,
    sort: true,
  },
  {
    dataField: '',
    headerClasses: 'border-0',
    text: '',
    classes: 'border-0 py-2 align-middle',
    formatter: actionFormatter,
    align: 'right',
  },
];

const Applicants = ({ showLoadMore = true }) => {
  const { applicants, loading, hasMore } = useSelector((state) => state.applicant);
  const [filters, setFilters] = useState([]);
  const { isTest } = useContext(TestContext);
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getApplicants(filters, undefined, isTest));
  }, [dispatch, filters, isTest]);

  return (
    <Card>
      <FalconCardHeader title="Applicants">
        <div className="d-flex">
          <DataFilter
            columns={[
              { label: 'First Name', type: 'string', filter: 'firstName' },
              { label: 'Last Name', type: 'string', filter: 'lastName' },
              {
                label: 'Documents Uploaded',
                type: 'custom',
                customFilter: [
                  ['cprCertificationImage.status', '==', 'pending'],
                  ['licenseImage.status', '==', 'pending'],
                  ['idImage.status', '==', 'pending'],
                  ['tuberculosisTestImage.status', '==', 'pending'],
                ],
              },
            ]}
            onSubmit={(filter) => {
              setFilters(filter);
              dispatch(getApplicants(filter, true, isTest));
            }}
          />
          <WorkersExportButton />
        </div>
      </FalconCardHeader>
      {loading ? (
        <Loader />
      ) : (
        <CardBody className="fs--1 p-0">
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={showLoadMore ? applicants : applicants.slice(0, 5)}
            columns={columnsApplicants}
            bordered={false}
            classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
            rowClasses="btn-reveal-trigger border-top border-200"
            headerClasses="bg-200 text-900 border-y border-200"
          />
          <Row noGutters className="px-1 py-3  d-flex justify-content-center">
            <Col xs="auto" className="pr-3">
              {showLoadMore ? (
                <Button
                  size="sm"
                  onClick={() => {
                    dispatch(getApplicants(filters, false, isTest));
                  }}
                  className="px-4 ml-2 btn-light"
                  disabled={!hasMore || loading}
                >
                  {loading ? 'Loading...' : hasMore ? 'Load More' : 'No more items'}
                </Button>
              ) : (
                <Button
                  size="sm"
                  onClick={() => {
                    history.push('/applicants');
                  }}
                  className="px-4 ml-2 btn-light"
                >
                  View All
                </Button>
              )}
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
};

export default Applicants;
