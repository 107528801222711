import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';

const PurchasesTableActions = ({ invoice }) => {
  const history = useNavigate();
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu right className="border py-2">
        <DropdownItem onClick={() => history.push(`/invoices/${invoice?.invoice.id}`)}>View Invoice</DropdownItem>
        {invoice.status === 'halted' && (
          <DropdownItem
            onClick={() => alert(`Error Code: ${invoice.errorCode}\nError Message: ${invoice.errorMessage}`)}
          >
            View Error Details
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default PurchasesTableActions;
