import { Parser } from 'json2csv';
import React, { useContext } from 'react';
import { useState } from 'react';
import { db } from '../../firebase';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ButtonIcon from './ButtonIcon';
import Datetime from 'react-datetime';
import { Collection } from '../../constants';
import { getAllWorkers } from '../../api/user';
import { getAllFacilities } from '../../api/facility';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import Input from 'reactstrap/lib/Input';
import { TestContext } from '../../context/Context';

const getDateFields = ({ startDate, endDate }) => {
  const dates = [];
  const currentDate = startDate;
  while (currentDate.format('MM-DD-YYYY') !== endDate.format('MM-DD-YYYY')) {
    dates.push({
      label: currentDate.format('MM-DD-YYYY'),
      value: currentDate.format('MM-DD-YYYY'),
    });
    currentDate.add(1, 'days');
  }
  dates.push({
    label: endDate.format('MM-DD-YYYY'),
    value: endDate.format('MM-DD-YYYY'),
  });
  return dates;
};

const getDurationInHours = ({ clockIn, clockOut }) => {
  var hours = Math.abs(clockIn.toDate() - clockOut.toDate()) / 36e5;
  // Round hours to nearest 0.25 or nearest 15 minutes
  return parseFloat((Math.round(hours * 4) / 4).toFixed(2));
};

function DateSelectModal({ open, setOpen }) {
  const toggle = () => setOpen(!open);

  const [loading, setLoading] = useState(false);
  const { isTest } = useContext(TestContext);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [cancellationNoticeInHours, setCancellationNoticeInHours] = useState('24');

  async function downloadReport() {
    setLoading(true);
    try {
      const shiftSnaps = await db
        .collection(Collection.SHIFTS)
        .where('isTest', '==', isTest)
        .where(
          'start',
          '>=',
          moment(startDate)
            .subtract(2, 'days') // account for timezone differences
            .toDate()
        )
        .where(
          'start',
          '<=',
          moment(endDate)
            .add(2, 'days') // account for timezone differences
            .toDate()
        )
        .get();
      const workerSnaps = await getAllWorkers(isTest);
      const workerData = {};
      workerSnaps.docs.forEach((snap) => {
        const { firstName, lastName, nurseType } = snap.data();
        workerData[snap.id] = `${firstName?.trim() || ''} ${lastName?.trim() || ''} - ${nurseType}`;
      });
      const facilitiesSnaps = await getAllFacilities(isTest);
      const facilityData = {};
      facilitiesSnaps.docs.forEach((doc) => {
        facilityData[doc.id] = doc.data();
      });
      const timesheetData = {};
      const dateFields = getDateFields({ startDate, endDate });
      shiftSnaps.docs.forEach((doc) => {
        const { nurseId, start, end, facilityIdentifier, clockIn, clockOut, cancelledAt } = doc.data();
        if (!nurseId || ((!clockIn || !clockOut) && !cancelledAt)) {
          return;
        }
        let cancelledText = 'No';
        if (
          cancelledAt &&
          Math.abs(start.toDate() - cancelledAt.toDate()) / 1000 / 3600 < parseInt(cancellationNoticeInHours)
        ) {
          cancelledText = 'Yes';
        } else if (cancelledAt) {
          return;
        }
        const tz = facilityData[facilityIdentifier].tz;
        const formattedStartDate = moment(start.toDate()).tz(tz).format('MM-DD-YYYY');
        if (!dateFields.map((f) => f.value).includes(formattedStartDate)) {
          return; // filter out shifts not in requested window
        }
        const durationInHours = cancelledAt
          ? getDurationInHours({ clockIn: start, clockOut: end })
          : getDurationInHours({ clockIn, clockOut });
        const key = `${facilityIdentifier}-${nurseId}-${cancelledText}`;
        if (!(key in timesheetData)) {
          timesheetData[key] = {
            worker: workerData[nurseId],
            facilityName: facilityData[facilityIdentifier].facilityName,
            cancelledText,
          };
        }
        if (!(formattedStartDate in timesheetData[key])) {
          timesheetData[key][formattedStartDate] = 0;
        }
        timesheetData[key][formattedStartDate] += durationInHours;
      });
      // Add in ?'s for shifts with no timesheet
      shiftSnaps.docs.forEach((doc) => {
        const { nurseId, start, end, facilityIdentifier, clockIn, clockOut, cancelledAt } = doc.data();
        if (!nurseId || (clockIn && clockOut) || cancelledAt || !start || !end) {
          return;
        }
        const tz = facilityData[facilityIdentifier].tz;
        const formattedStartDate = moment(start.toDate()).tz(tz).format('MM-DD-YYYY');
        if (!dateFields.map((f) => f.value).includes(formattedStartDate)) {
          return; // filter out shifts not in requested window
        }
        const cancelledText = 'No';
        const key = `${facilityIdentifier}-${nurseId}-${cancelledText}`;
        if (!(key in timesheetData)) {
          timesheetData[key] = {
            worker: workerData[nurseId],
            facilityName: facilityData[facilityIdentifier].facilityName,
            cancelledText,
          };
        }
        if (!(formattedStartDate in timesheetData[key])) {
          timesheetData[key][formattedStartDate] = '?';
        } else {
          timesheetData[key][formattedStartDate] = `${timesheetData[key][formattedStartDate]},?`;
        }
      });

      const timesheetDataArr = Object.values(timesheetData);
      if (timesheetDataArr.length > 0) {
        const parser = new Parser({
          fields: [
            { label: 'Facility Name', value: 'facilityName' },
            { label: 'Worker', value: 'worker' },
            ...dateFields,
            { label: `Cancelled`, value: 'cancelledText' },
          ],
        });
        save('timesheet.csv', parser.parse(timesheetDataArr));
      }
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  }
  function save(filename, data) {
    var blob = new Blob([data], { type: 'text/csv' });
    if (typeof window.navigator.msSaveOrOpenBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Filter Start Date between</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            From Date
          </Label>
          <Datetime
            timeFormat={false}
            value={startDate}
            onChange={(dateTime) => {
              if (dateTime._isValid) {
                setStartDate(dateTime);
              }
            }}
            dateFormat="MM-DD-YYYY"
            inputProps={{ required: true, placeholder: 'MM-DD-YYYY', id: 'eventStart' }}
          />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            End Date
          </Label>
          <Datetime
            timeFormat={false}
            value={endDate}
            onChange={(dateTime) => {
              if (dateTime._isValid) {
                setEndDate(dateTime);
              }
            }}
            dateFormat="MM-DD-YYYY"
            inputProps={{ required: true, placeholder: 'MM-DD-YYYY', id: 'eventEnd' }}
          />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            Cancellation hours before shift start threshold
          </Label>
          <Input
            type="number"
            value={cancellationNoticeInHours}
            onChange={(e) => setCancellationNoticeInHours(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button disabled={loading} color="primary" onClick={() => downloadReport()}>
          Download
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default function AllTimesheetExportButton() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ButtonIcon
        onClick={() => {
          setIsOpen(true);
        }}
        icon="external-link-alt"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        className="ml-2"
      >
        Export Timesheets
      </ButtonIcon>
      <DateSelectModal open={isOpen} setOpen={setIsOpen} />
    </>
  );
}
