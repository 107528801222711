export const actions = {
  SET_THREADS: 'set_threads',
};

export const reducer = (state, action) => {
  // console.log(action);
  switch (action.type) {
    case actions.SET_THREADS:
      return {
        ...state,
        threads: action.payload,
      };
    default:
      return state;
  }
};
