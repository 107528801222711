import { useNavigate } from 'react-router';
import React, { useEffect } from 'react';
import { Button, Card, CardBody, Col } from 'reactstrap';

import Loader from '../common/Loader';
import FalconCardHeader from '../common/FalconCardHeader';
import { Row } from 'reactstrap/lib';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscontents } from '../../redux/actions/discontentActions';
import DiscontentDetailsModal from '../common/DiscontentDetailsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const facilityFormatter = (facilityIdentifier) => (
  <a href={`/facilities/${facilityIdentifier}`}>
    <b>{facilityIdentifier}</b>
  </a>
);

const nurseFormatter = (nurseId) => (
  <a href={`/users/${nurseId}`}>
    <b>{nurseId}</b>
  </a>
);

const shiftFormatter = (shiftIdentifier) => (
  <a href={`/shifts/${shiftIdentifier}`}>
    <b>{shiftIdentifier}</b>
  </a>
);

const reasonFormatter = (reason) => (
  <div>
    {reason.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    })}
  </div>
);

const columns = [
  {
    dataField: 'shiftIdentifier',
    text: 'Shift',
    headerClasses: 'border-0',
    classes: 'border-0',
    sort: true,
    formatter: shiftFormatter,
  },
  {
    dataField: 'nurseId',
    text: 'Nurse',
    headerClasses: 'border-0',
    classes: 'border-0',
    sort: true,
    formatter: nurseFormatter,
  },
  {
    dataField: 'facilityIdentifier',
    text: 'Facility',
    headerClasses: 'border-0',
    classes: 'border-0',
    sort: true,
    formatter: facilityFormatter,
  },
  {
    dataField: 'shiftDiscontentReason',
    headerClasses: 'border-0',
    text: 'Reason',
    formatter: reasonFormatter,
    classes: 'border-0',
  },
];

const Discontents = ({ showLoadMore = true }) => {
  const { discontents, loading, hasMore } = useSelector((state) => state.discontent);
  const [show, setShow] = React.useState(false);
  const [selected, setSelected] = React.useState();
  const modalFormatter = (_, row) => (
    <button
      className="btn-sm btn btn-link"
      onClick={() => {
        setSelected(row);
        setShow(true);
      }}
    >
      <FontAwesomeIcon icon="external-link-alt" />
    </button>
  );

  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDiscontents());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Card>
      <FalconCardHeader title="Discontents" />
      <CardBody className="fs--1 p-0">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={showLoadMore ? discontents : discontents.slice(0, 5)}
          columns={[
            ...columns,
            {
              dataField: 'shiftDiscontentReasonDetails',
              headerClasses: 'border-0',
              text: 'Details',
              formatter: modalFormatter,
              classes: 'border-0 py-2',
            },
          ]}
          bordered={false}
          classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
        />
        <Row noGutters className="px-1 py-3  d-flex justify-content-center">
          <Col xs="auto" className="pr-3">
            {showLoadMore ? (
              <Button
                size="sm"
                onClick={() => {
                  dispatch(getDiscontents());
                }}
                className="px-4 ml-2 btn-light"
                disabled={!hasMore || loading}
              >
                {loading ? 'Loading...' : hasMore ? 'Load More' : 'No more items'}
              </Button>
            ) : (
              <Button
                size="sm"
                onClick={() => {
                  history.push('/discontents');
                }}
                className="px-4 ml-2 btn-light"
              >
                View All
              </Button>
            )}
          </Col>
        </Row>
      </CardBody>
      {show && selected && <DiscontentDetailsModal discontent={selected} onClose={() => setShow(false)} />}
    </Card>
  );
};

export default Discontents;
