import React, { Fragment, useContext, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Badge from 'reactstrap/es/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoices } from '../../redux/actions/invoiceActions';
import moment from 'moment';
import PurchasesTableActions from './PurchasesTableActions';
import { Card, CardBody } from 'reactstrap/lib';
import FalconCardHeader from '../common/FalconCardHeader';
import { useNavigate } from 'react-router';
import InvoiceExportButton from '../common/InvoiceExportButton';
import DataFilter from '../common/DataFilter';
import { TestContext } from '../../context/Context';
const actionFormatter = (_, invoice) => <PurchasesTableActions invoice={invoice} />;

const anchorFormatter = (number, invoice) => (
  <a href={'/invoices/' + invoice.invoice.id}>
    <b>{number}</b>
  </a>
);

const badgeFormatter = (status) => {
  let color = '';
  let icon = '';
  switch (status) {
    case 'paid':
      color = 'success';
      icon = 'check';
      break;
    case 'open':
      color = 'secondary';
      icon = 'ban';
      break;
    default:
      color = 'warning';
      icon = 'stream';
  }
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule fs--2 text-capitalize">
      {status}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
    </Badge>
  );
};

const amountFormatter = (amount) => <Fragment>${amount / 100}</Fragment>;

const dateFormatter = (date) => <Fragment>{moment.unix(date).toLocaleString()}</Fragment>;

const columns = [
  {
    dataField: 'invoice.number',
    text: 'Invoice No',
    formatter: anchorFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'shiftId',
    text: 'Shift',
    // formatter: customerFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'invoice.created',
    formatter: dateFormatter,
    text: 'Invoice Date',
    // formatter: customerFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'invoice.due_date',
    text: 'Due Date',
    classes: 'border-0 py-2 align-middle',
    formatter: dateFormatter,
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'invoice.status',
    text: 'Status',
    formatter: badgeFormatter,
    classes: 'border-0 py-2 align-middle fs-0',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'invoice.amount_due',
    text: 'Amount',
    formatter: amountFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true,
    align: 'right',
    headerAlign: 'right',
  },
  {
    dataField: 'actions',
    isDummyField: true,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    text: '',
    formatter: actionFormatter,
  },
];

const Invoices = ({ showLoadMore = true }) => {
  const history = useNavigate();
  const { invoices, loading, hasMore } = useSelector((state) => state.invoice);
  const [filters, setFilters] = useState([]);
  const dispatch = useDispatch();
  const { isTest } = useContext(TestContext);
  useEffect(() => {
    dispatch(getInvoices(filters, undefined, isTest));
  }, [dispatch, filters, isTest]);

  return (
    <Card>
      <FalconCardHeader title="Invoices">
        <InvoiceExportButton />
        <DataFilter
          columns={[
            { label: 'Facility', type: 'string', filter: 'facility.facilityName' },
            { label: 'Invoice No', type: 'string', filter: 'invoice.number' },
            {
              label: 'Invoice Date',
              type: 'date',
              filter: 'invoice.created',
              conversion: (date) => date.getTime() / 1000,
            },
          ]}
          onSubmit={(filter) => {
            setFilters(filter);
            dispatch(getInvoices(filter, true, isTest));
          }}
        />
      </FalconCardHeader>
      <CardBody className="fs--1 p-0">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={showLoadMore ? invoices : invoices.slice(0, 5)}
          columns={columns}
          bordered={false}
          classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
        />
        <Row noGutters className="px-1 py-3  d-flex justify-content-center">
          <Col xs="auto" className="pr-3">
            {showLoadMore ? (
              <Button
                size="sm"
                onClick={() => {
                  dispatch(getInvoices(filters, undefined, isTest));
                }}
                className="px-4 ml-2 btn-light"
                disabled={!hasMore || loading}
              >
                {loading ? 'Loading...' : hasMore ? 'Load More' : 'No more items'}
              </Button>
            ) : (
              <Button
                size="sm"
                onClick={() => {
                  history.push('/invoices');
                }}
                className="px-4 ml-2 btn-light"
              >
                View All
              </Button>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Invoices;
