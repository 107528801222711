import firebase from 'firebase';
import { Collection } from '../constants';
import { auth, db } from '../firebase';
import { geohashQueryBounds, distanceBetween } from 'geofire-common';
import {
  Facility,
  PremierStatus,
  PremierWorker,
  Shift,
  UserDocument,
  UserStatus,
  UserType,
  WithId,
  Worker,
  WorkerTier,
} from '../types';
import moment from 'moment';
import { getAllBrookdaleFacilities } from './facility';
import { cloneDeep } from 'lodash';

const userCollection = db.collection(Collection.USER);
const facilitiesCollection = db.collection(Collection.FACILITIES);

export const getUserRef = (userId: string | undefined) => {
  if (userId) {
    return userCollection.doc(userId);
  }
  return userCollection;
};

export const getAllUsers = () => {
  return userCollection.get();
};

export const getAllWorkers = (isTest: boolean) => {
  return userCollection.where('userType', '==', UserType.WORKER).where('isTest', '==', isTest).get();
};

export const getUserData = (uid: string) => {
  return userCollection.doc(uid).get();
};

export const updateUserDataById = (user: Partial<Worker>, id: string) => {
  return userCollection.doc(id).set(user, { merge: true });
};

export const approveDocument = async (user: WithId<Worker>, type: keyof Worker, date: moment.Moment | undefined) => {
  const userRef = userCollection.doc(user.id);

  const file = user[type] as UserDocument;
  userRef.update({
    [type]: { fileUrl: file.fileUrl, status: 'accepted', expirationDate: date?.toDate() },
  });
};

export const rejectDocument = async (user: WithId<Worker>, type: keyof Worker) => {
  const userRef = userCollection.doc(user.id);
  const file = user[type] as UserDocument;
  userRef.update({
    [type]: { fileUrl: file.fileUrl, status: 'rejected' },
  });
};

export const checkDocuments = async (id: string): Promise<boolean> => {
  const userRef = firebase.firestore().collection(Collection.USER).doc(id);
  const user = (await userRef.get()).data() as Worker;
  return Promise.resolve(
    user.cprCertificationImage?.status === 'accepted' &&
      user.licenseImage?.status === 'accepted' &&
      user.tuberculosisTestImage?.status === 'accepted' &&
      user.idImage?.status === 'accepted'
  );
};

interface Options {
  userType?: UserType;
  isTest?: boolean;
  status?: UserStatus;
  suspended?: boolean;
}

export const getUsersByDistance = async (latitude: number, longitude: number, radiusInMiles: number, opts: Options) => {
  const radiusInMeters = radiusInMiles * 1609.34;
  const center = [latitude, longitude];
  const bounds = geohashQueryBounds(center, radiusInMeters);
  const promises = [];
  for (const b of bounds) {
    let q = db.collection(Collection.USER).orderBy('geohash').startAt(b[0]).endAt(b[1]);
    if (opts.userType !== undefined) {
      q = q.where('userType', '==', opts.userType);
    }
    if (opts.isTest !== undefined) {
      q = q.where('isTest', '==', opts.isTest);
    }
    if (opts.status !== undefined) {
      q = q.where('status', '==', opts.status);
    }
    if (opts.suspended !== undefined) {
      q = q.where('suspended', '==', opts.suspended);
    }
    promises.push(q.get());
  }
  const snapshots = await Promise.all(promises);
  const matchingDocs = [];
  for (const snap of snapshots) {
    for (const doc of snap.docs) {
      const lat = doc.data().coordinates.latitude;
      const lng = doc.data().coordinates.longitude;

      // We have to filter out a few false positives due to GeoHash
      // accuracy, but most will match
      const distanceInKm = distanceBetween([lat, lng], center);
      const distanceInM = distanceInKm * 1000;
      if (distanceInM <= radiusInMeters) {
        matchingDocs.push({ ...(doc.data() as Worker), id: doc.id });
      }
    }
  }
  return matchingDocs;
};

export const approveWorkerPremier = async (workerId: string, workerData: Worker) => {
  if (workerData.brookdalePremier?.status !== PremierStatus.REQUESTED) {
    throw new Error('Worker has not been premier requested');
  }
  const premierWorker: PremierWorker = {
    id: workerId,
    status: PremierStatus.APPROVED,
  };
  const brookdaleFacilities = (await getAllBrookdaleFacilities(workerData.isTest)) as WithId<Facility>[];
  const batch = db.batch();
  const workerRef = userCollection.doc(workerId);
  batch.update(workerRef, {
    brookdalePremier: {
      ...workerData.brookdalePremier,
      status: PremierStatus.APPROVED,
      approvedBy: auth.currentUser?.uid,
      approvedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    },
    workerTier: {
      tier: WorkerTier.PLATINUM,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      updatedBy: auth.currentUser?.uid,
    },
  });
  brookdaleFacilities.forEach(({ id, premierlist }) => {
    const facilitiesRef = facilitiesCollection.doc(id);
    const existingIndex = (premierlist || []).findIndex((premierWorker) => premierWorker.id === workerId);
    const newPremierlist = cloneDeep(premierlist);
    if (!newPremierlist) {
      console.error('Could not find worker in existing premierlist', { facilityId: id, premierlist, workerId });
      return;
    }
    newPremierlist[existingIndex] = premierWorker;
    batch.update(facilitiesRef, { premierlist: newPremierlist });
  });
  await batch.commit();
};

export const getWorkersFromShifts = async (shifts: Shift[]) => {
  const nurseIds = new Set<string>();
  shifts.forEach((shift) => {
    if (shift.nurseId) {
      nurseIds.add(shift.nurseId);
    }
  });
  const nursePromises = [...nurseIds].map((id: string) => {
    return new Promise((resolve) => {
      const getNurseData = async () => {
        const nurse = await getUserData(id);
        return {
          ...nurse.data(),
          id: nurse.id,
        };
      };
      getNurseData().then((nurseData) => resolve(nurseData));
    });
  });
  const nurseArr = (await Promise.all(nursePromises)) as WithId<Worker>[];
  return nurseArr.reduce((acc: { [nurseId: string]: WithId<Worker> }, nurse) => ({ ...acc, [nurse.id]: nurse }), {});
};

export const getTimesheetActionUsersFromShifts = async (shifts: Shift[]) => {
  const userIds = new Set<string>();
  shifts.forEach((shift) => {
    if (shift.timesheetActionMadeBy) {
      userIds.add(shift.timesheetActionMadeBy);
    }
  });
  const userPromises = [...userIds].map((id: string) => {
    return new Promise((resolve) => {
      const getuserData = async () => {
        const user = await getUserData(id);
        return {
          ...user.data(),
          id: user.id,
        };
      };
      getuserData().then((userData) => resolve(userData));
    });
  });
  const userArr = (await Promise.all(userPromises)) as WithId<Worker>[];
  return userArr.reduce((acc: { [userId: string]: WithId<Worker> }, user) => ({ ...acc, [user.id]: user }), {});
};
