import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { updateShift } from '../../api/shifts';
import { sleep } from '../../helpers/utils';
import MoneyInput from '../common/MoneyInput';
import { GenericBonus, GenericBonusRateType, Shift } from '../../types';
import { ModalFooter } from 'reactstrap';
import { useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface EditPayoutBonusModalProps {
  id: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: () => void;
  genericBonuses: GenericBonus[];
}

interface FormValues {
  bonusRateType: GenericBonusRateType;
  bonusValue: string;
  facilityBonusValue: string;
  bonusIndex: number;
  bonusLabel: string;
}

const EditPayoutBonusModal = ({ id, isOpen, setIsOpen, onSave, genericBonuses }: EditPayoutBonusModalProps) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm();
  const toast = useToast();
  const onClose = () => setIsOpen(false);
  const onDelete = async () => {
    const { bonusIndex, bonusLabel } = getValues();
    const newGenericBonuses = cloneDeep(genericBonuses);
    newGenericBonuses.splice(bonusIndex, 1);
    await updateShift(id, {
      genericBonuses: newGenericBonuses,
    });
    toast({
      title: `Successfully deleted bonus with label: ${bonusLabel}`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    onClose();
    await sleep(7000); // wait for payouts to be calculated on backend
    onSave();
  };
  const onSubmit = async ({ bonusRateType, bonusValue, bonusIndex, bonusLabel, facilityBonusValue }: FormValues) => {
    const bonus: GenericBonus = {
      value: Math.round(parseFloat(bonusValue) * 100),
      label: bonusLabel,
      rateType: bonusRateType,
    };
    if (facilityBonusValue) {
      bonus.facilityBonusValue = Math.round(parseFloat(facilityBonusValue) * 100);
    }
    const newGenericBonuses = cloneDeep(genericBonuses);
    newGenericBonuses[bonusIndex] = bonus;
    await updateShift(id, {
      genericBonuses: newGenericBonuses,
    });
    toast({
      title: 'Successfully edited bonus',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    onClose();
    await sleep(7000); // wait for payouts to be calculated on backend
    onSave();
  };

  useEffect(() => {
    setValue('bonusIndex', 0);
    setValue('bonusValue', (genericBonuses[0].value / 100).toString());
    if (genericBonuses[0].facilityBonusValue) {
      setValue('facilityBonusValue', (genericBonuses[0].facilityBonusValue / 100).toString());
    }
    setValue('bonusRateType', genericBonuses[0].rateType);
    setValue('bonusLabel', genericBonuses[0].label);
  }, [genericBonuses, setValue]);

  watch(async (data: any, { name, type }) => {
    const genericBonus = genericBonuses[parseInt(data.bonusIndex)];
    if (name === 'bonusIndex' && type === 'change' && genericBonus) {
      setValue('bonusValue', (genericBonus.value / 100).toString());
      if (genericBonus.facilityBonusValue) {
        setValue('facilityBonusValue', (genericBonus.facilityBonusValue / 100).toString());
      }
      setValue('bonusRateType', genericBonus.rateType);
      setValue('bonusLabel', genericBonus.label);
    }
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit generic payout bonus</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing={4} align="stretch" mb={4}>
              <Container>
                <FormControl isInvalid={Boolean(errors.bonusIndex)}>
                  <FormLabel htmlFor="bonusIndex">Choose bonus to edit</FormLabel>
                  <Controller
                    rules={{
                      required: 'Bonus is required',
                      maxLength: { value: 8, message: 'Bonus must be less than 8 characters' },
                    }}
                    render={({ field }) => (
                      <Select {...field}>
                        {genericBonuses.map((bonus: GenericBonus, index: number) => (
                          <option key={index} value={index}>
                            {bonus.label}
                          </option>
                        ))}
                      </Select>
                    )}
                    name="bonusIndex"
                    control={control}
                  />
                  <FormErrorMessage>{errors.bonusIndex && errors.bonusIndex.message}</FormErrorMessage>
                </FormControl>
              </Container>
              <Container>
                <FormControl isInvalid={Boolean(errors.bonusLabel)}>
                  <FormLabel htmlFor="bonusLabel">
                    Label&nbsp;
                    <Tooltip placement="top" label="This will be shown on the mobile app" aria-label="shown-on-app">
                      <span>
                        <FontAwesomeIcon icon="info-circle" />
                      </span>
                    </Tooltip>
                  </FormLabel>
                  <Controller
                    rules={{
                      required: 'Bonus label is required',
                      maxLength: { value: 8, message: 'Bonus label must be less than 8 characters' },
                    }}
                    render={({ field }) => <Input {...field} />}
                    name="bonusLabel"
                    control={control}
                  />
                  <FormErrorMessage>{errors.bonusLabel && errors.bonusLabel.message}</FormErrorMessage>
                </FormControl>
              </Container>
              <Container>
                <FormControl isInvalid={Boolean(errors.bonusRateType)}>
                  <FormLabel htmlFor="bonusRateType">Choose bonus rate type</FormLabel>
                  <Controller
                    rules={{ required: 'Bonus rate type is required' }}
                    render={({ field }) => (
                      <RadioGroup isInline spacing={4} {...field}>
                        <Radio value="perShift">Per shift</Radio>
                        <Radio value="perHour">Per hour</Radio>
                      </RadioGroup>
                    )}
                    name="bonusRateType"
                    control={control}
                  />
                  <FormErrorMessage>{errors.bonusRateType && errors.bonusRateType.message}</FormErrorMessage>
                </FormControl>
              </Container>
              <Container>
                <FormControl isInvalid={Boolean(errors.bonusValue)}>
                  <FormLabel htmlFor="bonusValue">Set bonus value for contractor</FormLabel>
                  <Controller
                    rules={{
                      required: 'Bonus value is required',
                      pattern: /^[0-9]*(\.\d{1,2})?$/g,
                    }}
                    render={({ field }) => <MoneyInput {...field} />}
                    name="bonusValue"
                    control={control}
                  />
                  <FormErrorMessage>{errors.bonusValue && errors.bonusValue.message}</FormErrorMessage>
                </FormControl>
              </Container>
              <Container>
                <FormControl isInvalid={Boolean(errors.facilityBonusValue)}>
                  <FormLabel htmlFor="facilityBonusValue">Set bonus rate to charge facility</FormLabel>
                  <Controller
                    rules={{
                      required: 'Bonus value is required',
                      pattern: /^[0-9]*(\.\d{1,2})?$/g,
                    }}
                    render={({ field }) => <MoneyInput {...field} />}
                    name="facilityBonusValue"
                    control={control}
                  />
                  <FormErrorMessage>{errors.facilityBonusValue && errors.facilityBonusValue.message}</FormErrorMessage>
                </FormControl>
              </Container>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              Close
            </Button>
            <Button onClick={onDelete} colorScheme="red" mr={3}>
              Delete
            </Button>
            <Button colorScheme="blue" mr={3} type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

interface EditPayoutBonusModalWrapperProps {
  id: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: () => void;
  shift: Shift;
}

const EditPayoutBonusModalWrapper = ({ shift, ...rest }: EditPayoutBonusModalWrapperProps) => {
  if (!shift || !shift.genericBonuses || !shift.genericBonuses.length) {
    return null;
  }
  return <EditPayoutBonusModal genericBonuses={shift.genericBonuses} {...rest} />;
};

export default EditPayoutBonusModalWrapper;
