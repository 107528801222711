import React, { useState } from 'react';
import { InputGroupButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';

export const StatsFilter = ({ onFilterSelected, filterName, options, style, defaultValue }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(defaultValue);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  return (
    <InputGroupButtonDropdown style={style} addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
      <DropdownToggle caret>{filterName}</DropdownToggle>
      <DropdownMenu>
        {options.flatMap((option, index) => (
          <DropdownItem
            key={index}
            active={selectedFilter?.value === option?.value}
            onClick={() => {
              setSelectedFilter(option);
              onFilterSelected(option);
            }}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </InputGroupButtonDropdown>
  );
};
