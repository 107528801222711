import firebase from 'firebase';
import { Parser } from 'json2csv';
import { useContext, useEffect, useState } from 'react';
import { db } from '../../firebase';
import { Collection } from '../../constants';
import { listAllAuthUsers } from '../../api/auth';
import moment from 'moment-timezone';
import { batchPromiseAll, formatPhoneNumber } from '../../helpers/utils';
import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import {
  Facility,
  UserDocument,
  UserType,
  UserStatus,
  WithId,
  Worker,
  NurseType,
  SuspendEvent,
  Shift,
} from '../../types';
import { getAllFacilities, getAllFacilityData, getFacilityData } from '../../api/facility';
import { TestContext } from '../../context/Context';
import Loader from './Loader';
import { getUsersByDistance } from '../../api/user';

enum WorkerType {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  APPLICANTS = 'applicants',
  ALL = 'all',
}

interface FormValues {
  facilityId: string;
  radiusInMi: string;
  workerType: WorkerType;
}

const getStatus = (doc: UserDocument | undefined) => {
  if (doc?.status === 'pending') {
    return 'Pending';
  }
  if (doc?.status === 'accepted') {
    return 'Accepted';
  }
  if (doc?.status === 'rejected') {
    return 'Rejected';
  }
  return 'Not Uploaded';
};

const promiseAllInBatches = async <T, R>(task: (arg: T) => Promise<R>, items: T[], batchSize: number): Promise<R[]> => {
  let position = 0;
  let results: R[] = [];
  while (position < items.length) {
    const itemsForBatch = items.slice(position, position + batchSize);
    results = [...results, ...(await Promise.all(itemsForBatch.map((item) => task(item))))];
    position += batchSize;
  }
  return results;
};

const getShift = async (shiftRef: firebase.firestore.DocumentReference<Shift>): Promise<WithId<Shift>> => {
  const shift = await shiftRef.get();
  return { ...(shift.data() as Shift), id: shift.id };
};

const getAllShifts = async (
  shifts: firebase.firestore.DocumentReference<Shift>[] | firebase.firestore.FieldValue,
  isTest: boolean
): Promise<WithId<Shift>[]> => {
  if (!Array.isArray(shifts)) {
    return [];
  }
  const shiftData = await promiseAllInBatches(
    (shift: firebase.firestore.DocumentReference<Shift>) =>
      new Promise(async (resolve: (shiftResult: WithId<Shift>) => void) => {
        const shiftResult = await getShift(shift);
        resolve(shiftResult);
      }),
    shifts,
    15
  );
  return shiftData.filter((shift) => shift.isTest === isTest);
};

const getShiftInfo = (shiftData: WithId<Shift>[]) => {
  const checkCompletedShift = (shift: WithId<Shift>) => shift.clockIn && shift.clockOut;
  const checkAMShift = (shift: WithId<Shift>) => {
    const shiftStart = moment(shift.start.toDate()).tz(shift.tz || 'America/Chicago');
    return shiftStart.hour() > 4 && shiftStart.hour() < 9;
  };
  const checkPMShift = (shift: WithId<Shift>) => {
    const shiftStart = moment(shift.start.toDate()).tz(shift.tz || 'America/Chicago');
    return shiftStart.hour() > 12 && shiftStart.hour() < 16;
  };
  const checkNOCShift = (shift: WithId<Shift>) => {
    const shiftStart = moment(shift.start.toDate()).tz(shift.tz || 'America/Chicago');
    return shiftStart.hour() > 19 && shiftStart.hour() < 24;
  };
  const totalCompletedShiftCount = shiftData.reduce((acc, shift) => {
    return checkCompletedShift(shift) ? acc + 1 : acc;
  }, 0);
  const amCompletedShiftCount = shiftData.reduce((acc, shift) => {
    return checkCompletedShift(shift) && checkAMShift(shift) ? acc + 1 : acc;
  }, 0);
  const pmCompletedShiftCount = shiftData.reduce((acc, shift) => {
    return checkCompletedShift(shift) && checkPMShift(shift) ? acc + 1 : acc;
  }, 0);
  const nocCompletedShiftCount = shiftData.reduce((acc, shift) => {
    return checkCompletedShift(shift) && checkNOCShift(shift) ? acc + 1 : acc;
  }, 0);
  const shiftFacilitySet = shiftData.reduce((acc, shift) => {
    if (!checkCompletedShift(shift)) {
      return acc;
    }
    acc.add(shift.facilityIdentifier);
    return acc;
  }, new Set<string>());

  return {
    amCompletedShiftCount,
    pmCompletedShiftCount,
    nocCompletedShiftCount,
    totalCompletedShiftCount,
    shiftFacilitySet,
  };
};

interface UserRecord {
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
  nurseType: NurseType | undefined;
  city: string;
  state: string;
  cprCertificationImageStatus: string;
  idImageStatus: string;
  licenseImageStatus: string;
  tuberculosisTestImageStatus: string;
  signupDatetime: string | null;
  lastSignedIn: string | null;
  suspendedReason: string | undefined;
  suspendedAt: string | undefined;
  totalCompletedShiftCount: number;
  amCompletedShiftCount: number;
  pmCompletedShiftCount: number;
  nocCompletedShiftCount: number;
  completedShiftStateList: string;
}

const getLastSuspendEvent = (suspendEvents: SuspendEvent[] | undefined) => {
  if (!suspendEvents || suspendEvents.length === 0 || suspendEvents[suspendEvents.length - 1].action === 'rehired') {
    return null;
  }
  return suspendEvents[suspendEvents.length - 1];
};

interface WorkersExportButtonProps {
  facilityOptions: { label: string; value: string }[];
}

const WorkersExportButton = ({ facilityOptions }: WorkersExportButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { isTest } = useContext(TestContext);
  const toast = useToast();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: { facilityId: 'none' },
  });

  const downloadReport = async (
    latitude: number | undefined,
    longitude: number | undefined,
    radiusInMi: number | undefined,
    workerType: WorkerType
  ) => {
    setLoading(true);
    try {
      let status;
      let suspended;
      if (workerType === WorkerType.APPLICANTS) {
        status = UserStatus.INACTIVE;
      } else if (workerType === WorkerType.SUSPENDED) {
        suspended = true;
      } else if (workerType === WorkerType.ACTIVE) {
        status = UserStatus.ACTIVE;
      }
      let userData: WithId<Worker>[];
      if (latitude && longitude && radiusInMi) {
        userData = await getUsersByDistance(latitude, longitude, radiusInMi, {
          isTest,
          userType: UserType.WORKER,
          ...(status === undefined ? {} : { status }),
          ...(suspended === undefined ? {} : { suspended }),
        });
      } else {
        let userQuery = db
          .collection(Collection.USER)
          .where('userType', '==', UserType.WORKER)
          .where('isTest', '==', isTest);
        if (suspended !== undefined) {
          userQuery = userQuery.where('suspended', '==', suspended);
        }
        if (status !== undefined) {
          userQuery = userQuery.where('status', '==', status);
        }
        const userSnaps = await userQuery.get();
        userData = userSnaps.docs.map((doc) => ({ ...(doc.data() as Worker), id: doc.id }));
      }
      const { data: authUsers } = await listAllAuthUsers();
      const facilities = (await getAllFacilityData(isTest)) as WithId<Facility>[];
      const facilityObj: { [id: string]: WithId<Facility> } = facilities.reduce(
        (acc, fac) => ({ ...acc, [fac.id]: fac }),
        {}
      );
      const userPromises = userData.map((userItem) => {
        return async (): Promise<UserRecord> => {
          const {
            firstName,
            lastName,
            phoneNumber,
            nurseType,
            city,
            state,
            cprCertificationImage,
            idImage,
            licenseImage,
            tuberculosisTestImage,
            createdAt,
            suspendEvents,
            shifts,
          } = userItem;
          const shiftData = await getAllShifts(shifts, isTest);
          const {
            totalCompletedShiftCount,
            amCompletedShiftCount,
            pmCompletedShiftCount,
            nocCompletedShiftCount,
            shiftFacilitySet,
          } = getShiftInfo(shiftData);
          const completedShiftStateSet = [...shiftFacilitySet].reduce((acc, facilityId) => {
            acc.add((facilityObj[facilityId] as WithId<Facility>).facilityState);
            return acc;
          }, new Set());
          const completedShiftStateList = [...completedShiftStateSet].join(', ');

          const suspendEvent = getLastSuspendEvent(suspendEvents);

          const user: UserRecord = {
            firstName,
            lastName,
            phoneNumber: formatPhoneNumber(phoneNumber),
            email: authUsers[userItem.id] ? authUsers[userItem.id]['email'] : null,
            nurseType,
            city,
            state,
            cprCertificationImageStatus: getStatus(cprCertificationImage),
            idImageStatus: getStatus(idImage),
            licenseImageStatus: getStatus(licenseImage),
            tuberculosisTestImageStatus: getStatus(tuberculosisTestImage),
            signupDatetime: createdAt
              ? moment(createdAt.toDate()).tz('America/Chicago').format('M/D/YYYY h:mm A')
              : null,
            lastSignedIn: authUsers[userItem.id]
              ? moment(authUsers[userItem.id]['lastSignedIn']).tz('America/Chicago').format('M/D/YYYY h:mm A')
              : null,
            suspendedReason: suspendEvent?.reason,
            suspendedAt: suspendEvent
              ? moment(suspendEvent.timestamp.toDate()).tz('America/Chicago').format('M/D/YYYY h:mm A')
              : undefined,
            amCompletedShiftCount,
            pmCompletedShiftCount,
            nocCompletedShiftCount,
            totalCompletedShiftCount,
            completedShiftStateList,
          };
          return user;
        };
      });
      const users: UserRecord[] = await batchPromiseAll(userPromises, 150);

      const now = new Date();
      const formattedDate = `${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}`;

      if (users.length > 0) {
        const parser = new Parser({
          fields: [
            { label: 'First Name', value: 'firstName' },
            { label: 'Last Name', value: 'lastName' },
            { label: `Phone Number`, value: 'phoneNumber' },
            { label: `Email`, value: 'email' },
            { label: `Position`, value: 'nurseType' },
            { label: `City`, value: 'city' },
            { label: `State`, value: 'state' },
            { label: 'CPR Certification', value: 'cprCertificationImageStatus' },
            { label: 'ID', value: 'idImageStatus' },
            { label: 'License', value: 'licenseImageStatus' },
            { label: 'Tuberculosis Test', value: 'tuberculosisTestImageStatus' },
            { label: 'Signup Datetime', value: 'signupDatetime' },
            { label: 'Last Signed In', value: 'lastSignedIn' },
            { label: 'Suspended At', value: 'suspendedAt' },
            { label: 'Suspended Reason', value: 'suspendedReason' },
            { label: 'AM Completed Shift Count', value: 'amCompletedShiftCount' },
            { label: 'PM Completed Shift Count', value: 'pmCompletedShiftCount' },
            { label: 'NOC Completed Shift Count', value: 'nocCompletedShiftCount' },
            { label: 'Total Completed Shift Count', value: 'totalCompletedShiftCount' },
            { label: 'Completed Shift State List', value: 'completedShiftStateList' },
          ],
        });
        save(`workers-${formattedDate}.csv`, parser.parse(users));
      }
    } catch (e: any) {
      console.error(e);
      toast({
        title: 'Failed to download report',
        description: e.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  function save(filename: string, data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }

  const Required = () => <span style={{ color: 'red' }}>&nbsp;*</span>;

  const onSubmit = async ({ facilityId, radiusInMi, workerType }: FormValues) => {
    try {
      let coordinates;
      if (facilityId !== 'none') {
        const facilityData = (await getFacilityData(facilityId)) as Facility;
        coordinates = facilityData.coordinates;
      }
      await downloadReport(
        coordinates?.latitude,
        coordinates?.longitude,
        radiusInMi ? parseInt(radiusInMi) : undefined,
        workerType
      );
      setIsOpen(false);
    } catch (e: any) {
      toast({
        title: 'Failed to download report',
        description: e.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        color="falcon-default"
        size="sm"
        className="ml-2"
      >
        Export
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Worker Export</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack spacing={4} align="stretch" mb={4}>
                <Container>
                  <FormControl isInvalid={Boolean(errors.workerType)}>
                    <FormLabel htmlFor="workerType">
                      Choose worker type
                      <Required />
                    </FormLabel>
                    <Controller
                      rules={{ required: 'Worker type is required' }}
                      render={({ field }) => (
                        <RadioGroup spacing={4} {...field}>
                          <Stack>
                            <Radio value="eligibleToWork">Eligible to work</Radio>
                            <Radio value="suspended">Suspended</Radio>
                            <Radio value="applicants">Applicants</Radio>
                            <Radio value="all">All</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      name="workerType"
                      control={control}
                    />
                    <FormErrorMessage>{errors.workerType && errors.workerType.message}</FormErrorMessage>
                  </FormControl>
                </Container>
                <Container>
                  <p>Choose a facility as the center point for the geo-query, and then choose a radius.</p>
                  <FormControl isInvalid={Boolean(errors.facilityId)}>
                    <FormLabel htmlFor="facilityId">Facility</FormLabel>
                    <Controller
                      render={({ field }) => (
                        <Select {...field}>
                          <option key="none" value="none">
                            None
                          </option>
                          {facilityOptions.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                              {opt.label}
                            </option>
                          ))}
                        </Select>
                      )}
                      name="facilityId"
                      control={control}
                    />
                    <FormErrorMessage>{errors.facilityId && errors.facilityId.message}</FormErrorMessage>
                  </FormControl>
                </Container>
                <Container>
                  <FormControl isInvalid={Boolean(errors.radiusInMi)}>
                    <FormLabel htmlFor="radiusInMi">Radius (mi)</FormLabel>
                    <Controller
                      render={({ field }) => <Input placeholder="25" type="number" {...field} />}
                      name="radiusInMi"
                      control={control}
                    />
                    <FormErrorMessage>{errors.radiusInMi && errors.radiusInMi.message}</FormErrorMessage>
                  </FormControl>
                </Container>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button onClick={() => setIsOpen(false)} variant="ghost">
                Close
              </Button>
              <Button isLoading={loading} colorScheme="blue" mr={3} type="submit">
                Export
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

const WorkersExportButtonWrapper = () => {
  const [facilityOptions, setFacilityOptions] = useState<{ label: string; value: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const { isTest } = useContext(TestContext);

  const getFacilities = async () => {
    setLoading(true);
    const facilities = await getAllFacilities(isTest);
    const facilityOptions = facilities.docs
      .map((facility) => ({
        label: facility.data().facilityName,
        value: facility.id,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1));
    setFacilityOptions(facilityOptions);
    setLoading(false);
  };

  useEffect(() => {
    getFacilities();
  }, [isTest]);

  if (loading) {
    return <Loader />;
  }
  return <WorkersExportButton facilityOptions={facilityOptions} />;
};

export default WorkersExportButtonWrapper;
