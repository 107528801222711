import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { changeEmail } from '../../api/auth';
import _ from 'lodash';
import { firebase } from '../../firebase';
import { validateEmail } from '../../helpers/utils';
import { updateFacilityAndUserDataById } from '../../api/facility';

const AccountEditModal = ({ isOpen, setIsOpen, initialAccountInformation, ownerId, onSave, facilityId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accountInformation, setAccountInformation] = useState({
    email: '',
    facilityName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    notificationEmails: '',
  });
  const toggle = () => setIsOpen(!isOpen);
  const closeBtn = (
    <button
      className="close font-weight-normal"
      onClick={() => {
        setAccountInformation(initialAccountInformation);
        toggle();
      }}
    >
      &times;
    </button>
  );

  useEffect(() => {
    setAccountInformation(initialAccountInformation);
  }, [initialAccountInformation]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (accountInformation.email !== initialAccountInformation.email) {
        await changeEmail({ userId: ownerId, newEmail: accountInformation.email });
      }
      const notificationEmails = accountInformation.notificationEmails.split(',').map((email) => email.trim());
      await updateFacilityAndUserDataById({
        facilityData: {
          facilityName: accountInformation.facilityName,
          facilityPhoneNumber: accountInformation.phoneNumber,
          ...(accountInformation.notificationEmails
            ? { notificationEmails }
            : { notificationEmails: firebase.firestore.FieldValue.delete() }),
        },
        facilityId,
        userData: {
          firstName: accountInformation.firstName,
          lastName: accountInformation.lastName,
        },
        userId: ownerId,
      });
      toast.success('Successfully updated account information');
      onSave();
    } catch (e) {
      toast.error(`Failed to update account information: ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  const onFormChange = (event) => {
    setAccountInformation({ ...accountInformation, [event.target.id]: event.target.value });
  };
  const hasChanged = !_.isEqual(accountInformation, initialAccountInformation);
  const requiredFields = ['facilityName', 'firstName', 'lastName', 'phoneNumber', 'email'];
  const isNotEmpty = requiredFields.every((key) => Boolean(accountInformation[key]));
  const notificationEmailArr = accountInformation.notificationEmails.split(',').map((email) => email.trim());
  const notificationEmailArrSet = new Set(notificationEmailArr);
  const noDupes = notificationEmailArrSet.size === notificationEmailArr.length;
  const isValid =
    !accountInformation.notificationEmails || (notificationEmailArr.every((email) => validateEmail(email)) && noDupes);
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader close={closeBtn}>Edit Account Information</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>
              Facility Name <span style={{ color: 'red' }}>*</span>
            </Label>
            <Input id="facilityName" value={accountInformation.facilityName} onChange={onFormChange} />
          </FormGroup>
          <FormGroup>
            <Label>
              Account Owner Email Address <span style={{ color: 'red' }}>*</span>
            </Label>
            <Input id="email" value={accountInformation.email} onChange={onFormChange} type="email" />
          </FormGroup>
          <FormGroup>
            <Label>
              Account Owner First Name <span style={{ color: 'red' }}>*</span>
            </Label>
            <Input id="firstName" value={accountInformation.firstName} onChange={onFormChange} />
          </FormGroup>
          <FormGroup>
            <Label>
              Account Owner Last Name <span style={{ color: 'red' }}>*</span>
            </Label>
            <Input id="lastName" value={accountInformation.lastName} onChange={onFormChange} />
          </FormGroup>
          <FormGroup>
            <Label>
              Phone Number <span style={{ color: 'red' }}>*</span>
            </Label>
            <Input id="phoneNumber" value={accountInformation.phoneNumber} onChange={onFormChange} />
          </FormGroup>
          <FormGroup>
            <Label>Notification Emails (comma-separated list)</Label>
            <Input
              placeholder="tanner@ptnurse.com, sales@ptnurse.com"
              id="notificationEmails"
              value={accountInformation.notificationEmails}
              onChange={onFormChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!hasChanged || !isNotEmpty || isLoading || !isValid} onClick={handleSubmit}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AccountEditModal;
