import { SHIFT_LIST_FAIL, SHIFT_LIST_REQUEST, SHIFT_LIST_SUCCESS, SHIFT_HAS_MORE } from '../constants/shiftConstants';

const initialState = {
  shifts: [],
  loading: false,
  error: false,
  hasMore: true,
  field: 'start',
  order: 'desc',
  filters: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHIFT_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case SHIFT_LIST_SUCCESS:
      let shifts = [];
      if (payload.empty || payload.isTest !== state.isTest) {
        shifts = [...payload.shifts];
      } else {
        shifts = [...state.shifts, ...payload.shifts];
      }
      return { ...state, ...payload, shifts, loading: false, isTest: payload.isTest };
    case SHIFT_LIST_FAIL:
      return { ...state, loading: false, error: true };
    case SHIFT_HAS_MORE:
      return { ...state, hasMore: payload };
    default:
      return state;
  }
};
