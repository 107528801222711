import { Collection } from '../../constants';
import { db } from '../../firebase';
import {
  INVOICE_HAS_MORE,
  INVOICE_LIMIT,
  INVOICE_LIST_FAIL,
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
} from '../constants/invoiceConstants';

export const getInvoices = (filters, first, isTest) => async (dispatch, getState) => {
  dispatch({
    type: INVOICE_LIST_REQUEST,
  });
  try {
    let invoicesSnap;
    let collection = db.collection(Collection.INVOICES).where('isTest', '==', isTest);
    const orders = new Set();
    filters.forEach((filter) => {
      collection = collection.where(...filter);
      if (filter[1] !== '==') orders.add(filter[0]);
    });
    const { invoices } = getState().invoice;
    orders.forEach((order) => {
      collection = collection.orderBy(order);
    });
    collection = orders.has('invoice.created') ? collection : collection.orderBy('invoice.created', 'desc');
    orders.forEach((order) => {
      if (invoices.length && invoices[invoices.length - 1] && !first)
        collection = collection.startAfter(invoices[invoices.length - 1][order]);
    });
    if (invoices.length && invoices[invoices.length - 1] && !first) {
      if (orders.size === 0) {
        invoicesSnap = await collection
          .startAfter(invoices[invoices.length - 1].invoice.created)
          .limit(INVOICE_LIMIT)
          .get();
      } else {
        invoicesSnap = await collection.limit(INVOICE_LIMIT).get();
      }
    } else {
      invoicesSnap = await collection.limit(INVOICE_LIMIT).get();
    }

    const invoicesArr = [];
    invoicesSnap.forEach((snapChild) => {
      const data = snapChild.data();
      invoicesArr.push(data);
    });
    dispatch({
      type: INVOICE_LIST_SUCCESS,
      payload: { invoices: invoicesArr, empty: first },
    });
    if (invoicesArr.length < INVOICE_LIMIT) {
      dispatch({
        type: INVOICE_HAS_MORE,
        payload: false,
      });
    } else {
      dispatch({
        type: INVOICE_HAS_MORE,
        payload: true,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: INVOICE_LIST_FAIL,
    });
  }
};
