import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Menu, MenuList, MenuItem, IconButton, MenuButton } from '@chakra-ui/react';

interface OverflowOption {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  key: string | number;
  isDisabled?: boolean;
}

interface OverflowProps {
  options: OverflowOption[];
}

const Overflow = ({ options }: OverflowProps) => {
  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="Options" icon={<FontAwesomeIcon icon="ellipsis-v" />} />
      <MenuList>
        {options.map(({ text, onClick, key, isDisabled }) => (
          <MenuItem isDisabled={isDisabled} key={key} onClick={onClick}>
            {text}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default Overflow;
