import {
  // eslint-disable-next-line
  googleAuthProvider,
  firebase,
  auth,
} from '../../firebase';
import { USER_LOGOUT } from '../constants/userConstants';
import { toast } from 'react-toastify';

// The logout action
export const startLogout = () => async (dispatch) => {
  await auth.signOut();
  dispatch(logout());

  toast.info(`Logged out successfully`, { hideProgressBar: true });
};

export const logout = () => ({
  type: USER_LOGOUT,
});
