import { db } from '../../firebase';
import {
  USER_SHIFT_HAS_MORE,
  USER_SHIFT_LIMIT,
  USER_SHIFT_LIST_FAIL,
  USER_SHIFT_LIST_REQUEST,
  USER_SHIFT_LIST_SUCCESS,
} from '../constants/userShiftConstants';
import _ from 'lodash';
import { Collection } from '../../constants';
import { getUserData } from '../../api/user';

export const getShiftsByUid = (uid, type, filters, field, order) => async (dispatch, getState) => {
  dispatch({
    type: USER_SHIFT_LIST_REQUEST,
  });
  try {
    let shiftsSnap;
    let collection = db.collection(Collection.SHIFTS);
    let first = false;
    let filtersOrder = null;

    filters.forEach((filter) => {
      collection = collection.where(...filter);
      filtersOrder = filter[0];
      // TODO: Make this more robust. If filtersOrder is a sortable field, then change the sort field to be filtersOrder
      if (filtersOrder === 'start') {
        field = filtersOrder;
      }
    });
    // This is required in order for firebase to be able to do inequality filters
    if (filtersOrder && filtersOrder !== field) {
      collection = collection.orderBy(filtersOrder);
    }
    const { shifts, field: stateField, order: stateOrder, filters: stateFilters } = getState().userShifts;
    if (field !== stateField || order !== stateOrder || !_.isEqual(filters, stateFilters)) {
      first = true; // start over whenever sorting or filtering changes
    }
    collection = collection.orderBy(field, order);
    // need to order by createdAt to make sorting deterministic when usual orderBy field has duplicates
    // so that pagination works deterministically
    collection = collection.orderBy('createdAt');
    if (shifts.length && shifts[shifts.length - 1] && !first) {
      shiftsSnap = await collection
        .where(type === 'facility' ? 'facilityIdentifier' : 'nurseId', '==', uid)
        .startAfter(shifts[shifts.length - 1][field], shifts[shifts.length - 1]['createdAt'])
        .limit(USER_SHIFT_LIMIT)
        .get();
    } else {
      shiftsSnap = await collection
        .where(type === 'facility' ? 'facilityIdentifier' : 'nurseId', '==', uid)
        .limit(USER_SHIFT_LIMIT)
        .get();
    }
    let shiftsArr = [];
    const nurseIds = {};
    shiftsSnap.forEach((snapChild) => {
      const data = {
        ...snapChild.data(),
        id: snapChild.id,
      };
      if (snapChild.data().nurseId) {
        nurseIds[snapChild.data().nurseId] = null;
      }
      shiftsArr.push(data);
    });
    const nursePromises = await Object.keys(nurseIds).map((id) => {
      return new Promise(async (resolve) => {
        const nurseSnap = await getUserData(id);
        const nurseName = `${nurseSnap.data().firstName.trim()} ${nurseSnap.data().lastName.trim()}`;
        resolve({ nurseName, nurseId: id });
      });
    });
    const nurseData = await Promise.all(nursePromises);
    nurseData.forEach((data) => {
      nurseIds[data.nurseId] = data.nurseName;
    });
    shiftsArr = shiftsArr.map((shift) => {
      if (shift.nurseId) {
        return { ...shift, nurseName: nurseIds[shift.nurseId] };
      }
      return { ...shift };
    });
    dispatch({
      type: USER_SHIFT_LIST_SUCCESS,
      payload: {
        shifts: shiftsArr,
        filters,
        field,
        order,
        empty: first,
      },
    });
    if (shiftsArr.length < USER_SHIFT_LIMIT) {
      dispatch({
        type: USER_SHIFT_HAS_MORE,
        payload: false,
      });
    } else {
      dispatch({
        type: USER_SHIFT_HAS_MORE,
        payload: true,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: USER_SHIFT_LIST_FAIL,
    });
  }
};
