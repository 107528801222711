import {
  INVOICE_LIST_FAIL,
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
  INVOICE_HAS_MORE,
} from '../constants/invoiceConstants';

const initialState = {
  invoices: [],
  loading: false,
  error: false,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INVOICE_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case INVOICE_LIST_SUCCESS:
      let invoices = [];
      if (payload.empty) {
        invoices = [...payload.invoices];
      } else {
        invoices = [...state.invoices, ...payload.invoices];
      }
      return { invoices, loading: false };
    case INVOICE_LIST_FAIL:
      return { ...state, loading: false, error: true };
    case INVOICE_HAS_MORE:
      return { ...state, hasMore: payload };
    default:
      return state;
  }
};
