import React, { useState } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ChangeEmailModal = ({ isOpen, setIsOpen, onSave, userId }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const closeBtn = (
    <button className="close font-weight-normal" onClick={() => setIsOpen(false)}>
      &times;
    </button>
  );
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader close={closeBtn}>Change Email</ModalHeader>
      <ModalBody>
        <Label> Enter new email</Label>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!email || loading}
          onClick={async () => {
            setLoading(true);
            await onSave({ newEmail: email, userId });
            setLoading(false);
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangeEmailModal;
