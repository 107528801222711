import {
  DISCONTENT_LIST_FAIL,
  DISCONTENT_LIST_REQUEST,
  DISCONTENT_LIST_SUCCESS,
  DISCONTENT_HAS_MORE,
} from '../constants/discontentConstants';

const initialState = {
  discontents: [],
  loading: false,
  error: false,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DISCONTENT_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case DISCONTENT_LIST_SUCCESS:
      let discontents = [];
      if (payload.empty) {
        discontents = [...payload.discontents];
      } else {
        discontents = [...state.discontents, ...payload.discontents];
      }
      return { discontents, loading: false };
    case DISCONTENT_LIST_FAIL:
      return { ...state, loading: false, error: true };
    case DISCONTENT_HAS_MORE:
      return { ...state, hasMore: payload };
    default:
      return state;
  }
};
