import { STATS_LIST_FAIL, STATS_LIST_REQUEST, STATS_LIST_SUCCESS, STATS_SORT } from '../constants/statsConstants';

const initialState = {
  stats: [],
  loading: false,
  error: false,
  filters: {
    timeframe: null,
    timeToStart: null,
  },
  field: 'droppedShifts',
  order: 'desc',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STATS_LIST_REQUEST:
      let filters = { ...state.filters };
      if (payload?.filter) {
        filters = { ...state.filters, ...payload.filter };
      }
      return { ...state, loading: true, error: false, filters };
    case STATS_LIST_SUCCESS:
      const stats = [...payload.stats];
      return { ...state, stats, loading: false };
    case STATS_LIST_FAIL:
      return { ...state, loading: false, error: true };
    case STATS_SORT:
      const { field, order } = payload;
      return { ...state, field, order, stats: [...payload.stats] };
    default:
      return state;
  }
};
