import { useContext, useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { getAllWorkers } from '../../api/user';
import { TestContext } from '../../context/Context';

interface WorkerSelectProps {
  workerId: string;
  setWorkerId: (workerId: string) => void;
}

interface Option {
  value: string;
  label: string;
}

const WorkerSelect = ({ workerId, setWorkerId }: WorkerSelectProps) => {
  const [options, setOptions] = useState<Option[]>([{ value: '', label: 'None' }]);
  const { isTest } = useContext(TestContext);
  useEffect(() => {
    const getUsers = async () => {
      const userSnapshot = await getAllWorkers(isTest);
      const opts: Option[] = [{ value: '', label: 'None' }];
      userSnapshot.forEach((user) => {
        const userData = user.data();
        opts.push({ value: user.id, label: `${userData.firstName} ${userData.lastName}` });
      });
      opts.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setOptions(opts);
    };
    getUsers();
  }, []);

  const value = options.find((opt) => opt.value === workerId);

  return (
    <Select
      value={value}
      onChange={(value) => setWorkerId(value?.value || '')}
      classNamePrefix="react-select"
      options={options}
      className="form-control"
      styles={{ container: (provided, state) => ({ ...provided, height: '40px', padding: '0px' }) }}
    />
  );
};

export default WorkerSelect;
