import { auth, functions } from '../firebase';

export const signup = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

export const signin = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const logout = () => {
  return auth.signOut();
};

export const changeEmail = ({ userId, newEmail }) => {
  const authChangeEmail = functions.httpsCallable('auth-changeEmail');
  return authChangeEmail({
    userId,
    newEmail,
  });
};

export const getAuthUser = async (userId) => {
  const authGetUser = functions.httpsCallable('auth-getUser');
  return authGetUser({
    userId,
  });
};

export const listAllAuthUsers = async () => {
  const authListAllUsers = functions.httpsCallable('auth-listAllUsers');
  return authListAllUsers();
};
