import { Parser } from 'json2csv';
import React from 'react';
import { useState } from 'react';
import { db } from '../../firebase';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ButtonIcon from './ButtonIcon';
import Datetime from 'react-datetime';
import moment from 'moment';
import { Collection } from '../../constants';

function DateSelectModal({ open, setOpen }) {
  const toggle = () => setOpen(!open);

  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();

  async function downloadReport() {
    const snaps = await db
      .collection(Collection.INVOICES)
      .where('invoice.created', '>=', moment(startDate?.toDate()).unix())
      .where('invoice.created', '<=', moment(endDate?.toDate()).unix())
      .get();
    const data = [];
    snaps.docs.forEach((doc) => {
      const d = doc.data();
      data.push({
        ...d,
        createdTime: moment.unix(d.invoice.created).toLocaleString(),
      });
    });
    console.log(data);
    if (data.length > 0) {
      const parser = new Parser({
        fields: [
          { label: 'Invoice No', value: 'invoice.number' },
          { label: 'Invoice Date', value: 'createdTime' },
          { label: 'Facility Name', value: 'facility.facilityName' },
          { label: 'Facility Address', value: 'facility.facilityAddress' },
          { label: 'Amount($)', value: (row) => `${row.invoice.amount_due / 100}` },
          { label: 'Status', value: 'invoice.status' },
          { label: 'Nurse', value: (row) => `${row.nurse.firstName} ${row.nurse.lastName}` },
          { label: 'Nurse Type', value: 'nurse.nurseType' },
          { label: 'Shift', value: 'shift.description' },
          { label: 'Clock In', value: (row) => row.shift.clockIn?.toDate().toLocaleString() },
          { label: 'Clock Out', value: (row) => row.shift.clockOut?.toDate().toLocaleString() },
        ],
      });
      save('report.csv', parser.parse(data));
    }
    toggle();
  }
  function save(filename, data) {
    var blob = new Blob([data], { type: 'text/csv' });
    if (typeof window.navigator.msSaveOrOpenBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Filter Start Date between</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            From Date
          </Label>
          <Datetime
            timeFormat={true}
            value={startDate}
            onChange={(dateTime) => {
              if (dateTime._isValid) {
                setStartDate(dateTime);
              }
            }}
            dateFormat="MM-DD-YYYY"
            inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'eventStart' }}
          />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            End Date
          </Label>
          <Datetime
            timeFormat={true}
            value={endDate}
            onChange={(dateTime) => {
              if (dateTime._isValid) {
                setEndDate(dateTime);
              }
            }}
            dateFormat="MM-DD-YYYY"
            inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'eventEnd' }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => downloadReport()}>
          Download
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default function InvoiceExportButton({ data = [], fields = [] }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ButtonIcon
        onClick={() => {
          setIsOpen(true);
        }}
        icon="external-link-alt"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        className="ml-2"
      >
        Export
      </ButtonIcon>
      <DateSelectModal open={isOpen} setOpen={setIsOpen} />
    </>
  );
}
