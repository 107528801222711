import { Collection } from '../constants';
import { db } from '../firebase';

const companiesCollection = db.collection(Collection.COMPANIES);

/* Facilities Table */

export const getCompanyData = async (id) => {
  const company = await getCompany(id);
  return company.data();
};

export const getCompanyRef = (userId) => {
  if (userId) {
    return companiesCollection.doc(userId);
  }
  return companiesCollection;
};

export const getAllFacilities = () => {
  return companiesCollection.get();
};

export const getCompany = (id) => {
  return companiesCollection.doc(id).get();
};

export const updateCompanyDataById = async (data, id) => {
  return companiesCollection.doc(id).set(data, { merge: true });
};

export const getOwnerIdFromFacilityData = async (facilityData) => {
  if (facilityData.ownerId) {
    return facilityData.ownerId;
  }
  if (facilityData.companyId) {
    const { ownerId } = await getCompanyData(facilityData.companyId);
    return ownerId;
  }
  return null;
};
