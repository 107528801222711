import { FACILITY_LIST_FAIL, FACILITY_LIST_REQUEST, FACILITY_LIST_SUCCESS } from '../constants/facilityConstants';

const initialState = {
  facilities: [],
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FACILITY_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case FACILITY_LIST_SUCCESS:
      const facilities = [...payload.facilities];
      return { facilities, loading: false };
    case FACILITY_LIST_FAIL:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
