import { Col, Row } from 'reactstrap';

interface CustomerDetailRowProps {
  title: string;
  text: React.ReactNode;
}

const CustomerDetailRow = ({ title, text }: CustomerDetailRowProps) => (
  <Row>
    <Col xs={5} sm={4}>
      {' '}
      <p className="font-weight-semi-bold fs--1"> {title} </p>{' '}
    </Col>
    <Col>
      <p className="fs--1">{text}</p>{' '}
    </Col>
  </Row>
);

export default CustomerDetailRow;
