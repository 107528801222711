import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import thunk from 'redux-thunk';

import { userListReducer } from '../reducers/userListReducer';
import invoiceReducer from '../reducers/invoiceReducer';
import payoutReducer from '../reducers/payoutReducer';

import { userEmailLoginReducer } from '../reducers/userEmailLoginReducer';
import shiftReducer from '../reducers/shiftReducer';
import nurseReducer from '../reducers/nurseReducer';
import statsReducer from '../reducers/statsReducer';
import applicantReducer from '../reducers/applicantReducer';
import facilityReducer from '../reducers/facilityReducer';
import discontentReducer from '../reducers/discontentReducer';
import userShiftReducer from '../reducers/userShiftReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// The first key is the name that shows up in the Redux devtools
const reducers = combineReducers({
  email: userEmailLoginReducer,
  users: userListReducer,
  userShifts: userShiftReducer,
  invoice: invoiceReducer,
  payout: payoutReducer,
  shift: shiftReducer,
  nurse: nurseReducer,
  applicant: applicantReducer,
  facility: facilityReducer,
  discontent: discontentReducer,
  stats: statsReducer,
});

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
