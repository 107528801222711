import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Heading, Container, Box, Button, Stack } from '@chakra-ui/react';
import { db } from '../../firebase';
import { Collection, Facility, Shift, WithId, Worker } from '../../types';
import moment from 'moment-timezone';
import { getUserData } from '../../api/user';
import { getFacilityData } from '../../api/facility';
import Loader from '../common/Loader';
import { updateShift } from '../../api/shifts';
import { TestContext } from '../../context/Context';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface Extras {
  facilityName: string | null;
  workerName: string | null;
}

type DecoratedShift = Extras & WithId<Shift>;

type Head = {
  label: React.ReactNode;
  key: keyof DecoratedShift;
  transform?: (val: any, shift: DecoratedShift) => React.ReactNode;
}[];

interface MarkNCNSProps {
  id: string;
  isNCNS: boolean | undefined;
  onUpdate: () => Promise<void>;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const MarkNCNS: React.FunctionComponent<MarkNCNSProps> = ({ id, isNCNS, onUpdate, onMouseEnter, onMouseLeave }) => {
  if (isNCNS) {
    return null;
  }
  return (
    <Button
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      onClick={async (e): Promise<void> => {
        e.stopPropagation();
        try {
          await updateShift(id, { isNCNS: true });
          toast.success('Successfully marked shift as NCNS');
        } catch (e) {
          toast.error('Failed to mark shift as NCNS');
        }
        await onUpdate();
      }}
    >
      Mark NCNS
    </Button>
  );
};

interface ShiftsNoTimesheetProps {
  shifts: DecoratedShift[];
  onUpdate: () => Promise<void>;
}

const MIN_SHIFTS_LENGTH = 5;

const ShiftsNoTimesheet: React.FunctionComponent<ShiftsNoTimesheetProps> = ({ shifts, onUpdate }) => {
  const [shiftCount, setShiftCount] = useState(5);
  const [hover, setHover] = useState<string | null>(null);
  const navigate = useNavigate();
  const head: Head = [
    {
      label: 'Worker',
      key: 'workerName',
    },
    {
      label: 'Facility',
      key: 'facilityName',
    },
    {
      label: 'Start',
      key: 'start',
      transform: (val: any, shift: DecoratedShift): React.ReactNode =>
        moment(val.toDate())
          .tz(shift.tz || 'America/Chicago')
          .format('M/D/YY h:mm a zz'),
    },
    {
      label: 'End',
      key: 'end',
      transform: (val: any, shift: DecoratedShift): React.ReactNode =>
        moment(val.toDate())
          .tz(shift.tz || 'America/Chicago')
          .format('M/D/YY h:mm a zz'),
    },
    {
      label: null,
      key: 'isNCNS',
      transform: function isNCNSTransform(val: any, shift: DecoratedShift): React.ReactNode {
        return (
          <MarkNCNS
            isNCNS={val}
            id={shift.id}
            onUpdate={onUpdate}
            onMouseEnter={() => setHover(null)}
            onMouseLeave={() => setHover(shift.id)}
          />
        );
      },
    },
  ];
  console.log(shifts.length);
  return (
    <Container
      maxWidth="100%"
      padding="0"
      boxShadow="0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%)"
      borderRadius=".375rem"
    >
      <Box backgroundColor="white" padding={4}>
        <Heading as="h5" size="sm">
          Shifts without timesheet data
        </Heading>
      </Box>
      <Table variant="striped" size="sm" style={{ backgroundColor: 'white' }}>
        <Thead>
          <Tr>
            {head.map((h) => (
              <Th key={h.key}>{h.label}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {shifts.slice(0, shiftCount).map((shift, index) => {
            return (
              <Tr
                key={index}
                onMouseEnter={() => setHover(shift.id)}
                onMouseLeave={() => setHover(null)}
                onClick={() => navigate(`/shifts/${shift.id}`)}
                style={hover === shift.id ? { cursor: 'pointer', border: '2px solid rgb(44, 123, 229)' } : {}}
              >
                {head.map((h) => (
                  <Td key={h.key}>{h.transform ? h.transform(shift[h.key], shift) : shift[h.key]}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Box display="flex" padding={4} justifyContent="center" alignItems="center" flexDirection="row">
        <Stack direction="row">
          {shiftCount > MIN_SHIFTS_LENGTH && (
            <Button onClick={(): void => setShiftCount(Math.max(MIN_SHIFTS_LENGTH, shiftCount - 5))}>Load Less</Button>
          )}
          {shiftCount < shifts.length && (
            <Button onClick={(): void => setShiftCount(Math.min(shifts.length, shiftCount + 5))}>Load More</Button>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

const ONE_MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000;

const ShiftsNoTimesheetWrapper: React.FunctionComponent = () => {
  const [shifts, setShifts] = useState<DecoratedShift[]>();
  const [loading, setLoading] = useState(false);
  const { isTest } = useContext(TestContext);
  const getShifts = useCallback(async () => {
    setLoading(true);
    const now = new Date();
    const oneMonthAgo = new Date(now.getTime() - ONE_MONTH_IN_MS);
    const shiftsRes = await db
      .collection(Collection.SHIFTS)
      .where('end', '<', now)
      .where('end', '>', oneMonthAgo)
      .where('isTest', '==', isTest)
      .orderBy('end', 'desc')
      .get();
    const workers: { [id: string]: string | null } = {};
    const facilities: { [id: string]: string | null } = {};
    const shifts: WithId<Shift>[] = shiftsRes.docs
      .map<WithId<Shift>>((doc) => {
        const shift = doc.data() as Shift;
        return { ...shift, id: doc.id };
      })
      .filter(
        ({ clockIn, clockOut, nurseId, isNCNS, status }) =>
          nurseId && (!clockIn || !clockOut) && !isNCNS && status !== 'cancelled'
      );

    // Get set of worker and facility ids
    shifts.forEach((shift) => {
      if (shift.nurseId) {
        workers[shift.nurseId] = null;
      }
      facilities[shift.facilityIdentifier] = null;
    });

    // Fetch worker and facility names
    await Promise.all([
      ...Object.keys(workers).map(async (workerId) => {
        const user = await getUserData(workerId);
        const { firstName, lastName } = user.data() as Worker;
        workers[workerId] = `${firstName.trim()} ${lastName.trim()}`;
      }),
      ...Object.keys(facilities).map(async (facilityId) => {
        const { facilityName } = (await getFacilityData(facilityId)) as Facility;
        facilities[facilityId] = facilityName;
      }),
    ]);

    // Populate shifts with worker and facility info
    const finalShifts: DecoratedShift[] = shifts.map((shift) => {
      let facilityName = null;
      let workerName = null;
      if (shift.nurseId && shift.nurseId in workers) {
        workerName = workers[shift.nurseId];
      }
      if (shift.facilityIdentifier && shift.facilityIdentifier in facilities) {
        facilityName = facilities[shift.facilityIdentifier];
      }
      return { ...shift, workerName, facilityName };
    });
    setShifts(finalShifts);
    setLoading(false);
  }, [isTest]);

  useEffect(() => {
    getShifts();
  }, [getShifts, isTest]);

  if (loading) {
    return <Loader />;
  }
  if (!shifts) {
    return <p>Error loading shifts</p>;
  }
  return <ShiftsNoTimesheet shifts={shifts} onUpdate={getShifts} />;
};

export default ShiftsNoTimesheetWrapper;
