import React from 'react';
import { Route, Outlet, Routes, Navigate } from 'react-router-dom';

interface PublicRouteProps {
  isAuthenticated: boolean;
  component: React.FunctionComponent;
}

export const PublicRoute: React.FunctionComponent<PublicRouteProps> = ({ isAuthenticated, component: Component }) => {
  console.log('PublicRoute');
  return (
    <Routes>
      {isAuthenticated ? <Route path="*" element={<Navigate to="/" />} /> : <Route path="*" element={<Component />} />}
    </Routes>
  );
};
