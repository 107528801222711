import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const StatsModal = ({ setIsOpenStatsModal, isOpenStatsModal, statsModalType, events, nurseName }) => {
  const toggle = () => setIsOpenStatsModal(!isOpenStatsModal);
  const formattedModalType = statsModalType === 'pickedShifts' ? 'Picked Shift Events' : 'Dropped Shift Events';
  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );

  const anchorFormatter = (shiftId) => (
    <a target="_blank" rel="noreferrer" href={`/shifts/${shiftId}`}>
      <b>View Shift</b>
    </a>
  );
  function secondsToString(seconds) {
    var numdays = Math.floor((seconds % 31536000) / 86400);
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.round((((seconds % 31536000) % 86400) % 3600) / 60);
    return numdays + ' days ' + numhours + ' hours ' + numminutes + ' minutes';
  }

  const secondsFormatter = (seconds) => <span>{secondsToString(seconds)}</span>;

  const timeFormatter = (time) => <span>{time?.toDate().toLocaleString()}</span>;

  const columns = [
    {
      dataField: 'createdAt',
      text: 'Event Time',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: 'secondsToStart',
      text: 'Time to shift start',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: secondsFormatter,
    },
    {
      dataField: 'shiftId',
      headerClasses: 'border-0',
      text: 'Shift Details',
      classes: 'border-0 py-2 align-middle',
      sort: false,
      formatter: anchorFormatter,
    },
  ];

  return (
    <Modal size="lg" isOpen={isOpenStatsModal} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>
        {`${nurseName} - ${formattedModalType}`}
      </ModalHeader>
      <ModalBody>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={events.map((event) => ({
            ...event,
          }))}
          columns={columns}
          bordered={false}
          classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
        />
      </ModalBody>
    </Modal>
  );
};

export default StatsModal;
