import firebase from 'firebase';
import { NurseType } from './user';

export interface DashboardShift {
  additionalnotes?: Shift['additionalnotes'];
  additionalRequirements?: AdditionalRequirements[] | undefined;
  clockInTime?: string;
  clockOutTime?: string;
  description: string;
  end: firebase.firestore.Timestamp;
  endTime: string;
  facilityIdentifier: string;
  facilityName: string;
  hasShiftDiscontent: boolean;
  id: string;
  isFree: boolean;
  nurseId: string | undefined;
  startTime: string;
  status?: Shift['status'];
  numberOfNurse: number;
  nurseType: NurseType;
  start: firebase.firestore.Timestamp;
}

export enum ShiftStatus {
  CANCELLED = 'cancelled',
}

export enum AdditionalRequirements {
  MED_PASS = 'MeD Pass',
  COVID_VACCINATION = 'COVID Vaccination',
}

export enum GenericBonusRateType {
  PER_HOUR = 'perHour',
  PER_SHIFT = 'perShift',
}

export interface GenericBonus {
  label: string;
  rateType: GenericBonusRateType;
  value: number;
  facilityBonusValue?: number;
}

export enum PayoutAddOnType {
  COVID = 'COVID',
  WISCONSIN_CAREGIVER_MED_PASS = 'WISCONSIN_CAREGIVER_MED_PASS',
  WEEKEND = 'WEEKEND',
  PLATINUM = 'PLATINUM',
}

export interface PayoutAddOn {
  value: number;
  type: PayoutAddOnType | string;
  rateType?: GenericBonusRateType;
}

export interface Payout {
  totalAmount: number | null;
  baseRate: number;
  totalRate: number;
  hours: number | null;
  addOns: PayoutAddOn[];
  extraBonus?: number;
}

export interface BillRate {
  totalAmount: number | null;
  baseRate: number;
  totalRate: number;
  hours: number | null;
  addOns: PayoutAddOn[];
  extraBonus?: number;
}

export enum TimeAdjustmentName {
  LUNCH_BREAK = 'lunchBreak',
}

export interface TimeAdjustment {
  name: TimeAdjustmentName;
  timeInSecondsToAdd: number;
}

export interface Shift {
  additionalnotes?: string;
  additionalRequirements?: AdditionalRequirements[];
  coordinates?: firebase.firestore.GeoPoint;
  clockIn?: firebase.firestore.Timestamp;
  clockOut?: firebase.firestore.Timestamp;
  cancelledAt?: firebase.firestore.Timestamp;
  description: string;
  end: firebase.firestore.Timestamp;
  facilityIdentifier: string;
  genericBonuses?: GenericBonus[];
  isCovidBonus?: boolean;
  isFree: boolean;
  isNCNS?: boolean;
  numberOfNurse: number | string;
  nurseId?: string;
  nurseType: NurseType;
  start: firebase.firestore.Timestamp;
  status?: ShiftStatus;
  tz?: string;
  payout?: Payout;
  billRate?: BillRate;
  isTest: boolean;
  timeAdjustments?: TimeAdjustment[];
  timesheetActionMadeBy?: string;
  timesheetActionMadeAt?: firebase.firestore.Timestamp;
  timesheetAction?: TimesheetAction;
}

export interface ShiftExportRow extends Shift {
  id: string;
  startTime: string;
  endTime: string;
  isMedPass: boolean;
  isCancelled: string;
  cancelledAtStr: string | undefined;
  nurseName?: string;
  facilityName?: string;
  facilityState?: string;
  hasCovidVaccinationRequirement?: boolean;
  distanceInMi?: number;
  timesheetActionMadeByFullName?: string;
}

export enum TimesheetAction {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
