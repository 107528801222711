import { useState } from 'react';
import { db, firebase } from '../../firebase';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Datetime from 'react-datetime';
import { Collection } from '../../constants';

const TIME_DIFF_THRESHOLD_IN_HOURS = 5;

function DateSelectModal({ open, setOpen, shiftId, action, label, onComplete, initialValues }) {
  const toggle = () => setOpen(!open);

  const [date, setDate] = useState(initialValues[action]);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const checkErrors = () => {
    const proposedNewValue = date.toDate();
    if (action === 'clockIn') {
      const differenceInHours = Math.abs(initialValues.start - proposedNewValue) / (1000 * 60 * 60);
      if (differenceInHours > TIME_DIFF_THRESHOLD_IN_HOURS && !warning) {
        setWarning(
          `Clock in is not within ${TIME_DIFF_THRESHOLD_IN_HOURS} hours of start time. Are you sure this is correct?`
        );
        return true;
      }
      if (initialValues.clockOut && proposedNewValue > initialValues.clockOut) {
        setError('Clock in must be before clock out');
        return true;
      }
    }
    if (action === 'clockOut') {
      const differenceInHours = Math.abs(initialValues.end - proposedNewValue) / (1000 * 60 * 60);
      if (differenceInHours > TIME_DIFF_THRESHOLD_IN_HOURS && !warning) {
        setWarning(
          `Clock out is not within ${TIME_DIFF_THRESHOLD_IN_HOURS} hours of end time. Are you sure this is correct?`
        );
        return true;
      }
      if (initialValues.clockIn && proposedNewValue < initialValues.clockIn) {
        setError('Clock out must be after clock in');
        return true;
      }
    }
    return false;
  };
  async function performAction(shouldRemove = false) {
    setError(null);
    setWarning(null);
    let value;
    if (shouldRemove) {
      value = firebase.firestore.FieldValue.delete();
    } else {
      if (checkErrors()) {
        return;
      }
      value = date.toDate();
    }
    await db
      .collection(Collection.SHIFTS)
      .doc(shiftId)
      .update({
        [action]: value,
      });

    if (onComplete) {
      onComplete();
    }
    toggle();
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {`${label} `}
        {action === 'clockIn' ? 'Clock In' : 'Clock Out'}{' '}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            Time
          </Label>
          <Datetime
            timeFormat={true}
            value={date}
            onChange={(dateTime) => {
              if (dateTime._isValid) {
                setDate(dateTime);
              }
            }}
            dateFormat="MM-DD-YYYY"
            inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'eventStart' }}
          />
        </FormGroup>
        {warning && <p style={{ color: '#c27800' }}>{warning}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div>
            {label === 'Edit' && (
              <Button color="danger" onClick={() => performAction(true)}>
                Remove
              </Button>
            )}
          </div>
          <div>
            <Button style={{ marginRight: '7px' }} color="primary" onClick={() => performAction()}>
              {label}
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default function ClockInClockOutButton({ action, label, shiftId, onComplete, initialValues }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        style={{ zoom: 0.9 }}
        className="px-0 btn btn-sm btn-link text-primary"
      >
        {label}
      </button>
      <DateSelectModal
        open={isOpen}
        setOpen={setIsOpen}
        action={action}
        label={label}
        shiftId={shiftId}
        onComplete={onComplete}
        initialValues={initialValues}
      />
    </>
  );
}
