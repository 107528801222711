import firebase from 'firebase';
import { auth, db, functions } from '../firebase';
import { checkDocuments, getTimesheetActionUsersFromShifts, getUserRef, getWorkersFromShifts } from './user';
import { geohashQueryBounds, distanceBetween } from 'geofire-common';
import { AdditionalCapabilities, AdditionalRequirements, Collection, NurseType } from '../constants';
import { getFacilitiesFromShifts } from './facility';

const shiftsCollection = db.collection(Collection.SHIFTS);

export const getAllShifts = () => {
  return shiftsCollection.orderBy('start', 'desc').get();
};

export const getShiftData = (uid) => {
  return shiftsCollection.doc(uid).get();
};

export const updateShift = (uid, data) => {
  return shiftsCollection.doc(uid).update(data);
};

export const getAvailableShiftsList = () => {
  return shiftsCollection.where('isFree', '==', true).orderBy('startsAt', 'asc');
};

export const createShift = async (data, facility) => {
  if (data.nurseId) {
    const documentChecked = await checkDocuments(data.nurseId);
    if (!documentChecked) {
      throw new Error('Worker does not have documents approved');
    }
  }
  if (!facility.allowedPositions.includes(data.nurseType)) {
    throw new Error(
      `${data.nurseType} shifts are not allowed for this facility. Allowed positions: ${facility.allowedPositions.join(
        ', '
      )}`
    );
  }

  const batch = db.batch();
  const numberOfShifts = data.numberOfShifts;

  for (let i = 0; i < numberOfShifts; i++) {
    const newShiftRef = shiftsCollection.doc();
    await batch.set(newShiftRef, {
      ...data,
      createdBy: auth.currentUser?.uid,
      createdAt: new Date(),
    });
    if (data.nurseId) {
      const userRef = getUserRef(data.nurseId);
      await batch.update(userRef, {
        shifts: firebase.firestore.FieldValue.arrayUnion(shiftsCollection.doc(newShiftRef.id)),
      });
    }
  }
  await batch.commit();
};

export const pickShift = async (userId, shiftId, shouldCheckDocuments) => {
  const documentChecked = await checkDocuments(userId);
  if (!shouldCheckDocuments || documentChecked) {
    await db.runTransaction(async (tx) => {
      const shiftRef = shiftsCollection.doc(shiftId);
      await tx.update(shiftRef, { isFree: false, nurseId: userId, addedWorkerByAdmin: auth.currentUser?.uid });
      const userRef = getUserRef(userId);
      await tx.update(userRef, {
        shifts: firebase.firestore.FieldValue.arrayUnion(shiftsCollection.doc(shiftId)),
      });
    });
    return Promise.resolve(true);
  } else {
    return Promise.resolve(false);
  }
};

export const leaveShift = async (shiftId) => {
  await db.runTransaction(async (tx) => {
    const shiftRef = shiftsCollection.doc(shiftId);
    const shiftSnap = await shiftRef.get();
    const nurseId = shiftSnap.data().nurseId;
    await tx.update(shiftRef, {
      isFree: true,
      nurseId: firebase.firestore.FieldValue.delete(),
      removedWorkerByAdmin: auth.currentUser?.uid,
    });
    const userRef = getUserRef(nurseId);
    await tx.update(userRef, {
      shifts: firebase.firestore.FieldValue.arrayRemove(shiftsCollection.doc(shiftId)),
    });
  });
};

const milesToMeters = (x) => x * 1609.34;

export const getTokensByLocation = async (coordinates, radiusesInMiles, shift, facility, isTest) => {
  const requiresMedPass = checkShiftRequiresMedpass(shift);
  const radiusesInMeters = radiusesInMiles.map(milesToMeters);
  const queryRefs = [];
  const center = [coordinates.latitude, coordinates.longitude];
  radiusesInMeters.forEach(async (radius, index) => {
    const bounds = geohashQueryBounds(center, radius);
    const matchingDocs = [];

    for (const b of bounds) {
      const q = getEligibleUsersForShift(shift, requiresMedPass, isTest)
        .orderBy('location.geohash')
        .startAt(b[0])
        .endAt(b[1]);
      queryRefs.push({ q, index });
      matchingDocs.push([]);
    }
  });
  const queryPromises = queryRefs.map(
    ({ q, index }) =>
      new Promise(async (resolve) => {
        const snap = await q.get();
        const tokens = [];
        for (const doc of snap.docs) {
          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          let isWithinRadius = true;
          const { location, token, suspended, additionalCapabilities } = doc.data();
          if (location.coordinates) {
            const distanceInKm = distanceBetween(
              [location.coordinates.latitude, location.coordinates.longitude],
              center
            );
            const distanceInM = distanceInKm * 1000;
            isWithinRadius = distanceInM <= radiusesInMeters[index];
          }
          if (
            !suspended &&
            isWithinRadius &&
            !(facility.blocklist || []).includes(doc.id) &&
            token &&
            !token.includes('ExponentPushToken') &&
            (!(shift.additionalRequirements || []).includes(AdditionalRequirements.COVID_VACCINATION) ||
              (additionalCapabilities || []).includes(AdditionalCapabilities.COVID_VACCINATION))
          ) {
            tokens.push(token);
          }
        }
        resolve({ tokens, index });
      })
  );
  const values = await Promise.all(queryPromises);
  return values.reduce((acc, { tokens, index }) => {
    if (!acc[radiusesInMiles[index]]) {
      acc[radiusesInMiles[index]] = [];
    }
    acc[radiusesInMiles[index]].push(...tokens);
    return acc;
  }, {});
};

export const checkShiftRequiresMedpass = (shift) => {
  const nurseTypeMustMedPass = [NurseType.LPN, NurseType.RN].includes(shift.nurseType);
  return (shift.additionalRequirements || []).includes(AdditionalRequirements.MED_PASS) || nurseTypeMustMedPass;
};

const getEligibleUsersForShift = (shift, requiresMedPass, isTest) => {
  const { nurseType } = shift;
  let reference = getUserRef().where('status', '==', 'active');
  if (isTest) {
    // if !isTest, we still want to send to test users
    reference = reference.where('isTest', '==', true);
  }
  switch (nurseType) {
    case NurseType.CAREGIVER:
      const nurseTypeOptionsCaregiver = [NurseType.CAREGIVER, NurseType.CNA, NurseType.LPN, NurseType.RN];
      if (requiresMedPass) {
        reference = reference.where('additionalCapabilities', 'array-contains', AdditionalCapabilities.MED_PASS);
      }
      reference = reference.where('nurseType', 'in', nurseTypeOptionsCaregiver);
      break;
    case NurseType.CNA:
      const nurseTypeOptionsCNA = [NurseType.CNA, NurseType.LPN, NurseType.RN];
      if (requiresMedPass) {
        reference = reference.where('additionalCapabilities', 'array-contains', AdditionalCapabilities.MED_PASS);
      }
      reference = reference.where('nurseType', 'in', nurseTypeOptionsCNA);
      break;
    case NurseType.LPN:
      reference = reference.where('nurseType', 'in', [NurseType.LPN, NurseType.RN]);
      break;
    case NurseType.RN:
      reference = reference.where('nurseType', 'in', [NurseType.RN]);
      break;
    default:
      return reference;
  }
  return reference;
};

// The Delete Shift action
export const cancelShift = (shiftId, reason, reasonOtherDetails) => {
  return db
    .collection(Collection.SHIFTS)
    .doc(shiftId)
    .set(
      {
        status: 'cancelled',
        cancelledAt: new Date(),
        cancelledBy: auth.currentUser?.uid,
        cancellationReason: {
          type: reason,
          ...(reasonOtherDetails ? { details: reasonOtherDetails } : {}),
        },
      },
      { merge: true }
    );
};

export const updatePayouts = ({ minStartDateStr, maxStartDateStr }) => {
  const updatePayoutsForShifts = functions.httpsCallable('finances-updatePayoutsForShifts');
  return updatePayoutsForShifts({
    minStartDateStr,
    maxStartDateStr,
  });
};

export const getShiftsByDistance = async (latitude, longitude, radiusInMiles, opts, postOpts) => {
  const radiusInMeters = radiusInMiles * 1609.34;
  const center = [latitude, longitude];
  const bounds = geohashQueryBounds(center, radiusInMeters);
  const promises = [];
  for (const b of bounds) {
    let q = db.collection(Collection.SHIFTS).orderBy('geohash').startAt(b[0]).endAt(b[1]);
    opts.forEach(({ field, op, value }) => {
      q = q.where(field, op, value);
    });
    promises.push(q.get());
  }
  const snapshots = await Promise.all(promises);
  const matchingDocs = [];
  for (const snap of snapshots) {
    for (const doc of snap.docs) {
      if (!doc.data().coordinates) {
        continue;
      }
      const lat = doc.data().coordinates.latitude;
      const lng = doc.data().coordinates.longitude;

      // We have to filter out a few false positives due to GeoHash
      // accuracy, but most will match
      const distanceInKm = distanceBetween([lat, lng], center);
      const distanceInM = distanceInKm * 1000;
      const distanceInMi = distanceInM / 1609.34;
      if (distanceInM <= radiusInMeters) {
        matchingDocs.push({ ...doc.data(), id: doc.id, distanceInMi });
      }
    }
  }
  return matchingDocs.filter((shift) => {
    return postOpts.every((fn) => fn(shift));
  });
};

export const getShiftsByDate = async (date, facilityId) => {
  const nextSunday = new Date(date);
  nextSunday.setDate(date.getDate() + 7);

  const shifts = await db
    .collection(Collection.SHIFTS)
    .where('start', '>', date)
    .where('start', '<', nextSunday)
    .where('facilityIdentifier', '==', facilityId)
    .orderBy('start', 'asc')
    .get();
  const result = [];

  shifts.docs.forEach((shift) => {
    const shData = shift.data();
    if (shData.status === 'cancelled' || !shData.nurseId) {
      return;
    }
    const shiftResult = { id: shift.id, ...shData };
    if (!shiftResult.clockIn) {
      shiftResult.clockIn = shiftResult.start;
    }
    if (!shiftResult.clockOut) {
      shiftResult.clockOut = shiftResult.end;
    }
    result.push(shiftResult);
  });
  const nurses = await getWorkersFromShifts(result);
  const facilities = await getFacilitiesFromShifts(result);
  const timesheetActionMadeBy = await getTimesheetActionUsersFromShifts(result);
  return result.map((res) => ({
    ...res,
    ...(res.nurseId
      ? { workerFullName: `${nurses[res.nurseId].firstName.trim()} ${nurses[res.nurseId].lastName.trim()}` }
      : {}),
    facilityName: facilities[res.facilityIdentifier].facilityName,
    ...(res.timesheetActionMadeBy
      ? {
          timesheetActionMadeByFullName: `${timesheetActionMadeBy[
            res.timesheetActionMadeBy
          ].firstName.trim()} ${timesheetActionMadeBy[res.timesheetActionMadeBy].lastName.trim()}`,
        }
      : {}),
  }));
};
