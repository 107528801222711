import { SyntheticEvent, useEffect, useState } from 'react';
import { updatePayouts } from '../../api/shifts';
import Loader from '../common/Loader';

export const UpdatePayouts = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      setSuccess(false);
      setError('');
      const target = e.target as typeof e.target & {
        minStart: { value: string };
        maxStart: { value: string };
      };
      await updatePayouts({ minStartDateStr: target.minStart.value, maxStartDateStr: target.maxStart.value });
      setSuccess(true);
    } catch (e: any) {
      setError(e.message);
    }
    setLoading(false);
  };
  if (loading) {
    return <Loader />;
  }
  if (success) {
    return 'Success!';
  }
  return (
    <>
      <form onSubmit={onSubmit}>
        <div>
          <label htmlFor="minStart">Min Start (MM/DD/YYYY)</label>
          <input type="text" id="minStart" name="minStart" />
        </div>
        <div>
          <label htmlFor="maxStart">Max Start (MM/DD/YYYY)</label>
          <input type="text" id="maxStart" name="maxStart" />
        </div>
        <div>
          <input type="submit" />
        </div>
      </form>
      <p>{error}</p>
      <p>{success}</p>
    </>
  );
};
