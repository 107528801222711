import { Collection } from '../constants';
import { db } from '../firebase';

const stripeCustomersCollection = db.collection(Collection.STRIPE_CUSTOMERS);

/* Stripe Customers Table */

export const getStripeCustomerData = async (id) => {
  const stripeCustomer = await getStripeCustomer(id);
  return stripeCustomer.data();
};

export const getStripeCustomerRef = (userId) => {
  if (userId) {
    return stripeCustomersCollection.doc(userId);
  }
  return stripeCustomersCollection;
};

export const getAllStripeCustomers = () => {
  return stripeCustomersCollection.get();
};

export const getStripeCustomer = (id) => {
  return stripeCustomersCollection.doc(id).get();
};

export const updateStripeCustomerDataById = async (data, id) => {
  return stripeCustomersCollection.doc(id).set(data, { merge: true });
};
