import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment-timezone';
import { useContext, useEffect } from 'react';
import firebase from 'firebase';
import { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Datetime from 'react-datetime';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Loader from '../common/Loader';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { auth } from '../../firebase';
import { Shift, TimesheetAction, WithId } from '../../types';
import { getShiftsByDate } from '../../api/shifts';
import { getAllFacilities } from '../../api/facility';
import { TestContext } from '../../context/Context';
import { Select } from '@chakra-ui/react';

const anchorFormatter = (description: string, { id }: WithId<Shift>) => (
  <a href={`/shifts/${id}`}>
    <b>{description}</b>
  </a>
);

const dateFormatter = (date: firebase.firestore.Timestamp) => <>{moment(date?.toDate()).format(`M/D/YYYY h:mm A`)}</>;

const timesheetActionFormatter = (dataField: any, { timesheetActionMadeByFullName, timesheetAction }: any) => {
  if (timesheetAction === TimesheetAction.APPROVED) {
    return (
      <div>
        <FontAwesomeIcon icon="check" color="green" />
        <span style={{ marginLeft: '5px' }}>{timesheetActionMadeByFullName}</span>
      </div>
    );
  }
  if (timesheetAction === TimesheetAction.REJECTED) {
    return (
      <div>
        <FontAwesomeIcon icon="times" color="red" />
        <span style={{ marginLeft: '5px' }}>{timesheetActionMadeByFullName}</span>
      </div>
    );
  }
  return (
    <div>
      <span>{timesheetActionMadeByFullName}</span>
    </div>
  );
};

const Timesheets = () => {
  const [date, setDate] = useState<Moment | undefined>();
  const [timesheets, setTimesheets] = useState<WithId<Shift>[]>([]);
  const [loading, setLoading] = useState(false);
  const [facilityOptions, setFacilityOptions] = useState<{ label: string; value: string }[]>([]);
  const [facilityId, setFacilityId] = useState<string | undefined>();
  const userId = auth.currentUser?.uid;

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const queryDate = query.get('date');
  const queryFacilityId = query.get('facilityId');
  console.log({ queryDate, queryFacilityId });
  const { isTest } = useContext(TestContext);

  const getFacilities = async () => {
    setLoading(true);
    const facilities = await getAllFacilities(isTest);
    const facilityOptions = facilities.docs
      .map((facility) => ({
        label: facility.data().facilityName,
        value: facility.id,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1));
    setFacilityOptions(facilityOptions);
    setLoading(false);
  };

  useEffect(() => {
    getFacilities();
  }, [isTest]);

  const columns = [
    {
      dataField: '',
      headerClasses: 'border-0',
      text: 'Approved?',
      classes: 'border-0 py-2',
      formatter: timesheetActionFormatter,
    },
    {
      dataField: 'description',
      text: 'Description',
      headerClasses: 'border-0',
      classes: 'border-0 py-2',
      formatter: anchorFormatter,
      sort: true,
    },
    {
      dataField: 'workerFullName',
      text: 'Worker',
      headerClasses: 'border-0',
      classes: 'border-0 py-2',
      sort: true,
    },
    {
      dataField: 'clockIn',
      text: 'Clock In',
      headerClasses: 'border-0',
      classes: 'border-0 py-2',
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: 'clockOut',
      text: 'Clock Out',
      headerClasses: 'border-0',
      classes: 'border-0 py-2',
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: 'nurseType',
      text: 'Position',
      headerClasses: 'border-0',
      classes: 'border-0 py-2',
      sort: true,
    },
  ];

  useEffect(() => {
    let needsQueryChange = false;
    let newQueryDate = queryDate;
    let newQueryFacilityId = queryFacilityId;
    if (queryDate) {
      const momentQueryDate = moment(queryDate).tz('America/Chicago', true);
      if (momentQueryDate.day() !== 0) {
        const newMomentQueryDate = moment(queryDate).tz('America/Chicago', true);
        newMomentQueryDate.day(0);
        needsQueryChange = true;
        newQueryDate = newMomentQueryDate.format('YYYY-MM-DD');
      } else {
        setDate(momentQueryDate);
      }
    } else {
      const newMomentQueryDate = moment().tz('America/Chicago', true);
      newMomentQueryDate.day(0);
      needsQueryChange = true;
      newQueryDate = newMomentQueryDate.format('YYYY-MM-DD');
    }
    if (queryFacilityId) {
      setFacilityId(queryFacilityId);
    } else {
      needsQueryChange = true;
      newQueryFacilityId = 'none';
    }
    if (needsQueryChange) {
      navigate(`/timesheets?date=${newQueryDate}&facilityId=${newQueryFacilityId}`, { replace: true });
    }
  }, [queryDate, queryFacilityId]);

  const getTimesheets = async () => {
    if (facilityId === 'none' || !date || !facilityId) {
      return;
    }
    console.log(date.toDate(), facilityId);
    setLoading(true);
    const shifts = await getShiftsByDate(date.toDate(), facilityId);
    setTimesheets(shifts);
    setLoading(false);
  };

  useEffect(() => {
    getTimesheets();
  }, [date, facilityId]);

  if (!userId || !date || !facilityId || facilityOptions.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <Card>
        <FalconCardHeader title="Timesheet">
          <div className="d-flex flex-row" style={{ gap: 8 }}>
            <Select
              value={facilityId}
              onChange={(opt) =>
                navigate(`/timesheets?date=${date.format('YYYY-MM-DD')}&facilityId=${opt.target.value}`, {
                  replace: true,
                })
              }
              placeholder="Select facility"
            >
              <option key="none" value="none">
                None
              </option>
              {facilityOptions.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Select>
            <Datetime
              timeFormat={false}
              value={date}
              onChange={(dateTime: any) => {
                if (dateTime._isValid) {
                  navigate(`/timesheets?date=${moment(dateTime).format('YYYY-MM-DD')}&facilityId=${facilityId}`, {
                    replace: true,
                  });
                }
              }}
              dateFormat="MM-DD-YYYY"
              isValidDate={(current) => current.day() === 0}
            />
          </div>
        </FalconCardHeader>
        <CardBody>
          <div className="table-responsive">
            {loading ? (
              <Loader />
            ) : (
              <BootstrapTable
                data={timesheets || []}
                keyField="id"
                bordered={false}
                classes="table-striped table-sm fs--1 border-bottom border-200"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                columns={columns}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Timesheets;
