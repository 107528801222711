import { Collection } from '../constants';
import { db, functions } from '../firebase';
import { isBrookdale } from '../helpers/utils';

const userCollection = db.collection(Collection.USER);
const facilitiesCollection = db.collection(Collection.FACILITIES);

/* Facilities Table */

export const getFacilityData = async (id) => {
  const facility = await getFacility(id);
  return facility.data();
};

export const getFacilityRef = (userId) => {
  if (userId) {
    return facilitiesCollection.doc(userId);
  }
  return facilitiesCollection;
};

export const getAllFacilities = (isTest) => {
  return facilitiesCollection.where('isTest', '==', isTest).get();
};

export const getAllFacilityData = async (isTest) => {
  const facilities = await getAllFacilities(isTest);
  const facilityData = [];
  facilities.docs.forEach((facility) => {
    facilityData.push({ id: facility.id, ...facility.data() });
  });
  return facilityData;
};

export const getFacility = (id) => {
  return facilitiesCollection.doc(id).get();
};

export const updateFacilityDataById = async (data, id) => {
  return facilitiesCollection.doc(id).set(data, { merge: true });
};

/* Other */

export const updateFacilityAndUserDataById = async ({ facilityData, facilityId, userData, userId }) => {
  await db.runTransaction(async (transaction) => {
    const facilitiesRef = facilitiesCollection.doc(facilityId);
    const userRef = userCollection.doc(userId);
    await transaction.update(facilitiesRef, facilityData);
    await transaction.update(userRef, userData);
  });
};

export const inviteFacilityAdmin = async ({ inviteData, facilityId }) => {
  const inviteAdmin = functions.httpsCallable('facilities-inviteAdmin');
  return inviteAdmin({
    inviteData,
    facilityId,
  });
};

export const generateInvoice = async (data) => {
  const generateInvoice = functions.httpsCallable('finances-generateInvoiceCall');
  return generateInvoice(data);
};

export const getAllBrookdaleFacilities = async (isTest) => {
  const allFacilities = await getAllFacilities(isTest);
  const brookdaleFacilities = [];
  allFacilities.docs.forEach((doc) => {
    if (isBrookdale(doc.data().facilityName)) {
      brookdaleFacilities.push({ ...doc.data(), id: doc.id });
    }
  });
  return brookdaleFacilities;
};

export const getFacilitiesFromShifts = async (shifts) => {
  const facilityIds = new Set();
  shifts.forEach((shift) => {
    if (shift.facilityIdentifier) {
      facilityIds.add(shift.facilityIdentifier);
    }
  });
  const facilityPromises = [...facilityIds].map((id) => {
    return new Promise((resolve) => {
      const getFacilityDataWrapper = async () => {
        const facility = await getFacility(id);
        return {
          ...facility.data(),
          id: facility.id,
        };
      };
      getFacilityDataWrapper().then((facilityData) => resolve(facilityData));
    });
  });
  const facilityArr = await Promise.all(facilityPromises);
  return facilityArr.reduce((acc, facility) => ({ ...acc, [facility.id]: facility }), {});
};
