import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import React, { useEffect, useState, useCallback } from 'react';

import Loader from '../common/Loader';
import FalconCardHeader from '../common/FalconCardHeader';
import { toast } from 'react-toastify';
import { getAuthUser } from '../../api/auth';
import { getCompany, updateCompanyDataById } from '../../api/company';
import { getUserData } from '../../api/user';

const CustomerDetailRow = ({ title, isLastItem, text }) => (
  <Row>
    <Col xs={5} sm={4}>
      {' '}
      <p className="font-weight-semi-bold fs--1"> {title} </p>{' '}
    </Col>
    <Col>
      <p className="fs--1">{text}</p>{' '}
    </Col>
  </Row>
);

const CompanyDetail = () => {
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCompany = useCallback(async () => {
    const result = await getCompany(id);
    let authUser;
    let owner;
    try {
      authUser = await getAuthUser(result.data().ownerId);
      owner = await getUserData(result.data().ownerId);
      owner = { ...owner.data(), email: authUser?.data?.email };
    } catch (e) {
      console.log(e);
    }
    return { ...result.data(), id: result.id, owner };
  }, [id]);

  const fetchCompanyAndUpdateState = useCallback(() => {
    setLoading(true);

    fetchCompany()
      .then((result) => {
        setCompany(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [fetchCompany]);

  useEffect(() => {
    fetchCompanyAndUpdateState();
  }, [fetchCompanyAndUpdateState]);

  const isPending = company?.status === 'pending';

  const approveCompany = async () => {
    try {
      await updateCompanyDataById({ status: 'active' }, id);
      await fetchCompanyAndUpdateState();
      toast.success('Successfully approved company');
    } catch (e) {
      toast.error('Failed to approve company');
    }
  };

  const fullName =
    company?.owner?.firstName && company?.owner?.lastName
      ? `${company?.owner?.firstName} ${company?.owner?.lastName}`
      : '';

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card className="mb-3">
            <FalconCardHeader title={company.companyName} />
            <CardBody className="bg-light border-top">
              <Row>
                <Col lg className="col-xxl-5">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '16px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h6
                      style={{ display: 'block', marginBottom: '0px' }}
                      className="font-weight-semi-bold ls text-uppercase"
                    >
                      Account Information
                    </h6>
                  </div>
                  <CustomerDetailRow title="ID" text={company.id} />
                  <CustomerDetailRow title="Company Name" text={company.companyName} />
                  {fullName && <CustomerDetailRow title="Account Owner Name" text={fullName} />}
                  <CustomerDetailRow title="Account Owner Email" text={company?.owner?.email} />
                  <CustomerDetailRow title="Phone Number" text={company.companyPhoneNumber} />
                </Col>
                <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '16px',
                      justifyContent: 'space-between',
                      height: '24px',
                    }}
                  >
                    <h6
                      style={{ display: 'block', marginBottom: '0px' }}
                      className="font-weight-semi-bold ls text-uppercase"
                    >
                      Location
                    </h6>
                  </div>
                  <CustomerDetailRow title="Address" text={company.companyAddress} />
                  <CustomerDetailRow title="City" text={company.companyCity} />
                  <CustomerDetailRow title="State" text={company.companyState} />
                </Col>
              </Row>
              {isPending && (
                <Button style={{ width: '100%' }} color="primary" onClick={approveCompany}>
                  Approve Company
                </Button>
              )}
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};

export default CompanyDetail;
