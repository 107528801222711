import firebase from 'firebase';
import { Collection } from '../constants';

const reviewsCollection = firebase.firestore().collection(Collection.REVIEWS);

export const getReviewByShiftId = async (shiftId) => {
  const snap = await reviewsCollection.doc(shiftId).get();
  if (!snap.data()) {
    return null;
  }
  return { ...snap.data(), id: snap.id };
};

export const getReviewsByNurseId = async (nurseId) => {
  return reviewsCollection.where('nurseId', '==', nurseId).where('status', '==', 'submitted').get();
};

export const updateReview = async (review, shiftId) => {
  await reviewsCollection.doc(shiftId).update(review);
};

export const createReview = async (review, shiftId) => {
  await reviewsCollection.doc(shiftId).set({ isTest: false, ...review });
};
