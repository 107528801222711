import { Collection } from '../constants';
import { db } from '../firebase';

const shiftDiscontentsCollection = db.collection(Collection.SHIFTDISCONTENTS);

export const getShiftDiscontentByUId = (uId) => {
  return shiftDiscontentsCollection.where('nurseId', '==', uId).get();
};
export const getShiftDiscontentByIdentifier = (identifier) => {
  return shiftDiscontentsCollection.get();
};
