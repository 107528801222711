import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import { reducer } from './services/state/Reducer';
import { StateProvider } from './services/state/State';
import { initialState } from './services/state/InitialState';
import { ChakraProvider } from '@chakra-ui/react';

ReactDOM.render(
  <Provider store={store}>
    <StateProvider initialState={initialState} reducer={reducer}>
      <ChakraProvider>
        <Main>
          <App />
        </Main>
      </ChakraProvider>
    </StateProvider>
  </Provider>,
  document.getElementById('main')
);
