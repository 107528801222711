import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';

import Loader from '../common/Loader';
import FalconCardHeader from '../common/FalconCardHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { getStats, sortStats } from '../../redux/actions/statsActions';
import { StatsFilter } from '../common/StatsFilter';
import StatsModal from '../common/StatsModal';
import { TestContext } from '../../context/Context';

const Stats = () => {
  const [modalState, setModalState] = useState({ isOpen: false });
  const { isOpen, modalType, nurseName, selectedIdx } = modalState;
  const state = useSelector((state) => state.stats);
  const { stats, loading, filters, field, order } = state;
  const { isTest } = useContext(TestContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStats(undefined, isTest));
  }, [dispatch, isTest]);

  const onSort = (field, order) => {
    dispatch(sortStats(field, order));
  };

  const modalButtonFormatter = function modalButtonFormatterWrapper(modalType) {
    return function modalButtonFormatterWrapper2(value, row, selectedIdx) {
      return (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setModalState({ isOpen: true, modalType, nurseName: `${row.firstName} ${row.lastName}`, selectedIdx });
          }}
        >
          <b>{value}</b>
        </a>
      );
    };
  };

  const setIsModalOpen = (isOpenNew) => {
    setModalState({ ...modalState, isOpen: isOpenNew });
  };

  const columns = [
    {
      dataField: 'firstName',
      text: 'First Name',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      onSort,
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      onSort,
    },
    {
      dataField: 'pickedShifts',
      headerClasses: 'border-0',
      text: 'Picked Shifts',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      onSort,
      formatter: modalButtonFormatter('pickedShifts'),
    },
    {
      dataField: 'droppedShifts',
      headerClasses: 'border-0',
      text: 'Dropped Shifts',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      onSort,
      formatter: modalButtonFormatter('droppedShifts'),
    },
  ];
  const timeframeOptions = [
    {
      value: 1,
      label: 'Last 24 hours',
    },
    {
      value: 7,
      label: 'Last 7 days',
    },
    {
      value: 30,
      label: 'Last 30 days',
    },
    {
      value: null,
      label: 'All',
    },
  ];
  const timeToStartOptions = [
    {
      value: 24,
      label: '24 hours',
    },
    {
      value: 36,
      label: '36 hours',
    },
    {
      value: 72,
      label: '3 days',
    },
    {
      value: null,
      label: 'All',
    },
  ];
  return (
    <Card>
      <FalconCardHeader title="Stats">
        <div className="d-flex">
          <StatsFilter
            onFilterSelected={(option) =>
              dispatch(getStats({ filterName: 'timeframe', filterValue: option.value }, isTest))
            }
            filterName="Timeframe"
            options={timeframeOptions}
            defaultValue={timeframeOptions.find((el) => el.value === filters.timeToStart)}
            style={{ marginRight: '25px' }}
          />
          <StatsFilter
            onFilterSelected={(option) =>
              dispatch(getStats({ filterName: 'timeToStart', filterValue: option.value }, isTest))
            }
            filterName="Event time to shift start"
            options={timeToStartOptions}
            defaultValue={timeToStartOptions.find((el) => el.value === filters.timeToStart)}
          />
        </div>
      </FalconCardHeader>
      {loading ? (
        <Loader />
      ) : (
        <CardBody className="fs--1 p-0">
          <BootstrapTable
            bootstrap4
            keyField="id"
            sort={{ dataField: field, order }}
            data={stats.slice(0, 10)}
            columns={columns}
            bordered={false}
            classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
            rowClasses="btn-reveal-trigger border-top border-200"
            headerClasses="bg-200 text-900 border-y border-200"
          />
        </CardBody>
      )}
      <StatsModal
        isOpenStatsModal={isOpen}
        statsModalType={modalType}
        nurseName={nurseName}
        setIsOpenStatsModal={setIsModalOpen}
        events={
          !stats[selectedIdx]
            ? []
            : modalType === 'droppedShifts'
            ? stats[selectedIdx]['droppedEvents']
            : stats[selectedIdx]['pickedEvents']
        }
      />
    </Card>
  );
};

export default Stats;
