import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NurseType } from '../../types';
import CustomerDetailRow from './CustomerDetailRow';

interface FacilityAllowedPositionsProps {
  allowedPositions: NurseType[];
  onSave: (data: FormValues) => Promise<void>;
  onComplete: () => Promise<void>;
}

type FormValues = {
  allowedPositions: NurseType[];
};

const FacilityAllowedPositions = ({ allowedPositions, onSave, onComplete }: FacilityAllowedPositionsProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const onClose = () => setModalIsOpen(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({ defaultValues: { allowedPositions } });
  const toast = useToast();
  const onSubmit = async (data: FormValues) => {
    try {
      await onSave(data);
      setModalIsOpen(false);
      await onComplete();
    } catch (e) {
      toast({
        title: 'Failed to update allowed positions',
        status: 'error',
      });
    }
  };
  return (
    <>
      <CustomerDetailRow
        title="Allowed Positions"
        text={
          <>
            <span>{allowedPositions.join(', ')}</span>
            <button onClick={() => setModalIsOpen(true)} className="btn fs--2 btn-link">
              Edit
            </button>
          </>
        }
      />
      <Modal isOpen={modalIsOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Allowed Positions</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl isInvalid={Boolean(errors.allowedPositions)}>
                <FormLabel htmlFor="positions">Allowed positions</FormLabel>
                <Controller
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <CheckboxGroup colorScheme="green">
                        <Stack>
                          {Object.values(NurseType)
                            .filter((value) => typeof value === 'string')
                            .map((nurseType) => (
                              <Checkbox
                                onChange={(e) => {
                                  const value = field.value || [];
                                  if (e.target.checked) {
                                    field.onChange([...value, e.target.id]);
                                  } else {
                                    const index = value.indexOf(e.target.id as NurseType);
                                    field.onChange(value.filter((_: any, i: number) => i !== index));
                                  }
                                }}
                                isChecked={(field.value || []).includes(nurseType)}
                                key={nurseType}
                                id={nurseType}
                              >
                                {nurseType}
                              </Checkbox>
                            ))}
                        </Stack>
                      </CheckboxGroup>
                    );
                  }}
                  name="allowedPositions"
                  control={control}
                />
                <FormErrorMessage>
                  {errors.allowedPositions && 'At least one position must be allowed'}
                </FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} variant="ghost">
                Close
              </Button>
              <Button colorScheme="blue" mr={3} type="submit">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FacilityAllowedPositions;
