import { FormLabel, HStack, Switch } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { TestContext } from '../../context/Context';

const TestSwitch = () => {
  const { isTest, setIsTest } = useContext(TestContext);

  return (
    <HStack mr="5" align="center" display="flex" justify="center">
      <FormLabel htmlFor="isTest" mb="0">
        Test mode?
      </FormLabel>
      <Switch id="isTest" size="md" isChecked={isTest} onChange={() => setIsTest(!isTest)} />
    </HStack>
  );
};

export default TestSwitch;
