import React from 'react';
import Shifts from '../page/Shifts';
import Invoices from '../page/Invoices';
import Payouts from '../page/Payouts';
import Nurses from '../page/Nurses';
import Applicants from '../page/Applicants';
import Facilities from '../page/Facilities';
import Stats from '../page/Stats';
import ShiftsNoTimesheet from '../page/ShiftsNoTimesheet';

const Dashboard = () => {
  console.log('Dashboard');
  return (
    <>
      <div className="mt-3">
        <ShiftsNoTimesheet />
      </div>
      <div className="mt-3">
        <Nurses showLoadMore={false} />
      </div>
      <div className="mt-3">
        <Applicants showLoadMore={false} />
      </div>
      <div className="mt-3">
        <Stats />
      </div>
      <div className="mt-3">
        <Facilities showLoadMore={false} />
      </div>
      <div className="mt-3">
        <Shifts showLoadMore={false} />
      </div>
      <div className="mt-3">
        <Invoices showLoadMore={false} />
      </div>
      <div className="mt-3">
        <Payouts showLoadMore={false} />
      </div>
    </>
  );
};

export default Dashboard;
