import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, BoxProps, Center, CenterProps, Heading, HeadingProps, Stack } from '@chakra-ui/layout';

type CardProps = {
  children: React.ReactNode;
  titleText?: string;
  titleProps?: HeadingProps;
  boxProps?: BoxProps;
  centerProps?: CenterProps;
  titleButtons?: React.ReactNode[];
};

const Card = ({
  children,
  titleText,
  titleProps = {},
  boxProps = {},
  centerProps = {},
  titleButtons = [],
}: CardProps) => {
  const defaultBoxProps = {
    w: 'full',
    bg: useColorModeValue('white', 'gray.900'),
    boxShadow: '2xl',
    rounded: 'lg',
    px: 6,
    py: 6,
  };
  const defaultTitleProps: HeadingProps = { as: 'h3', size: 'lg' };
  const defaultCenterProps: CenterProps = { px: 6, py: 6 };
  const usedTitleProps = { ...defaultTitleProps, ...titleProps };
  const usedBoxProps = { ...defaultBoxProps, ...boxProps };
  const usedCenterProps = { ...defaultCenterProps, ...centerProps };
  return (
    <Center {...usedCenterProps}>
      <Box {...usedBoxProps}>
        <Stack direction="row" justifyContent="space-between">
          {titleText && <Heading {...usedTitleProps}>{titleText}</Heading>}
          <Box>{titleButtons}</Box>
        </Stack>
        {children}
      </Box>
    </Center>
  );
};

export default Card;
