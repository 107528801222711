import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Input, Label, Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { getFacilityData } from '../../api/facility';
import { getTokensByLocation } from '../../api/shifts';
import { functions } from '../../firebase';
import ShiftNotificationsModal from '../common/ShiftNotificationsModal';

const radiusesInMiles = [10, 25, 50];
const initialTokenCounts = radiusesInMiles.reduce((acc, val) => ({ ...acc, [val]: 0 }), {});
const initialTokens = radiusesInMiles.reduce((acc, val) => ({ ...acc, [val]: [] }), {});

const ShiftNotifications = ({ shift }) => {
  const [tokenCounts, setTokenCounts] = useState(initialTokenCounts);
  const [tokens, setTokens] = useState(initialTokens);
  const [isTest, setIsTest] = useState(false);
  const [clickState, setClickState] = useState({
    isOpenModal: false,
    mi: radiusesInMiles[0],
  });
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  useEffect(() => {
    const asyncGetShift = async () => {
      const facilityData = await getFacilityData(shift.facilityIdentifier);
      const tokens = await getTokensByLocation(facilityData.coordinates, radiusesInMiles, shift, facilityData, isTest);
      setTokenCounts(Object.keys(tokens).reduce((acc, mi) => ({ ...acc, [mi]: tokens[mi].length }), {}));
      setTokens(tokens);
    };
    asyncGetShift();
  }, [isTest, shift, shift.facilityIdentifier]);
  const onNotificationClick = (mi) => {
    setClickState({ isOpenModal: true, mi });
  };
  const setIsOpenModal = (isOpen) => {
    setClickState({ ...clickState, isOpenModal: isOpen });
  };
  const onSendIt = async () => {
    try {
      const shiftNotification = functions.httpsCallable('user-shiftNotification');
      await shiftNotification({
        tokens: tokens[clickState.mi],
        notification: {
          title,
          body,
        },
        data: {
          shiftId: shift.id,
          route: 'SHIFT',
        },
        android: { notification: { channelId: 'default', priority: 'max' } },
        fcmOptions: {
          analyticsLabel: 'shift_notification',
        },
      });
      setIsOpenModal(false);
      toast.success('Notifications sent successfully');
    } catch (e) {
      console.error(e);
      toast.error('Notifications failed to be sent');
    }
  };
  const formIsValid = title && body;
  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <Label>Notification Title</Label>
        <Input placeholder="New shift available!" value={title} onChange={({ target }) => setTitle(target.value)} />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Label>Notification Body</Label>
        <Input value={body} onChange={({ target }) => setBody(target.value)} type="textarea" />
      </div>
      <div style={{ marginLeft: '20px' }}>
        <Label check>
          <Input type="checkbox" value={isTest} onChange={(event) => setIsTest(event.target.checked)} />
          Only send to test users?
        </Label>
      </div>
      <Row style={{ marginTop: '20px' }}>
        <Col lg className="col-xxl-5">
          <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Radius</h6>
        </Col>
        <Col lg className="col-xxl-5">
          <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">User Count</h6>
        </Col>
        <Col lg className="col-xxl-5">
          <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Action</h6>
        </Col>
      </Row>
      {radiusesInMiles.map((mi) => (
        <Row style={{ marginBottom: '15px' }} key={mi}>
          <Col>{`${mi} miles`}</Col>
          <Col>{`${tokenCounts[mi]}`}</Col>
          <Col>
            <Button
              disabled={!formIsValid || tokenCounts[mi] === 0}
              color="secondary"
              className="px-4"
              onClick={() => onNotificationClick(mi)}
            >
              Send Notifications
            </Button>
          </Col>
        </Row>
      ))}
      <ShiftNotificationsModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={clickState.isOpenModal}
        tokens={tokens[clickState.mi]}
        title={title}
        body={body}
        radius={clickState.mi}
        onSendIt={onSendIt}
        isTest={isTest}
      />
    </>
  );
};

export default ShiftNotifications;
