import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import React, { useEffect, useState, useCallback } from 'react';

import Loader from '../common/Loader';
import FalconCardHeader from '../common/FalconCardHeader';
import TimesheetExportButton from '../common/TimesheetExportButton';
import UserShiftsTable from './UserShiftsTable';
import FacilityShiftTemplateCard from '../common/FacilityShiftTemplateCard';
import BlockedEmployeesTable from '../common/BlockedEmployeesTable';
import { functions } from '../../firebase';
import { toast } from 'react-toastify';
import FacilityNotifications from './FacilityNotifications';
import { getAuthUser } from '../../api/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountEditModal from '../common/AccountEditModal';
import { getFacility, updateFacilityDataById } from '../../api/facility';
import { getUserData } from '../../api/user';
import InviteButton from '../common/InviteButton';
import GenerateInvoiceButton from '../common/GenerateInvoiceButton';
import { getStripeCustomerData } from '../../api/stripe-customer';
import { Badge } from '@chakra-ui/react';
import { getOwnerIdFromFacilityData } from '../../api/company';
import CustomerDetailRow from '../common/CustomerDetailRow';
import FacilityAllowedPositions from '../common/FacilityAllowedPositions';
import FacilityAdminsTable from '../common/FacilityAdminsTable';
import EditBasePayoutRatesModal from '../common/EditBasePayoutRatesModal';

const FacilityDetail = () => {
  const { id } = useParams();
  const [facility, setFacility] = useState(null);
  const [loadingResend, setLoadingResend] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpenAccountEditModal, setIsOpenAccountEditModal] = useState(false);
  const [isOpenEditPayoutRatesModal, setIsOpenEditPayoutRatesModal] = useState(false);

  const sendResetPassword = async () => {
    setLoadingResend(true);
    try {
      const resendFn = functions.httpsCallable('resendPasswordRequest');
      await resendFn({
        userId: facility.ownerId,
      });
      toast.success('Successfully sent reset password email');
    } catch (e) {
      toast.error('Failed to send reset password email');
      console.error(e);
    } finally {
      setLoadingResend(false);
    }
  };

  const fetchFacility = useCallback(async () => {
    const result = await getFacility(id);
    let authUser;
    let owner;
    let stripeCustomer;
    let ownerId;
    try {
      ownerId = await getOwnerIdFromFacilityData(result.data());
      authUser = await getAuthUser(ownerId);
      stripeCustomer = await getStripeCustomerData(id);
      owner = await getUserData(ownerId);
      owner = { ...owner.data(), email: authUser?.data?.email };
    } catch (e) {
      console.log(e);
    }
    return { ...result.data(), id: result.id, owner, stripeCustomer, ownerId };
  }, [id]);

  const fetchFacilityAndUpdateState = useCallback(() => {
    setLoading(true);

    fetchFacility()
      .then((result) => {
        setFacility(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [fetchFacility]);

  useEffect(() => {
    fetchFacilityAndUpdateState();
  }, [fetchFacilityAndUpdateState]);

  const isPending = facility?.status === 'pending';

  const approveFacility = async () => {
    try {
      await updateFacilityDataById({ status: 'active' }, id);
      await fetchFacilityAndUpdateState();
      toast.success('Successfully approved facility');
    } catch (e) {
      toast.error('Failed to approve facility');
    }
  };

  const fullName =
    facility?.owner?.firstName && facility?.owner?.lastName
      ? `${facility?.owner?.firstName} ${facility?.owner?.lastName}`
      : '';

  const initialAccountInformation = {
    email: facility?.owner?.email || '',
    facilityName: facility?.facilityName || '',
    firstName: facility?.owner?.firstName || '',
    lastName: facility?.owner?.lastName || '',
    phoneNumber: facility?.facilityPhoneNumber || '',
    notificationEmails: (facility?.notificationEmails || []).join(', ') || '',
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card className="mb-3">
            <FalconCardHeader title={facility.facilityName}>
              <TimesheetExportButton facilityId={facility.id} />
              <InviteButton facilityId={facility.id} />
              <GenerateInvoiceButton facilityId={facility.id} />
            </FalconCardHeader>
            <CardBody className="bg-light border-top">
              <Row>
                <Col lg className="col-xxl-5">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '16px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h6
                      style={{ display: 'block', marginBottom: '0px' }}
                      className="font-weight-semi-bold ls text-uppercase"
                    >
                      Account Information
                    </h6>
                    <button
                      style={{
                        background: 'none',
                        color: 'inherit',
                        border: 'none',
                        padding: '0',
                        font: 'inherit',
                        cursor: 'pointer',
                        outline: 'inherit',
                        marginLeft: '10px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        display: 'block',
                      }}
                      color="primary"
                      size="sm"
                      onClick={() => setIsOpenAccountEditModal(true)}
                    >
                      <FontAwesomeIcon icon="edit" className="mr-1" />
                    </button>
                  </div>
                  <CustomerDetailRow title="ID" text={facility.id} />
                  <CustomerDetailRow title="Facility Name" text={facility.facilityName} />
                  {facility?.stripeCustomer?.customer_id && (
                    <CustomerDetailRow
                      title="Stripe"
                      text={
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://dashboard.stripe.com/customers/${facility?.stripeCustomer?.customer_id}`}
                        >
                          Customer page
                        </a>
                      }
                    />
                  )}
                  {fullName && <CustomerDetailRow title="Account Owner Name" text={fullName} />}
                  <CustomerDetailRow title="Account Owner Email" text={facility?.owner?.email} />
                  <CustomerDetailRow title="Phone Number" text={facility.facilityPhoneNumber} />
                  {facility.notificationEmails && (
                    <CustomerDetailRow
                      title="Notification Emails"
                      text={initialAccountInformation.notificationEmails}
                    />
                  )}
                  <CustomerDetailRow
                    title=""
                    text={
                      <button
                        className={`btn fs--1 btn-info`}
                        onClick={() => {
                          sendResetPassword();
                        }}
                        disabled={loadingResend}
                        style={{ marginTop: '10px' }}
                      >
                        {loadingResend ? 'Loading...' : 'Send reset password email'}
                      </button>
                    }
                  />
                  <CustomerDetailRow
                    title=""
                    text={
                      <button
                        className={`btn fs--1 btn-info`}
                        onClick={() => {
                          setIsOpenEditPayoutRatesModal(true);
                        }}
                        disabled={loadingResend}
                        style={{ marginTop: '10px' }}
                      >
                        Edit payout rates
                      </button>
                    }
                  />
                </Col>
                <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '16px',
                      justifyContent: 'space-between',
                      height: '24px',
                    }}
                  >
                    <h6
                      style={{ display: 'block', marginBottom: '0px' }}
                      className="font-weight-semi-bold ls text-uppercase"
                    >
                      Other
                    </h6>
                  </div>
                  <CustomerDetailRow title="Address" text={facility.facilityAddress} />
                  <CustomerDetailRow title="City" text={facility.facilityCity} />
                  <CustomerDetailRow title="State" text={facility.facilityState} />
                  <CustomerDetailRow
                    title="Requires COVID vaccination"
                    text={
                      facility?.requiresCovidVaccination ? (
                        <>
                          <Badge colorScheme="purple">Yes</Badge>
                          <button
                            onClick={async () => {
                              await updateFacilityDataById({ requiresCovidVaccination: false }, id);
                              await fetchFacilityAndUpdateState();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      ) : (
                        <>
                          <Badge colorScheme="green">No</Badge>
                          <button
                            onClick={async () => {
                              await updateFacilityDataById({ requiresCovidVaccination: true }, id);
                              await fetchFacilityAndUpdateState();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      )
                    }
                  />
                  <CustomerDetailRow
                    title="Has 30min lunch break"
                    text={
                      facility?.hasLunchBreak ? (
                        <>
                          <Badge colorScheme="purple">Yes</Badge>
                          <button
                            onClick={async () => {
                              await updateFacilityDataById({ hasLunchBreak: false }, id);
                              await fetchFacilityAndUpdateState();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      ) : (
                        <>
                          <Badge colorScheme="green">No</Badge>
                          <button
                            onClick={async () => {
                              await updateFacilityDataById({ hasLunchBreak: true }, id);
                              await fetchFacilityAndUpdateState();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      )
                    }
                  />
                  <FacilityAllowedPositions
                    onSave={async (data) => {
                      await updateFacilityDataById(data, id);
                    }}
                    onComplete={fetchFacilityAndUpdateState}
                    allowedPositions={facility.allowedPositions}
                  />
                  {
                    <CustomerDetailRow
                      title="Log in as facility with admin creds"
                      text={
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://app.ptnurse.com/authentication/basic/otherLogin"
                        >
                          Open facility app
                        </a>
                      }
                    />
                  }
                </Col>
              </Row>
              {isPending && (
                <Button style={{ width: '100%' }} color="primary" onClick={approveFacility}>
                  Approve Facility
                </Button>
              )}
            </CardBody>
          </Card>
          <Card className="mt-3">
            <FacilityAdminsTable facilityAdminIds={facility?.adminIds} facilityId={facility?.id} />
          </Card>
          <Card className="mt-3">
            <UserShiftsTable uid={facility.id} type="facility" />
          </Card>
          <FacilityShiftTemplateCard facility={facility} uid={facility.id} onUpdate={fetchFacilityAndUpdateState} />
          {(facility?.blocklist || []).length > 0 && (
            <BlockedEmployeesTable
              blockedEmployees={facility?.blocklist || []}
              blockedEvents={facility?.blocklistEvents || []}
              onUpdate={fetchFacilityAndUpdateState}
              uid={facility.id}
            />
          )}
          {facility?.coordinates && (
            <Card className="mt-3">
              <FalconCardHeader title="Notifications" />
              <CardBody className="border-top" style={{ zoom: 0.8 }}>
                <FacilityNotifications facility={facility} />
              </CardBody>
            </Card>
          )}
        </>
      )}
      <AccountEditModal
        isOpen={isOpenAccountEditModal}
        setIsOpen={setIsOpenAccountEditModal}
        initialAccountInformation={initialAccountInformation}
        onSave={() => {
          fetchFacilityAndUpdateState();
          setIsOpenAccountEditModal(false);
        }}
        ownerId={facility?.ownerId}
        facilityId={facility?.id}
      />
      <EditBasePayoutRatesModal
        isOpen={isOpenEditPayoutRatesModal}
        setIsOpen={setIsOpenEditPayoutRatesModal}
        basePayoutRateConfigs={facility?.basePayoutRateConfigs || []}
        onSave={() => {
          fetchFacilityAndUpdateState();
          setIsOpenEditPayoutRatesModal(false);
        }}
        facilityId={facility?.id}
      />
    </>
  );
};

export default FacilityDetail;
