import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useEffect, useState, Fragment, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { firebase } from '../../firebase';
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input as ChInput,
  Button as ChButton,
  RadioGroup,
  Radio,
  Tooltip,
  Container,
  Stack,
  Divider,
  Badge,
} from '@chakra-ui/react';

import Loader from '../common/Loader';
import { cancelShift, getShiftData, leaveShift, updateShift } from '../../api/shifts';
import { addOnMapper, facilityShouldShowMedpass, isIterableArray, sleep } from '../../helpers/utils';
import { getShiftDiscontentByIdentifier } from '../../api/shiftdiscontents';
import FalconCardHeader from '../common/FalconCardHeader';
import ClockInClockOutButton from '../common/ClockInClockOutButton';
import AddUserToShiftButton from '../common/AddUserToShiftButton';
import ShiftNotifications from './ShiftNotifications';
import { getReviewByShiftId } from '../../api/reviews';
import { AdditionalRequirements, shiftReviewQuestions, shiftReviewQuestionsOrder } from '../../constants';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getUserData } from '../../api/user';
import Overflow from '../common/Overflow';
import MoneyInput from '../common/MoneyInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShiftMedPass from '../common/ShiftMedPass';
import { getFacilityData } from '../../api/facility';
import EditPayoutBonusModal from './EditPayoutBonusModal';
import FacilityBillRate from './FacilityBillRate';
import { TimeAdjustmentName } from '../../types';

const PayoutBonusModal = ({ id, isOpen, setIsOpen, onSave }) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm();
  const toggle = () => setIsOpen(!isOpen);

  const closeBtn = (
    <button
      className="close font-weight-normal"
      onClick={() => {
        toggle();
      }}
    >
      &times;
    </button>
  );
  const onSubmit = async ({ bonusRateType, bonusValue, bonusLabel, facilityBonusValue }) => {
    const bonus = {
      value: Math.round(parseFloat(bonusValue) * 100),
      label: bonusLabel,
      rateType: bonusRateType,
      facilityBonusValue: Math.round(parseFloat(facilityBonusValue) * 100),
    };
    await updateShift(id, {
      genericBonuses: firebase.firestore.FieldValue.arrayUnion(bonus),
    });
    toast.success('Successfully added bonus');
    toggle();
    await sleep(7000); // wait for payouts to be calculated on backend
    onSave();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader close={closeBtn}>
        <h5 className="mb-2 mb-md-0">Add generic payout bonus</h5>
      </ModalHeader>
      <ModalBody>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} align="stretch" mb={4}>
              <Container>
                <FormControl isInvalid={Boolean(errors.bonusLabel)}>
                  <FormLabel htmlFor="bonusLabel">
                    Choose bonus label&nbsp;
                    <Tooltip
                      placement="top"
                      label="This will be shown on the mobile app and in the facility invoice"
                      aria-label="shown-on-app"
                    >
                      <span>
                        <FontAwesomeIcon icon="info-circle" />
                      </span>
                    </Tooltip>
                  </FormLabel>
                  <Controller
                    rules={{
                      required: 'Bonus label is required',
                      maxLength: { value: 8, message: 'Bonus label must be less than 8 characters' },
                    }}
                    render={({ field }) => <ChInput {...field} />}
                    name="bonusLabel"
                    control={control}
                  />
                  <FormErrorMessage>{errors.bonusLabel && errors.bonusLabel.message}</FormErrorMessage>
                </FormControl>
              </Container>
              <Container>
                <FormControl isInvalid={Boolean(errors.bonusRateType)}>
                  <FormLabel htmlFor="bonusRateType">Choose bonus rate type</FormLabel>
                  <Controller
                    rules={{ required: 'Bonus rate type is required' }}
                    render={({ field }) => (
                      <RadioGroup name="bonusRateType" isInline spacing={4} {...field}>
                        <Radio value="perShift">Per shift</Radio>
                        <Radio value="perHour">Per hour</Radio>
                      </RadioGroup>
                    )}
                    name="bonusRateType"
                    control={control}
                  />
                  <FormErrorMessage>{errors.bonusRateType && errors.bonusRateType.message}</FormErrorMessage>
                </FormControl>
              </Container>
              <Container>
                <FormControl isInvalid={Boolean(errors.bonusValue)}>
                  <FormLabel htmlFor="bonusValue">Set bonus value for contractor</FormLabel>
                  <Controller
                    rules={{
                      required: 'Bonus value is required',
                      pattern: /^[0-9]*(\.\d{1,2})?$/g,
                    }}
                    render={({ field }) => <MoneyInput {...field} />}
                    name="bonusValue"
                    control={control}
                  />
                  <FormErrorMessage>{errors.bonusValue && errors.bonusValue.message}</FormErrorMessage>
                </FormControl>
              </Container>
              <Container>
                <FormControl isInvalid={Boolean(errors.facilityBonusValue)}>
                  <FormLabel htmlFor="facilityBonusValue">Set bonus rate to charge facility</FormLabel>
                  <Controller
                    rules={{
                      required: 'Bonus value is required',
                      pattern: /^[0-9]*(\.\d{1,2})?$/g,
                    }}
                    render={({ field }) => <MoneyInput {...field} />}
                    name="facilityBonusValue"
                    control={control}
                  />
                  <FormErrorMessage>{errors.facilityBonusValue && errors.facilityBonusValue.message}</FormErrorMessage>
                </FormControl>
              </Container>
              <Container>
                <ChButton isLoading={isSubmitting} type="submit">
                  Save
                </ChButton>
              </Container>
            </Stack>
          </form>
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

const CancelModal = ({ id, isOpen, setIsOpen, onSave }) => {
  const [reasonRadio, setReasonRadio] = useState(null);
  const [otherReason, setOtherReason] = useState('');
  const toggle = () => setIsOpen(!isOpen);

  const closeBtn = (
    <button
      className="close font-weight-normal"
      onClick={() => {
        setReasonRadio(null);
        setOtherReason('');
        toggle();
      }}
    >
      &times;
    </button>
  );
  const deleteShift = () => {
    try {
      cancelShift(id, reasonRadio, otherReason);
      toast.success('Successfully cancelled shift');
      setIsOpen(false);
      onSave();
    } catch (error) {
      toast.error('Failed to cancel shift');
    }
  };
  const onChange = (evt) => {
    setReasonRadio(evt.target.id);
  };

  const formValid = reasonRadio && (reasonRadio !== 'other' || otherReason);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader close={closeBtn}>
        <h5 className="mb-2 mb-md-0">Select cancellation reason</h5>
      </ModalHeader>
      <ModalBody>
        <CardBody>
          <Row>
            <Col md style={{ marginTop: '10px', marginBottom: '20px' }}>
              <FormGroup tag="fieldset">
                <div>
                  <CustomInput
                    checked={reasonRadio === 'filledExternally'}
                    onChange={onChange}
                    type="radio"
                    label="Shift filled externally (other agency, etc)"
                    id="filledExternally"
                    name="reason"
                  />
                  <CustomInput
                    checked={reasonRadio === 'filledInternally'}
                    onChange={onChange}
                    type="radio"
                    label="Shift filled internally"
                    id="filledInternally"
                    name="reason"
                  />
                  <CustomInput
                    checked={reasonRadio === 'noLongerNeeded'}
                    onChange={onChange}
                    type="radio"
                    label="Shift no longer needed"
                    id="noLongerNeeded"
                    name="reason"
                  />
                  <CustomInput
                    checked={reasonRadio === 'mistake'}
                    onChange={onChange}
                    type="radio"
                    label="Created by mistake or incorrect shift information"
                    id="mistake"
                    name="reason"
                  />
                  <CustomInput
                    checked={reasonRadio === 'other'}
                    onChange={onChange}
                    type="radio"
                    label="Other"
                    id="other"
                    name="reason"
                  />
                  <Input style={{ display: 'none' }} />
                  <FormFeedback>This question is required</FormFeedback>
                </div>
              </FormGroup>
              {reasonRadio === 'other' && (
                <FormGroup tag="fieldset">
                  <Label for="other">Please provide additional details</Label>
                  <Input onChange={(e) => setOtherReason(e.target.value)} />
                </FormGroup>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <Button disabled={!formValid} onClick={deleteShift} color="falcon-warning" size="sm" className="mr-2">
                Cancel Shift
              </Button>
            </Col>
          </Row>
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

export const CustomerDetailRow = ({ title, isLastItem, text }) => (
  <Row>
    <Col xs={5} sm={5}>
      <p className={classNames('font-weight-semi-bold', 'fs--1', { 'mb-0': isLastItem, 'mb-1': !isLastItem })}>
        {title}
      </p>
    </Col>
    <Col className="fs--1">{text}</Col>
  </Row>
);

const ShiftDetail = () => {
  const { id } = useParams();
  const [shift, setShift] = useState(null);
  const [facility, setFacility] = useState();
  const [loading, setLoading] = useState(true);
  const [discontents, setDiscontents] = useState([]);
  const [nurse, setNurse] = useState();

  const [review, setReview] = useState(null);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [reviewIsLoading, setReviewIsLoading] = useState(false);
  const [isOpenPayoutAddBonus, setIsOpenPayoutAddBonus] = useState(false);
  const [isOpenPayoutEditBonus, setIsOpenPayoutEditBonus] = useState(false);
  const reviewExists = !reviewIsLoading && review && review.responses;
  const reviewMap =
    reviewExists && review.responses.reduce((acc, res) => ({ ...acc, [res.questionId]: res.responseValue }), {});
  useEffect(() => {
    const getReview = async () => {
      const review = await getReviewByShiftId(id);
      setReview(review);
      setReviewIsLoading(false);
    };
    setReviewIsLoading(true);
    getReview();
  }, [id]);

  useEffect(() => {
    if (shift?.facilityIdentifier) {
      getFacilityData(shift.facilityIdentifier).then((facilityData) =>
        setFacility({ ...facilityData, id: shift.facilityIdentifier })
      );
    }
  }, [shift?.facilityIdentifier]);

  const fetchShift = useCallback(async () => {
    let result = await getShiftData(id);
    return result;
  }, [id]);

  const loadShift = useCallback(() => {
    setLoading(true);

    fetchShift()
      .then((result) => {
        setShift({ ...result.data(), id: result.id });
        if (result.data().nurseId) loadNurse(result.data().nurseId);
        setLoading(false);
        loadShiftDisconents(result.id);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [fetchShift]);

  useEffect(() => {
    loadShift();
  }, [fetchShift, loadShift]);

  const loadNurse = async (nurseId) => {
    const nurse = await getUserData(nurseId);
    setNurse(nurse.data());
  };
  const loadShiftDisconents = async (shiftId) => {
    let result = await getShiftDiscontentByIdentifier(shiftId);
    result = result.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter((doc) => doc.shiftIdentifier === shiftId);
    setDiscontents(result);
  };

  const freeShift = async () => {
    await leaveShift(id);
    window.location.reload();
  };

  const clockInClockOutInitialValues = {
    clockIn: shift?.clockIn ? shift.clockIn.toDate() : null,
    clockOut: shift?.clockOut ? shift.clockOut.toDate() : null,
    start: shift?.start.toDate(),
    end: shift?.end.toDate(),
  };

  let hoursText = '';
  if (shift?.payout?.hours) {
    const hours = Math.floor(shift.payout.hours);
    const hoursLabel = hours === 1 ? 'hour' : 'hours';

    const minutes = Math.round((shift.payout.hours - Math.floor(shift.payout.hours)) * 60);
    const minutesLabel = minutes === 1 ? 'minute' : 'minutes';
    const minutesText = minutes === 0 ? '' : ` and ${minutes} ${minutesLabel}`;

    hoursText = `${hours} ${hoursLabel}` + minutesText;
  }

  const editBonusOptionIsDisabled = !shift?.genericBonuses?.length;

  const payoutOptions = [
    {
      text: 'Add bonus',
      onClick: () => setIsOpenPayoutAddBonus(true),
      key: 0,
    },
    {
      text: 'Edit or delete bonus',
      onClick: () => setIsOpenPayoutEditBonus(true),
      key: 1,
      isDisabled: editBonusOptionIsDisabled,
    },
  ];
  const now = new Date();
  const showNCNSToggle = shift?.nurseId && shift?.end.toDate() < now && !shift?.clockOut && !shift.clockIn;
  const hasLunchBreak =
    (shift?.timeAdjustments || []).filter((ta) => ta.name === TimeAdjustmentName.LUNCH_BREAK).length > 0;
  const lunchBreakIndex = (shift?.timeAdjustments || []).findIndex((ta) => ta.name === TimeAdjustmentName.LUNCH_BREAK);

  return (
    <Fragment>
      {loading ? (
        <Card className="mb-3">
          <CardBody>
            <Loader />
          </CardBody>
        </Card>
      ) : (
        <div style={{ position: 'relative' }}>
          {shift.status === 'cancelled' && (
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
                background: '#e05',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'xxx-large',
                fontWeight: 'bold',
                opacity: 0.15,
              }}
            >
              Cancelled
            </div>
          )}

          <Card className="mb-3">
            <FalconCardHeader title="Shift Information">
              <Button onClick={() => setIsOpenCancelModal(true)} color="danger">
                Cancel shift
              </Button>
            </FalconCardHeader>
            <CardBody className="bg-light border-top">
              <Row>
                <Col lg className="col-xxl-5">
                  <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Shift Information</h6>
                  <CustomerDetailRow title="ID" text={shift.id} />
                  <CustomerDetailRow title="Description" text={shift.description} />
                  <CustomerDetailRow title="Position" text={shift.nurseType} />
                  <CustomerDetailRow title="Number of Nurses" text={shift.numberOfNurse} />
                  <CustomerDetailRow title="Start" text={shift.start?.toDate().toLocaleString()} />
                  <CustomerDetailRow title="End" text={shift.end?.toDate().toLocaleString()} />
                  <CustomerDetailRow title="Additional Notes" text={shift.additionalnotes} />
                </Col>
                <Col lg className="col-xxl-5">
                  <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Other Details</h6>
                  <CustomerDetailRow
                    title="Facility ID"
                    text={
                      <a className="fs--1" href={`/facilities/${shift.facilityIdentifier}`}>
                        {shift.facilityIdentifier}
                      </a>
                    }
                  />
                  <CustomerDetailRow
                    title="Is Free"
                    text={shift.isFree ? <Badge>Free</Badge> : <Badge>Booked</Badge>}
                  />
                  {
                    <CustomerDetailRow
                      title="Nurse"
                      text={
                        <>
                          {nurse && (
                            <a className="fs--1" href={`/users/${shift.nurseId}`}>
                              {nurse?.firstName + ' ' + nurse?.lastName}
                            </a>
                          )}
                          {nurse && !shift.clockIn && (
                            <button onClick={freeShift} className="btn text-danger fs--2 btn-link">
                              Remove User
                            </button>
                          )}
                          {!nurse && <AddUserToShiftButton />}
                        </>
                      }
                    />
                  }
                  <CustomerDetailRow
                    title="Clock In"
                    text={
                      shift.clockIn ? (
                        <>
                          <span style={{ marginRight: '7px' }}>{shift.clockIn?.toDate().toLocaleString()}</span>
                          <ClockInClockOutButton
                            action="clockIn"
                            label="Edit"
                            shiftId={shift.id}
                            initialValue={shift.clockIn.toDate()}
                            onComplete={() => loadShift()}
                            initialValues={clockInClockOutInitialValues}
                          />
                        </>
                      ) : (
                        <ClockInClockOutButton
                          action="clockIn"
                          label="Add"
                          shiftId={shift.id}
                          onComplete={() => loadShift()}
                          initialValues={clockInClockOutInitialValues}
                        />
                      )
                    }
                  />
                  <CustomerDetailRow
                    title="Clock Out"
                    text={
                      shift.clockOut ? (
                        <>
                          <span style={{ marginRight: '7px' }}>{shift.clockOut?.toDate().toLocaleString()}</span>
                          <ClockInClockOutButton
                            action="clockOut"
                            label="Edit"
                            shiftId={shift.id}
                            initialValue={shift.clockOut.toDate()}
                            onComplete={() => loadShift()}
                            initialValues={clockInClockOutInitialValues}
                          />
                        </>
                      ) : shift.clockIn ? (
                        <ClockInClockOutButton
                          action="clockOut"
                          label="Add"
                          shiftId={shift.id}
                          initialValues={clockInClockOutInitialValues}
                          onComplete={() => loadShift()}
                        />
                      ) : (
                        ''
                      )
                    }
                  />
                  <CustomerDetailRow
                    title="Covid Bonus"
                    text={
                      shift.isCovidBonus ? (
                        <>
                          <Badge bg="primary">Yes</Badge>
                          <button
                            onClick={async () => {
                              await updateShift(id, { isCovidBonus: false });
                              await loadShift();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Remove
                          </button>
                        </>
                      ) : (
                        <>
                          <Badge bg="secondary">No</Badge>
                          <button
                            onClick={async () => {
                              await updateShift(id, { isCovidBonus: true });
                              await loadShift();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Add
                          </button>
                        </>
                      )
                    }
                  />
                  <CustomerDetailRow
                    title="Requires COVID vaccination"
                    text={
                      (shift?.additionalRequirements || []).includes(AdditionalRequirements.COVID_VACCINATION) ? (
                        <>
                          <Badge colorScheme="green">Yes</Badge>
                          <button
                            onClick={async () => {
                              await updateShift(id, {
                                additionalRequirements: firebase.firestore.FieldValue.arrayRemove(
                                  AdditionalRequirements.COVID_VACCINATION
                                ),
                              });
                              await loadShift();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      ) : (
                        <>
                          <Badge colorScheme="purple">No</Badge>
                          <button
                            onClick={async () => {
                              await updateShift(id, {
                                additionalRequirements: firebase.firestore.FieldValue.arrayUnion(
                                  AdditionalRequirements.COVID_VACCINATION
                                ),
                              });
                              await loadShift();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      )
                    }
                  />
                  <CustomerDetailRow
                    title="Has 30min lunch break"
                    text={
                      hasLunchBreak ? (
                        <>
                          <Badge colorScheme="green">Yes</Badge>
                          <button
                            onClick={async () => {
                              const timeAdjustments = shift?.timeAdjustments || [];
                              timeAdjustments.splice(lunchBreakIndex, 1);
                              await updateShift(id, {
                                timeAdjustments,
                              });
                              await loadShift();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      ) : (
                        <>
                          <Badge colorScheme="purple">No</Badge>
                          <button
                            onClick={async () => {
                              const timeAdjustments = shift?.timeAdjustments || [];
                              const newTimeAdjustment = {
                                name: TimeAdjustmentName.LUNCH_BREAK,
                                timeInSecondsToAdd: -1 * 60 * 30,
                              };
                              await updateShift(id, {
                                timeAdjustments: [...timeAdjustments, newTimeAdjustment],
                              });
                              await loadShift();
                            }}
                            className="btn fs--2 btn-link"
                          >
                            Toggle
                          </button>
                        </>
                      )
                    }
                  />
                  {facilityShouldShowMedpass(facility) && (
                    <ShiftMedPass shift={shift} loadShift={loadShift} shiftId={id} />
                  )}
                  {showNCNSToggle && (
                    <CustomerDetailRow
                      title="NCNS"
                      text={
                        shift?.isNCNS ? (
                          <>
                            <Badge colorScheme="red">Yes</Badge>
                            <button
                              onClick={async () => {
                                await updateShift(id, {
                                  isNCNS: false,
                                });
                                await loadShift();
                              }}
                              className="btn fs--2 btn-link"
                            >
                              Toggle
                            </button>
                          </>
                        ) : (
                          <>
                            <Badge colorScheme="green">No</Badge>
                            <button
                              onClick={async () => {
                                await updateShift(id, {
                                  isNCNS: true,
                                });
                                await loadShift();
                              }}
                              className="btn fs--2 btn-link"
                            >
                              Toggle
                            </button>
                          </>
                        )
                      }
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <FalconCardHeader title="🤑🤑🤑🤑🤑" />
            <CardBody className="border-top">
              <Row style={{ marginTop: '15px' }}>
                {shift.payout && (
                  <>
                    <Col lg className="col-xxl-6">
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Payout</h6>
                        <Overflow options={payoutOptions} />
                      </div>
                      <CustomerDetailRow title="Base Rate" text={`$${shift.payout.baseRate.toFixed(2)} per hour`} />
                      {shift.payout.addOns.map((addOn) => (
                        <CustomerDetailRow
                          key={addOn.value}
                          title={addOnMapper(addOn.type).title}
                          text={`$${addOn.value.toFixed(2)}${addOn.rateType === 'perShift' ? '' : ' per hour'}`}
                        />
                      ))}
                      <Divider />
                      <CustomerDetailRow title="Total Rate" text={`$${shift.payout.totalRate} per hour`} />
                      {shift.payout.extraBonus && (
                        <CustomerDetailRow
                          title="Extra Bonus"
                          text={`$${(shift.payout.extraBonus / 100).toFixed(2)}`}
                        />
                      )}
                      {(shift.payout.hours || shift.payout.totalAmount) && <Divider />}
                      {shift.payout.hours && <CustomerDetailRow title="Hours Worked" text={hoursText} />}
                      {shift.payout.totalAmount && (
                        <CustomerDetailRow
                          title="Total Amount"
                          text={`$${(shift.payout.totalAmount / 100).toFixed(2)}`}
                        />
                      )}
                    </Col>
                  </>
                )}
                {shift.billRate && <FacilityBillRate billRate={shift.billRate} />}
              </Row>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <FalconCardHeader title="Notifications" />
            <CardBody className="border-top" style={{ zoom: 0.8 }}>
              {shift.isFree ? (
                <ShiftNotifications shift={shift} />
              ) : (
                <div>Notifications not available for shifts that are not free</div>
              )}
            </CardBody>
          </Card>
          <Card className="mb-3">
            <FalconCardHeader title="Discontents" />
            <CardBody className="border-top" style={{ zoom: 0.8 }}>
              {isIterableArray(discontents) ? (
                discontents.flatMap((discontent, idx) => (
                  <Fragment key={idx}>
                    <Row>
                      <Col>
                        Facility:{' '}
                        <a href={`/facilities/${discontent.facilityIdentifier}`}>
                          <b> {discontent.facilityIdentifier}</b>
                        </a>
                      </Col>
                      <Col>
                        Nurse:{' '}
                        <a href={`/users/${discontent.nurseId}`}>
                          <b> {discontent.nurseId}</b>
                        </a>
                      </Col>
                      <Col>
                        Reason:{' '}
                        <b>
                          {discontent.shiftDiscontentReason.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                            return str.toUpperCase();
                          })}
                        </b>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col style={{ whiteSpace: 'pre' }}>
                        <b>Details:</b> {discontent.shiftDiscontentReasonDetails}
                      </Col>
                    </Row>
                    <hr />
                  </Fragment>
                ))
              ) : (
                <div>No shifts discontent</div>
              )}
            </CardBody>
          </Card>
          {reviewExists && (
            <Card className="mb-3">
              <FalconCardHeader title="Review" />
              <CardBody className="bg-light border-top">
                <Row>
                  <Col lg className="col-xxl-5">
                    <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Information</h6>
                    {review.emailedAt && (
                      <CustomerDetailRow
                        title="Last Emailed At"
                        text={moment(review.emailedAt[review.emailedAt.length - 1].toDate()).format(
                          'MMMM Do YYYY, h:mm A z'
                        )}
                      />
                    )}
                    <CustomerDetailRow
                      title="Submitted At"
                      text={moment(review.submittedAt.toDate()).format('MMMM Do YYYY, h:mm A z')}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '15px' }}>
                  <Col lg className="col-xxl-5">
                    <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Responses</h6>
                    {shiftReviewQuestionsOrder.map((id) => (
                      <Row key={id}>
                        <div style={{ marginLeft: '16px' }}>
                          <p className={classNames('font-weight-semi-bold', 'fs--1', 'mb-1')}>
                            {shiftReviewQuestions[id].text}
                          </p>
                          <p className="fs--1">
                            {shiftReviewQuestions[id].type === 'boolean'
                              ? reviewMap[id]
                                ? 'Yes'
                                : 'No'
                              : reviewMap[id]}
                          </p>
                        </div>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </div>
      )}
      <CancelModal onSave={loadShift} id={id} isOpen={isOpenCancelModal} setIsOpen={setIsOpenCancelModal} />
      <PayoutBonusModal onSave={loadShift} id={id} isOpen={isOpenPayoutAddBonus} setIsOpen={setIsOpenPayoutAddBonus} />
      <EditPayoutBonusModal
        onSave={loadShift}
        id={id}
        isOpen={isOpenPayoutEditBonus}
        setIsOpen={setIsOpenPayoutEditBonus}
        shift={shift}
      />
    </Fragment>
  );
};

export default ShiftDetail;
