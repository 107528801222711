import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import ProductProvider from '../components/e-commerce/ProductProvider';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Dashboard from '../components/dashboard/Dashboard';
import NavbarTop from '../components/navbar/NavbarTop';
import Footer from '../components/footer/Footer';
import { getPageName } from '../helpers/utils';
import AppContext from '../context/Context';
import Shifts from '../components/page/Shifts';
import UserDetail from '../components/page/UserDetail';
import ShiftDetail from '../components/page/ShiftDetail';
import InvoiceDetails from '../components/page/InvoiceDetails';
import FacilityDetail from '../components/page/FacilityDetail';
import CompanyDetail from '../components/page/CompanyDetail';
import Nurses from '../components/page/Nurses';
import Applicants from '../components/page/Applicants';
import Facilities from '../components/page/Facilities';
import Invoices from '../components/page/Invoices';
import Payouts from '../components/page/Payouts';
import Discontents from '../components/page/Discontents';
import Timesheets from '../components/page/Timesheets';
import { employeeRoutes } from '../routes';
import HotMarkets from '../components/page/HotMarkets';
import { UpdatePayouts } from '../components/page/UpdatePayouts';

const DashboardLayout = () => {
  const location = useLocation();
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName('kanban');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
      <ProductProvider>
        <div className="content">
          <NavbarTop />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/payouts" element={<Payouts />} />
            <Route path="/invoices/:id" element={<InvoiceDetails />} />
            <Route path="/users/:id" element={<UserDetail />} />
            <Route path={employeeRoutes.to} element={<Nurses />} />
            <Route path="/applicants" element={<Applicants />} />
            <Route path="/facilities" element={<Facilities />} />
            <Route path="/facilities/:id" element={<FacilityDetail />} />
            <Route path="/companies/:id" element={<CompanyDetail />} />
            <Route path="/shifts" element={<Shifts />} />
            <Route path="/shifts/:id" element={<ShiftDetail />} />
            <Route path="/discontents" element={<Discontents />} />
            <Route path="/hotMarkets" element={<HotMarkets />} />
            <Route path="/updatePayouts" element={<UpdatePayouts />} />
            <Route path="/timesheets" element={<Timesheets />} />
            <Route path="*" element={<Navigate to="/errors/404" />} />
          </Routes>
          {!isKanban && <Footer />}
        </div>
      </ProductProvider>
    </div>
  );
};

export default DashboardLayout;
