export const homeRoutes = {
  name: 'Home',
  to: '/',
  exact: true,
  icon: 'home',
};

export const authenticationRoutes = {
  name: 'Authentication',
  to: '/authentication',
  icon: 'lock',
  children: [
    {
      to: '/authentication/basic',
      name: 'Basic',
      children: [
        { to: '/authentication/basic/login', name: 'Login' },
        { to: '/authentication/basic/logout', name: 'Logout' },
        { to: '/authentication/basic/register', name: 'Register' },
        { to: '/authentication/basic/forget-password', name: 'Forgot password' },
        { to: '/authentication/basic/password-reset', name: 'Reset password' },
        { to: '/authentication/basic/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/basic/lock-screen', name: 'Lock screen' },
      ],
    },
    {
      to: '/authentication/card',
      name: 'Card',
      children: [
        { to: '/authentication/card/login', name: 'Login' },
        { to: '/authentication/card/logout', name: 'Logout' },
        { to: '/authentication/card/register', name: 'Register' },
        { to: '/authentication/card/forget-password', name: 'Forgot password' },
        { to: '/authentication/card/password-reset', name: 'Reset password' },
        { to: '/authentication/card/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/card/lock-screen', name: 'Lock screen' },
      ],
    },
    {
      to: '/authentication/split',
      name: 'Split',
      children: [
        { to: '/authentication/split/login', name: 'Login' },
        { to: '/authentication/split/logout', name: 'Logout' },
        { to: '/authentication/split/register', name: 'Register' },
        { to: '/authentication/split/forget-password', name: 'Forgot password' },
        { to: '/authentication/split/password-reset', name: 'Reset password' },
        { to: '/authentication/split/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/split/lock-screen', name: 'Lock screen' },
      ],
    },
    {
      to: '/authentication/wizard',
      name: 'Wizard',
    },
  ],
};

export const shiftRoutes = {
  name: 'Shifts',
  to: '/shifts',
  exact: true,
  icon: ['fab', 'trello'],
};

export const employeeRoutes = {
  name: 'Employees',
  to: '/nurses',
  exact: true,
  icon: [],
};

export const applicantRoutes = {
  name: 'Applicants',
  to: '/applicants',
  exact: true,
  icon: [],
};

export const facilityRoutes = {
  name: 'Facilities',
  to: '/facilities',
  exact: true,
  icon: ['fas', 'building'],
};

export const professionalRoutes = {
  name: 'Professionals',
  exact: true,
  icon: ['fas', 'user'],
  children: [applicantRoutes, employeeRoutes],
};

export const invoiceRoutes = {
  name: 'Invoices',
  to: '/invoices',
  exact: true,
  icon: [],
};

export const payoutRoutes = {
  name: 'Payouts',
  to: '/payouts',
  exact: true,
  icon: [],
};

export const financeRoutes = {
  name: 'Finances',
  exact: true,
  icon: ['fas', 'file-invoice-dollar'],
  children: [invoiceRoutes, payoutRoutes],
};

export const discontentRoutes = {
  name: 'Discontents',
  exact: true,
  to: '/discontents',
  icon: ['fab', 'trello'],
};

export const timesheetRoutes = {
  name: 'Timesheets',
  to: '/timesheets',
  exact: true,
  icon: ['fa', 'clock'],
  requiredStatus: 'active',
};

export default [
  homeRoutes,
  professionalRoutes,
  shiftRoutes,
  facilityRoutes,
  financeRoutes,
  discontentRoutes,
  timesheetRoutes,
];
