import firebase from 'firebase';
import { Parser } from 'json2csv';
import { useContext, useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { getFacilities } from '../../redux/actions/facilityActions';
import { useSelector, useDispatch } from 'react-redux';
import { checkShiftRequiresMedpass, getShiftsByDistance } from '../../api/shifts';
import { calculatePayout } from '../../helpers/shifts';
import { getAllUsers } from '../../api/user';
import { TestContext } from '../../context/Context';
import { Shift, ShiftExportRow, Worker, AdditionalRequirements, WithId, Collection } from '../../types';
import { db } from '../../firebase';
import moment from 'moment';

const Required = () => <span style={{ color: 'red' }}>&nbsp;*</span>;

interface FormValues {
  radiusInMi: number;
  startDate: Date;
  endDate: Date;
  address: string;
  city: string;
  state: string;
  zip: string;
}

interface DateSelectModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

function DateSelectModal({ isOpen, setIsOpen }: DateSelectModalProps) {
  const { isTest } = useContext(TestContext);
  const toast = useToast();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFacilities(isTest));
  }, [dispatch, isTest]);
  const { facilities } = useSelector((state: any) => state.facility);
  const facilityData = facilities.reduce((acc: any, facility: any) => {
    acc[facility.id] = facility;
    return acc;
  }, {});

  const onSubmit = async ({ startDate, endDate, radiusInMi, address, city, state, zip }: FormValues) => {
    try {
      let shifts: WithId<Shift>[] = [];
      if (radiusInMi && address && city && state && zip) {
        const coordFn = firebase.functions().httpsCallable('getCoordinates');
        const coordinates = await coordFn({
          address,
          city,
          state,
          zip,
        });
        const opts = [
          {
            field: 'isTest',
            op: '==',
            value: isTest,
          },
        ];
        const postOpts = [
          (shift: Shift) => shift.start.toDate() >= startDate,
          (shift: Shift) => shift.end.toDate() <= endDate,
        ];
        shifts = (await getShiftsByDistance(
          coordinates.data.lat,
          coordinates.data.lng,
          radiusInMi,
          opts,
          postOpts
        )) as WithId<Shift & { distanceInMi: number }>[];
      } else {
        const snaps = await db
          .collection(Collection.SHIFTS)
          .where('start', '>=', startDate)
          .where('start', '<=', endDate)
          .where('isTest', '==', isTest)
          .get();
        snaps.docs.forEach((doc) => {
          shifts.push({ ...(doc.data() as Shift), id: doc.id });
        });
      }

      const userSnaps = await getAllUsers();
      const userData: { [userId: string]: Worker } = {};
      userSnaps.docs.forEach((doc) => {
        userData[doc.id] = doc.data() as Worker;
      });
      const data: ShiftExportRow[] = [];
      shifts.forEach((shift) => {
        const newData: ShiftExportRow = {
          ...shift,
          startTime: shift.start?.toDate().toLocaleString(),
          endTime: shift.end?.toDate().toLocaleString(),
          isMedPass: false,
          isCancelled: shift.status === 'cancelled' ? 'Yes' : 'No',
          cancelledAtStr: shift.cancelledAt?.toDate().toLocaleString(),
        };
        if (shift.nurseId) {
          const user = userData[shift.nurseId];
          newData.nurseName = `${user.firstName} ${user.lastName}`;
        }
        if (shift.timesheetActionMadeBy) {
          const user = userData[shift.timesheetActionMadeBy];
          newData.timesheetActionMadeByFullName = `${user.firstName} ${user.lastName}`;
        }
        if (shift.facilityIdentifier && facilityData[shift.facilityIdentifier]) {
          newData.facilityName = facilityData[shift.facilityIdentifier].facilityName;
          newData.facilityState = facilityData[shift.facilityIdentifier].facilityState;
        }
        if (checkShiftRequiresMedpass(shift)) {
          newData.isMedPass = true;
        }
        if ((shift.additionalRequirements || []).includes(AdditionalRequirements.COVID_VACCINATION)) {
          newData.hasCovidVaccinationRequirement = true;
        }
        data.push(newData);
      });
      if (data.length > 0) {
        const parser = new Parser({
          fields: [
            {
              label: 'ID',
              value: (row: ShiftExportRow) => `=HYPERLINK("https://admin.ptnurse.com/shifts/${row.id}","${row.id}")`,
            },
            {
              label: 'Facility',
              value: (row: ShiftExportRow) =>
                `=HYPERLINK("https://admin.ptnurse.com/users/${row.facilityIdentifier}","${row.facilityName}")`,
            },
            {
              label: 'Facility State',
              value: 'facilityState',
            },
            { label: 'Description', value: 'description' },
            { label: 'Is Free', value: (row: ShiftExportRow) => (row.isFree ? 'Yes' : 'No') },
            { label: 'Start Time', value: 'startTime' },
            { label: 'End Time', value: 'endTime' },
            { label: 'Nurse Type', value: 'nurseType' },
            {
              label: 'Distance from center (mi)',
              value: (row: ShiftExportRow) => (row.distanceInMi ? row.distanceInMi.toFixed(2) : ''),
            },
            {
              label: 'Nurse Name',
              value: (row: ShiftExportRow) =>
                row.nurseId ? `=HYPERLINK("https://admin.ptnurse.com/users/${row.nurseId}","${row.nurseName}")` : '',
            },
            { label: 'Clock In', value: (row: ShiftExportRow) => row?.clockIn?.toDate().toLocaleString() },
            { label: 'Clock Out', value: (row: ShiftExportRow) => row?.clockOut?.toDate().toLocaleString() },
            {
              label: 'Hourly Base Rate',
              value: (row: ShiftExportRow) => {
                if (row.payout?.baseRate) {
                  return `$${(row.payout?.baseRate / 100).toFixed(2)}`;
                }
              },
            },
            {
              label: 'Calculated Payouts',
              value: (row: ShiftExportRow) => {
                if (row.payout?.totalAmount) {
                  return `$${(row.payout?.totalAmount / 100).toFixed(2)}`;
                }
                return null;
              },
            },
            {
              label: 'Facility Bill Rate',
              value: (row: ShiftExportRow) => {
                if (row.billRate?.totalAmount) {
                  return `$${(row.billRate?.totalAmount / 100).toFixed(2)}`;
                }
                return null;
              },
            },
            {
              label: 'Using New Calculated Payouts',
              value: (row: ShiftExportRow) => {
                return row.payout?.totalAmount ? 'Yes' : 'No';
              },
            },
            { label: 'Notes', value: 'additionalnotes' },
            { label: 'Cancelled', value: 'isCancelled' },
            { label: 'Cancelled Datetime', value: 'cancelledAtStr' },
            { label: 'COVID Bonus', value: (row: ShiftExportRow) => (row.isCovidBonus ? 'Yes' : 'No') },
            {
              label: 'Requires COVID Vaccination',
              value: (row: ShiftExportRow) => (row.hasCovidVaccinationRequirement ? 'Yes' : 'No'),
            },
            {
              label: 'Timesheet Action',
              value: 'timesheetAction',
            },
            {
              label: 'Timesheet Action Made By',
              value: 'timesheetActionMadeByFullName',
            },
            {
              label: 'Timesheet Action Made At',
              value: (row: ShiftExportRow) =>
                row.timesheetActionMadeAt ? row.timesheetActionMadeAt.toDate().toLocaleString() : null,
            },
            {
              label: 'Hours Worked (matches invoice)',
              value: (row: ShiftExportRow) => row.billRate?.hours,
            },
          ],
        });
        save('shift-export.csv', parser.parse(data));
      }
      setIsOpen(false);
    } catch (e: any) {
      console.error(e);
      toast({
        title: 'Failed to download report',
        description: e.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  function save(filename: string, data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    if (typeof (window.navigator as any).msSaveOrOpenBlob !== 'undefined') {
      (window.navigator as any).msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Shift Export</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing={4} align="stretch" mb={4}>
              <Container>
                <Box>
                  <Heading size="md">Shift Start Date Range</Heading>
                  <FormControl isInvalid={Boolean(errors.startDate)}>
                    <FormLabel htmlFor="startDate">
                      From
                      <Required />
                    </FormLabel>
                    <Controller
                      rules={{ required: 'Start date is required' }}
                      render={({ field }) => (
                        <Datetime
                          timeFormat={true}
                          value={field.value}
                          onChange={(dateTime) => {
                            if (typeof dateTime !== 'string') {
                              field.onChange(dateTime.toDate());
                            }
                          }}
                          dateFormat="MM-DD-YYYY"
                          inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'eventStart' }}
                        />
                      )}
                      name="startDate"
                      control={control}
                    />
                    <FormErrorMessage>{errors.endDate && errors.endDate.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={Boolean(errors.endDate)}>
                    <FormLabel htmlFor="endDate">
                      To
                      <Required />
                    </FormLabel>
                    <Controller
                      rules={{ required: 'End date is required' }}
                      render={({ field }) => (
                        <Datetime
                          timeFormat={true}
                          value={field.value}
                          onChange={(dateTime) => {
                            if (typeof dateTime !== 'string') {
                              field.onChange(dateTime.toDate());
                            }
                          }}
                          dateFormat="MM-DD-YYYY"
                          inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'eventStart' }}
                        />
                      )}
                      name="endDate"
                      control={control}
                    />
                    <FormErrorMessage>{errors.endDate && errors.endDate.message}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box mt={7}>
                  <Heading size="md">Location Filter (Center and Radius)</Heading>
                  <Grid templateColumns="repeat(2, 1fr)" templateRows="repeat(3, 1fr)" rowGap={3} colGap={3}>
                    <GridItem colSpan={2} rowSpan={1}>
                      <FormControl isInvalid={Boolean(errors.address)}>
                        <FormLabel htmlFor="address">Address</FormLabel>
                        <Controller render={({ field }) => <Input {...field} />} name="address" control={control} />
                        <FormErrorMessage>{errors.address && errors.address.message}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem w="90%" colSpan={1} rowSpan={1}>
                      <FormControl isInvalid={Boolean(errors.city)}>
                        <FormLabel htmlFor="city">City</FormLabel>
                        <Controller render={({ field }) => <Input {...field} />} name="city" control={control} />
                        <FormErrorMessage>{errors.city && errors.city.message}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem w="90%" colSpan={1} rowSpan={1}>
                      <FormControl isInvalid={Boolean(errors.state)}>
                        <FormLabel htmlFor="state">State</FormLabel>
                        <Controller render={({ field }) => <Input {...field} />} name="state" control={control} />
                        <FormErrorMessage>{errors.state && errors.state.message}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem w="90%" colSpan={1} rowSpan={1}>
                      <FormControl isInvalid={Boolean(errors.zip)}>
                        <FormLabel htmlFor="zip">Zip</FormLabel>
                        <Controller render={({ field }) => <Input {...field} />} name="zip" control={control} />
                        <FormErrorMessage>{errors.zip && errors.zip.message}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem w="90%" colSpan={1} rowSpan={1}>
                      <FormControl isInvalid={Boolean(errors.radiusInMi)}>
                        <FormLabel htmlFor="radiusInMi">Radius (mi)</FormLabel>
                        <Controller
                          render={({ field }) => <Input placeholder="100" type="number" {...field} />}
                          name="radiusInMi"
                          control={control}
                        />
                        <FormErrorMessage>{errors.radiusInMi && errors.radiusInMi.message}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </Grid>
                </Box>
              </Container>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setIsOpen(false)} variant="ghost">
              Close
            </Button>
            <Button isLoading={isSubmitting} colorScheme="blue" mr={3} type="submit">
              Export
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default function ShiftsExportButton({ data = [], fields = [] }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        color="falcon-default"
        size="sm"
        className="ml-2"
      >
        Export
      </Button>
      <DateSelectModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
