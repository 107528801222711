import firebase from 'firebase';
import { getAllUsers } from '../../api/user';
import { Collection } from '../../constants';
import { db } from '../../firebase';
import { STATS_LIST_REQUEST, STATS_LIST_SUCCESS, STATS_LIST_FAIL, STATS_SORT } from '../constants/statsConstants';

const sortStatsHelper = (stats, field, order) => {
  return [...stats].sort((a, b) => {
    let higher = a;
    let lower = b;
    if (order === 'desc') {
      higher = b;
      lower = a;
    }
    if (higher[field] < lower[field]) {
      return -1;
    }
    if (higher[field] > lower[field]) {
      return 1;
    }
    return 0;
  });
};

export const sortStats = (field, order) => (dispatch, getState) => {
  const { stats } = getState().stats;
  const sortedStats = sortStatsHelper(stats, field, order);

  dispatch({
    type: STATS_SORT,
    payload: {
      stats: sortedStats,
      field,
      order,
    },
  });
};

const pickSnapFn = (snapChild, statsObj) => {
  const data = snapChild.data();
  if (!(data.nurseId in statsObj)) {
    statsObj[data.nurseId] = { pickedShifts: 0, droppedShifts: 0, pickedEvents: [], droppedEvents: [] };
  }
  statsObj[data.nurseId].pickedShifts += 1;
  statsObj[data.nurseId].pickedEvents.push(data);
};

const leaveSnapFn = (snapChild, statsObj) => {
  const data = snapChild.data();
  if (!(data.nurseId in statsObj)) {
    statsObj[data.nurseId] = { pickedShifts: 0, droppedShifts: 0, pickedEvents: [], droppedEvents: [] };
  }
  statsObj[data.nurseId].droppedShifts += 1;
  statsObj[data.nurseId].droppedEvents.push(data);
};

const makeObjFn = (snapChild, eventsObj) => {
  eventsObj[snapChild.id] = snapChild.data();
  return eventsObj;
};

export const getStats =
  (filter = undefined, isTest) =>
  async (dispatch, getState) => {
    dispatch({
      type: STATS_LIST_REQUEST,
      payload: filter,
    });
    const { filters, field, order } = getState().stats;
    if (filter) {
      filters[filter.filterName] = filter.filterValue;
    }
    try {
      // Instantiate basic query
      let statsPickSnap = db
        .collection(Collection.STATS)
        .where('eventName', '==', 'pick_shift')
        .where('isTest', '==', isTest);
      let statsLeaveSnap = db
        .collection(Collection.STATS)
        .where('eventName', '==', 'leave_shift')
        .where('isTest', '==', isTest);

      // Instantiate and execute timeframe query
      let timeframeStatsPickSnap;
      let timeframeStatsLeaveSnap;
      if (filters.timeframe !== null) {
        const date = new Date();
        date.setDate(date.getDate() - filters.timeframe);
        timeframeStatsPickSnap = await statsPickSnap
          .where('createdAt', '>', firebase.firestore.Timestamp.fromDate(date))
          .get();
        timeframeStatsLeaveSnap = await statsLeaveSnap
          .where('createdAt', '>', firebase.firestore.Timestamp.fromDate(date))
          .get();
      }

      // Instantiate and execute timeToStart query
      let timeToStartStatsPickSnap;
      let timeToStartStatsLeaveSnap;
      if (filters.timeToStart !== null) {
        const threshold = filters.timeToStart * 60 * 60;
        timeToStartStatsPickSnap = await statsPickSnap.where('secondsToStart', '<', threshold).get();
        timeToStartStatsLeaveSnap = await statsLeaveSnap.where('secondsToStart', '<', threshold).get();
      }

      const nurseSnap = await getAllUsers();

      let statsObj = {};
      if (filters.timeframe == null && filters.timeToStart == null) {
        statsPickSnap = await statsPickSnap.get();
        statsLeaveSnap = await statsLeaveSnap.get();
        statsPickSnap.forEach((snapChild) => pickSnapFn(snapChild, statsObj));
        statsLeaveSnap.forEach((snapChild) => leaveSnapFn(snapChild, statsObj));
      } else if (filters.timeframe != null && filters.timeToStart == null) {
        timeframeStatsPickSnap.forEach((snapChild) => pickSnapFn(snapChild, statsObj));
        timeframeStatsLeaveSnap.forEach((snapChild) => leaveSnapFn(snapChild, statsObj));
      } else if (filters.timeframe == null && filters.timeToStart != null) {
        timeToStartStatsPickSnap.forEach((snapChild) => pickSnapFn(snapChild, statsObj));
        timeToStartStatsLeaveSnap.forEach((snapChild) => leaveSnapFn(snapChild, statsObj));
      } else {
        // If two filters are active, we have to execute them separately and take the intersection
        // This is because firebase does not allow multiple `where`s if they are not '='
        const timeframeEventObj = {};
        timeframeStatsPickSnap.forEach((snapChild) => makeObjFn(snapChild, timeframeEventObj));
        timeframeStatsLeaveSnap.forEach((snapChild) => makeObjFn(snapChild, timeframeEventObj));
        const timeToStartObj = {};
        timeToStartStatsPickSnap.forEach((snapChild) => makeObjFn(snapChild, timeToStartObj));
        timeToStartStatsLeaveSnap.forEach((snapChild) => makeObjFn(snapChild, timeToStartObj));
        const joinedEventObj = Object.keys(timeframeEventObj).reduce((acc, id) => {
          if (id in timeToStartObj) {
            return { ...acc, ...{ [id]: timeframeEventObj[id] } };
          }
          return acc;
        }, {});
        Object.keys(joinedEventObj).forEach((eventId) => {
          const event = joinedEventObj[eventId];
          if (!(event.nurseId in statsObj)) {
            statsObj[event.nurseId] = { pickedShifts: 0, droppedShifts: 0, pickedEvents: [], droppedEvents: [] };
          }
          if (event.eventName === 'pick_shift') {
            statsObj[event.nurseId].pickedShifts += 1;
            statsObj[event.nurseId].pickedEvents.push(event);
          }
          if (event.eventName === 'leave_shift') {
            statsObj[event.nurseId].droppedShifts += 1;
            statsObj[event.nurseId].droppedEvents.push(event);
          }
        });
      }

      nurseSnap.forEach((snapChild) => {
        if (snapChild.id in statsObj) {
          const data = snapChild.data();
          statsObj[snapChild.id].firstName = data.firstName;
          statsObj[snapChild.id].lastName = data.lastName;
        }
      });
      dispatch({
        type: STATS_LIST_SUCCESS,
        payload: {
          stats: sortStatsHelper(Object.values(statsObj), field, order),
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: STATS_LIST_FAIL,
      });
    }
  };
