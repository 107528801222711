import React from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from './FalconCardHeader';
import BootstrapTable from 'react-bootstrap-table-next';

const timeFormatter = (time) => <span>{time?.toDate().toLocaleString()}</span>;

const SuspendEventsTable = ({ suspendEvents }) => {
  const columns = [
    {
      dataField: 'action',
      text: 'Event Type',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: 'timestamp',
      text: 'Timestamp',
      headerClasses: 'border-0',
      formatter: timeFormatter,
      classes: 'border-0 py-2 align-middle',
      sort: true,
    },
    {
      dataField: 'reason',
      text: 'Reason',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-right',
      align: 'left',
    },
  ];

  return (
    <Card className="mt-3">
      <FalconCardHeader title="Suspension Events" light={false} />
      <CardBody className="p-0">
        <>
          <div className="table-responsive">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={suspendEvents}
              sort={{ dataField: 'timestamp', order: 'desc' }}
              columns={columns}
              bordered={false}
              classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
              rowClasses="btn-reveal-trigger border-top border-200"
              headerClasses="bg-200 text-900 border-y border-200"
            />
          </div>
        </>
      </CardBody>
    </Card>
  );
};

export default SuspendEventsTable;
