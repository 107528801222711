import React, { useCallback } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { updatePayout } from '../../redux/actions/payoutActions';
import { functions } from '../../firebase';
import { toast } from 'react-toastify';

const PayoutTableActions = ({ payout }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const changeStatus = useCallback(
    (status) => {
      dispatch(updatePayout({ ...payout, status }));
    },
    [dispatch, payout]
  );
  const createTxr = useCallback(() => {
    const payoutCallById = functions.httpsCallable('payoutCallById');
    payoutCallById({
      id: payout.id,
    })
      .then((res) => {
        console.log('payoutCallById', res);
        dispatch(updatePayout({ ...payout, status: 'processing' }));
      })
      .catch((e) => {
        console.error('payoutCallById', e);
        toast('There was an error processing the request.', {
          type: 'error',
        });
      });
  }, [payout, dispatch]);
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu right className="border py-2">
        <DropdownItem header>Change Status</DropdownItem>
        <DropdownItem
          onClick={() => {
            changeStatus('paid');
          }}
        >
          Paid
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            changeStatus('cancelled');
          }}
        >
          Cancelled
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            changeStatus('unpaid');
          }}
        >
          Unpaid
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          onClick={() => {
            createTxr();
          }}
        >
          Instant Payout
        </DropdownItem>
        <DropdownItem onClick={() => history.push(`/invoices/${payout?.invoice.id}`)}>View Invoice</DropdownItem>
        {payout.status === 'halted' && (
          <DropdownItem
            onClick={() => alert(`Error Code: ${payout?.errorCode}\nError Message: ${payout.errorMessage}`)}
          >
            View Error Details
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default PayoutTableActions;
