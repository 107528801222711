import { useNavigate } from 'react-router';
import React, { useContext, useEffect } from 'react';
import { Button, Card, CardBody, Col } from 'reactstrap';
import ShiftsExportButton from '../../components/common/ShiftExportButton';
import CreateShiftButton from '../../components/common/CreateShiftButton';
import FalconCardHeader from '../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap/lib';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { getShifts } from '../../redux/actions/shiftActions';
import StartDateFilter from '../common/StartDateFilter';
import ExpiredFilter from '../common/ExpiredFilter';
import { TestContext } from '../../context/Context';

const anchorFormatter = (description, { id }) => (
  <a href={`/shifts/${id}`}>
    <b>{description}</b>
  </a>
);

const badgeFormatter = (isFree, row) => {
  let color = '';
  let icon = '';
  let text = 'Is free';
  switch (isFree) {
    case true:
      color = 'secondary';
      text = 'Unclaimed';
      break;
    case false:
      color = 'success';
      icon = 'check';
      text = 'Claimed';
      break;
    default:
      color = 'warning';
      icon = 'ban';
      text = 'cancelled';
  }
  if (row.status === 'cancelled') {
    color = 'warning';
    icon = 'ban';
    text = 'Cancelled';
  }
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule">
      {text}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
    </Badge>
  );
};

const actionFormatter = (dataField, { id }) => (
  // Control your row with this id
  <UncontrolledDropdown>
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
      <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right className="border py-2">
      <DropdownItem onClick={() => console.log('Edit: ', id)}>Edit</DropdownItem>
      <DropdownItem onClick={() => console.log('Delete: ', id)} className="text-danger">
        Delete
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const timeFormatter = (time) => <span>{time?.toDate().toLocaleString()}</span>;

const Shifts = ({ showLoadMore = true }) => {
  const { isTest } = useContext(TestContext);
  const {
    shifts,
    loading,
    hasMore,
    field: stateField,
    order: stateOrder,
    filters,
  } = useSelector((state) => state.shift);
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShifts(filters, stateField, stateOrder, isTest));
    // adding deps will cause dupe fetches which can cause race conditions
    /* eslint-disable-next-line */
  }, [dispatch, isTest]);

  const onSort = (field, order) => {
    if (field !== stateField || order !== stateOrder) {
      dispatch(getShifts(filters, field, order, isTest));
    }
  };

  const columns = [
    {
      dataField: 'description',
      text: 'Description',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: anchorFormatter,
    },
    {
      dataField: 'start',
      headerClasses: 'border-0',
      text: 'Start',
      classes: 'border-0 py-2 align-middle',
      align: 'right',
      formatter: timeFormatter,
      headerAlign: 'right',
      sort: true,
      onSort,
    },
    {
      dataField: 'end',
      headerClasses: 'border-0',
      text: 'End',
      classes: 'border-0 py-2 align-middle',
      formatter: timeFormatter,
      align: 'right',
      headerAlign: 'right',
    },
    {
      dataField: 'isFree',
      text: 'Status',
      formatter: badgeFormatter,
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      align: 'center',
      headerAlign: 'center',
    },
    {
      dataField: 'nurseName',
      text: 'Nurse name',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      align: 'left',
    },
    {
      dataField: 'additionalnotes',
      text: 'Additional notes',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: '',
      headerClasses: 'border-0',
      text: '',
      classes: 'border-0 py-2 align-middle',
      formatter: actionFormatter,
      align: 'right',
    },
  ];

  return (
    <Card>
      <FalconCardHeader title="Shifts">
        <div className="d-flex">
          <ExpiredFilter
            onSubmit={(filter) => {
              dispatch(getShifts(filter, stateField, stateOrder, isTest));
            }}
          />
          <StartDateFilter
            onSubmit={(filter) => {
              dispatch(getShifts(filter, stateField, stateOrder, isTest));
            }}
          />
          <ShiftsExportButton />
          <CreateShiftButton />
        </div>
      </FalconCardHeader>
      <CardBody className="fs--1 p-0">
        <>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={showLoadMore ? shifts : shifts.slice(0, 5)}
            sort={{ dataField: stateField, order: stateOrder }}
            columns={columns}
            bordered={false}
            classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
            rowClasses="btn-reveal-trigger border-top border-200"
            headerClasses="bg-200 text-900 border-y border-200"
            loading={loading}
          />
          <Row noGutters className="px-1 py-3  d-flex justify-content-center">
            <Col xs="auto" className="pr-3">
              {showLoadMore ? (
                <Button
                  size="sm"
                  onClick={() => {
                    dispatch(getShifts(filters, stateField, stateOrder, isTest));
                  }}
                  className="px-4 ml-2 btn-light"
                  disabled={!hasMore || loading}
                >
                  {loading ? 'Loading...' : hasMore ? 'Load More' : 'No more items'}
                </Button>
              ) : (
                <Button
                  size="sm"
                  onClick={() => {
                    history.push('/shifts');
                  }}
                  className="px-4 ml-2 btn-light"
                >
                  View All
                </Button>
              )}
            </Col>
          </Row>
        </>
      </CardBody>
    </Card>
  );
};

export default Shifts;
