import {
  PAYOUT_LIST_FAIL,
  PAYOUT_LIST_REQUEST,
  PAYOUT_LIST_SUCCESS,
  PAYOUT_HAS_MORE,
  PAYOUT_EDIT,
} from '../constants/payoutConstants';

const initialState = {
  payouts: [],
  loading: false,
  error: false,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PAYOUT_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case PAYOUT_LIST_SUCCESS:
      return { payouts: [...state.payouts, ...payload], loading: false };
    case PAYOUT_LIST_FAIL:
      return { ...state, loading: false, error: true };
    case PAYOUT_HAS_MORE:
      return { ...state, hasMore: payload };
    case PAYOUT_EDIT:
      const index = state.payouts.findIndex((p) => p.id === payload.id);
      if (index === -1) {
        return state;
      }
      return {
        ...state,
        payouts: [
          ...state.payouts.slice(0, index),
          { ...state.payouts[index], ...payload },
          ...state.payouts.slice(index + 1),
        ],
      };
    default:
      return state;
  }
};
