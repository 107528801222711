import React from 'react';
import { InputGroup, InputLeftAddon, Input } from '@chakra-ui/react';

interface MoneyInputOption {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  key: string | number;
}

interface MoneyInputProps {
  options: MoneyInputOption[];
}

const MoneyInput = (props: any) => {
  return (
    <InputGroup {...props}>
      <InputLeftAddon>$</InputLeftAddon>
      <Input value={props.value} type="number" />
    </InputGroup>
  );
};

export default MoneyInput;
