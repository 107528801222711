import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import DateTimePicker from 'react-datetime';

export default function DatePromptModal({ onClose, onApprove }) {
  const [date, setDate] = useState(moment());
  return (
    <div
      className="modal"
      style={{ display: 'block' }}
      id="modelId"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="modelTitleId"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Select Expiration Date</h5>
            <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <DateTimePicker
              value={date}
              onChange={(dateTime) => {
                if (dateTime._isValid) {
                  setDate(dateTime);
                }
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'eventStart' }}
            />
          </div>
          <div className="modal-footer">
            <button type="button" onClick={() => onClose()} className="btn btn-secondary" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                onApprove(date);
              }}
              className="btn btn-primary"
              data-dismiss="modal"
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
