import React from 'react';
import { useState } from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ButtonIcon from './ButtonIcon';
import Input from 'reactstrap/lib/Input';
import { validateEmail } from '../../helpers/utils';
import { inviteFacilityAdmin } from '../../api/facility';
import { toast } from 'react-toastify';

function InviteModal({ open, setOpen, facilityId }) {
  const toggle = () => setOpen(!open);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  const save = async () => {
    try {
      setLoading(true);
      await inviteFacilityAdmin({ facilityId, inviteData: { email, firstName, lastName } });
      toast.success('Successfully invited user to become facility admin');
      setOpen(false);
      resetState();
    } catch (e) {
      toast.error(e.message);
    }
    setLoading(false);
  };

  const formIsValid = firstName && lastName && email && validateEmail(email);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Invite facility admin user</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="fs-0" for="email">
            Email
          </Label>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="firstName">
            First Name
          </Label>
          <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="firstName">
            Last Name
          </Label>
          <Input value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!formIsValid || loading} color="primary" onClick={() => save()}>
          Invite
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default function InviteButton({ facilityId }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ButtonIcon
        onClick={() => {
          setIsOpen(true);
        }}
        icon="user"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        className="ml-2"
      >
        Invite User
      </ButtonIcon>
      <InviteModal open={isOpen} setOpen={setIsOpen} facilityId={facilityId} />
    </>
  );
}
