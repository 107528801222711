import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Row, Table } from 'reactstrap';
import Loader from '../common/Loader';
import createMarkup from '../../helpers/createMarkup';
import { isIterableArray } from '../../helpers/utils';
import { useNavigate, useParams } from 'react-router';
import { db } from '../../firebase';
import moment from 'moment';
import { Collection } from '../../constants';

const formatCurrency = (number, currency) =>
  `${currency}${(number / 100).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;

const ProductTr = ({ description, quantity, rate }) => {
  return (
    <tr>
      <td className="align-middle">
        <h6 className="mb-0 text-nowrap">{description}</h6>
      </td>
      <td className="align-middle text-center">{quantity}</td>
      <td className="align-middle text-right">{formatCurrency(rate, '$')}</td>
      <td className="align-middle text-right">{formatCurrency(rate * quantity, '$')}</td>
    </tr>
  );
};

const InvoiceHeader = ({ institution, logo, address }) => (
  <Row className="align-items-center text-center mb-3">
    <Col sm={6} className="text-sm-left">
      <img src={logo} alt="invoice" width={150} />
    </Col>
    <Col className="text-sm-right mt-3 mt-sm-0">
      <h2 className="mb-3">Invoice</h2>
      <h5>{institution}</h5>
      {address && <p className="fs--1 mb-0" dangerouslySetInnerHTML={createMarkup(address)} />}
    </Col>
    <Col xs={12}>
      <hr />
    </Col>
  </Row>
);

const Invoice = () => {
  const params = useParams();
  const history = useNavigate();
  if (!params.id) {
    history.goBack();
  }
  const [invoice, setInvoice] = useState();
  const [invoiceLoading, setInvoiceLoading] = useState(true);
  useEffect(() => {
    db.collection(Collection.INVOICES)
      .where('invoice.id', '==', params.id)
      .get()
      .then((a) => {
        console.log(a.docs[0].data());
        setInvoice(a.docs[0].data());
      })
      .then(() => setInvoiceLoading(false));
  }, [params.id]);
  return (
    <Fragment>
      <Card className="mb-3">
        <CardBody>
          <Row className="justify-content-between align-items-center">
            <Col md>
              <h5 className="mb-2 mb-md-0">Shift Id: {invoiceLoading ? '' : invoice.shiftId}</h5>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          {invoiceLoading ? (
            <Loader />
          ) : (
            <InvoiceHeader institution="PT Nurse" logo={require('../../assets/img/logos/PT_NURSE.png')} />
          )}

          {invoiceLoading ? (
            <Loader />
          ) : (
            <Row className="justify-content-between align-items-center">
              <Col>
                <h6 className="text-500">Shift Details</h6>
                <h5>
                  <a href={`/shifts/${invoice.shiftId}`}>{invoice.shift.description}</a>
                </h5>
                <p className="fs--1">
                  {invoice.shift.nurseType} ({invoice.shift.numberOfNurse})
                </p>
                <p className="fs--1">
                  Start: {moment(invoice.shift.start?.toDate()).format('MM/DD/YYYY hh:mm A')}
                  <br />
                  End: {moment(invoice.shift.end?.toDate()).format('MM/DD/YYYY hh:mm A')}
                  <br />
                  Clock In: {moment(invoice.shift.clockIn?.toDate()).format('MM/DD/YYYY hh:mm A')}
                  <br />
                  Clock Out: {moment(invoice.shift.clockOut?.toDate()).format('MM/DD/YYYY hh:mm A')}
                </p>
              </Col>
              <Col sm="auto" className="ml-auto">
                <div className="table-responsive">
                  <Table size="sm" borderless className="fs--1">
                    <tbody>
                      <tr>
                        <th className="text-sm-right">Invoice No:</th>
                        <td>{invoice.invoice.number}</td>
                      </tr>
                      <tr>
                        <th className="text-sm-right">Facility:</th>
                        <td>
                          <a href={`/facilities/${invoice.shift.facilityIdentifier}`}>
                            {invoice.facility?.facilityName}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-sm-right">Nurse:</th>
                        <td>
                          <a href={`/users/${invoice.nurseId}`}>
                            {invoice.nurse?.firstName} {invoice.nurse?.lastName}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-sm-right">Due Date:</th>
                        <td>{moment.unix(invoice.invoice.due_date).format('MM/DD/YYYY')}</td>
                      </tr>
                      <tr className="alert-success font-weight-bold">
                        <th className="text-sm-right">Amount Due:</th>
                        <td>{formatCurrency(invoice.invoice.amount_due, '$')}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          )}

          {invoiceLoading ? (
            <Loader />
          ) : (
            <div className="table-responsive mt-4 fs--1">
              <Table striped className="border-bottom">
                <thead>
                  <tr className="bg-primary text-white">
                    <th className="border-0">Products</th>
                    <th className="border-0 text-center">Quantity</th>
                    <th className="border-0 text-right">Rate</th>
                    <th className="border-0 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {isIterableArray(invoice.invoice.lines.data) &&
                    invoice.invoice.lines.data.map((line, index) => (
                      <ProductTr
                        sno={index}
                        quantity={1}
                        rate={line.amount}
                        description={line.description}
                        key={index}
                      />
                    ))}
                </tbody>
              </Table>
            </div>
          )}

          {invoiceLoading ? (
            <Loader />
          ) : (
            <Row noGutters className="justify-content-end">
              <Col xs="auto">
                <Table size="sm" borderless className="fs--1 text-right">
                  <tbody>
                    <tr className="border-top">
                      <th className="text-900">Total:</th>
                      <td className="font-weight-semi-bold">{formatCurrency(invoice.invoice.amount_due, '$')}</td>
                    </tr>
                    <tr className="border-top border-2x font-weight-bold text-900">
                      <th>Amount Due:</th>
                      <td>{formatCurrency(invoice.invoice.amount_due, '$')}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Button size="sm" disabled className="px-4 ml-2 text-capitalize">
                          {invoice.invoice.status}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </CardBody>
        <CardFooter className="bg-light">
          <p className="fs--1 mb-0">
            <strong>Notes: </strong>We really appreciate your business and if there’s anything else we can do, please
            let us know!
          </p>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default Invoice;
