import firebase from 'firebase';
import { Shift, AdditionalRequirements } from './shifts';
import { NurseType, PremierStatus } from './user';
export * from './shifts';
export * from './user';

export enum FacilityStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}

export enum AdditionalCapabilities {
  MED_PASS = 'MeD Pass',
  COVID_VACCINATION = 'COVID Vaccination',
}

export interface HotMarket {
  nurseTypes: NurseType[];
}

export interface PremierWorker {
  id: string;
  status: PremierStatus;
}

export interface FacilityBasePayoutRateConfig {
  centsPerHour: { [key in NurseType]?: number };
  updatedAt: firebase.firestore.Timestamp;
}

export interface Facility {
  additionalRequirements?: AdditionalRequirements[];
  blocklist?: string[];
  createdAt?: firebase.firestore.Timestamp;
  companyId?: string;
  coordinates: firebase.firestore.GeoPoint;
  facilityAddress: string;
  facilityCity: string;
  facilityName: string;
  facilityPhoneNumber: string;
  facilityPostalCode: string;
  facilityState: string;
  geohash?: string;
  hotMarket?: HotMarket;
  notificationEmails?: string[];
  ownerId?: string;
  premierlist?: PremierWorker[];
  tz?: string;
  status: FacilityStatus;
  isTest?: boolean;
  basePayoutRateConfigs?: FacilityBasePayoutRateConfig[];
}

export interface FacilityProfile {
  facilityBlockList: Facility['blocklist'];
}
export interface Company {
  compnanyAddress: string;
  companyCity: string;
  companyName: string;
  companyPhoneNumber: string;
  companyPostalCode: string;
  companyState: string;
  contactInfoVisibility: boolean;
  contactOptions: string;
  createdAt: firebase.firestore.Timestamp;
  notifications: string;
  ownerId: string;
  status: string;
  coordinates: firebase.firestore.GeoPoint;
}

export interface PayoutItem {
  invoiceId: string;
  payoutAmount: number;
  status: 'unpaid' | 'paid';
  shift: Shift;
}

export enum Collection {
  COMPANIES = 'companies',
  FACILITIES = 'facilities',
  INVITES = 'invites',
  INVOICES = 'invoices',
  PAYOUTS = 'payouts',
  PAYOUT_ITEMS = 'payout_items',
  REVIEWS = 'reviews',
  SHIFTDISCONTENTS = 'shiftdiscontents',
  SHIFTS = 'shifts',
  STATS = 'stats',
  STRIPE_ACCOUNTS = 'stripe_accounts',
  STRIPE_CUSTOMERS = 'stripe_customers',
  USER = 'user',
}

export enum InviteType {
  FACILITY_ADMIN = 'facilityAdmin',
}

export interface Invite {
  email: string;
  facilityId: string;
  facilityName: string;
  firstName: string;
  inviteType: InviteType;
  invitedAt: firebase.firestore.Timestamp;
  lastName: string;
}

export type WithId<T> = T & {
  id: string;
};

export type WithNurseId<T> = T & {
  nurseId: string;
};

export enum ReviewResponseQuestionId {
  RATING = 'rating',
  ON_TIME = 'onTime',
  ADDITIONAL_COMMENTS = 'additionalComments',
}

export interface ReviewResponse {
  questionId: ReviewResponseQuestionId;
  responseValue: number | string | boolean;
}

export enum ReviewerType {
  FACILITY = 'facility',
}

export enum RevieweeType {
  WORKER = 'worker',
}

export interface Review {
  emailedAt?: firebase.firestore.Timestamp;
  submittedAt?: firebase.firestore.Timestamp;
  status: string;
  facilityIdentifier?: string;
  nurseId?: string;
  rating?: number;
  responses: ReviewResponse[];
  revieweeType: RevieweeType;
  reviewerType: ReviewerType;
}

export enum CancellationReasonType {
  FILLED_EXTERNALLY = 'filledExternally',
  FILLED_INTERNALLY = 'filledInternally',
  MISTAKE = 'mistake',
  NO_LONGER_NEEDED = 'noLongerNeeded',
  OTHER = 'other',
}
