import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, CustomInput } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getAllWorkers } from '../../api/user';
import { pickShift } from '../../api/shifts';
import { useParams } from 'react-router-dom';
import { TestContext } from '../../context/Context';

const UserInput = ({ userId, setUserId }) => {
  const [options, setOptions] = useState([]);
  const { isTest } = useContext(TestContext);
  useEffect(() => {
    const getUsers = async () => {
      const userSnapshot = await getAllWorkers(isTest);
      const opts = [];
      userSnapshot.forEach((user) => {
        const userData = user.data();
        opts.push({ value: user.id, label: `${userData.firstName} ${userData.lastName}` });
      });
      opts.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setOptions(opts);
    };
    getUsers();
  }, []);

  return (
    <Select
      value={userId}
      onChange={(value) => setUserId(value)}
      classNamePrefix="react-select"
      options={options}
      className="form-control"
      styles={{ container: (provided, state) => ({ ...provided, height: '40px', padding: '0px' }) }}
    />
  );
};

function AddUserToShiftModal({ open, setOpen, saveCallback }) {
  const toggle = () => setOpen(!open);

  const [userId, setUserId] = useState();
  const [shouldCheckDocuments, setShouldCheckDocuments] = useState(true);
  const params = useParams();
  const shiftId = params.id;

  const save = async () => {
    try {
      const success = await pickShift(userId.value, shiftId, shouldCheckDocuments);
      if (success) {
        saveCallback({ wasSuccessful: true, errMsg: '' });
        return;
      }
      saveCallback({
        wasSuccessful: false,
        errMsg:
          'Cannot add user to shift. User document check did not pass. Either add documents or uncheck box that requires documents.',
      });
    } catch (e) {
      saveCallback({ wasSuccessful: false, errMsg: e.message });
    }
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add user to shift</ModalHeader>
      <ModalBody>
        <FormGroup>
          <CustomInput
            id="shouldCheckDocuments"
            label="Should require documents"
            checked={shouldCheckDocuments}
            onChange={({ target }) => setShouldCheckDocuments(target.checked)}
            type="checkbox"
          />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="shiftUser">
            User
          </Label>
          <UserInput userId={userId} setUserId={setUserId} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => save()}>
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default function AddUserToShiftButton() {
  const [isOpen, setIsOpen] = useState(false);

  const saveCallback = (status) => {
    if (status.wasSuccessful) {
      window.location.reload();
    } else if (status.wasSuccessful === false) {
      toast.error(`There was an error creating shift: ${status.errMsg}`, { position: 'bottom-right' });
    }
  };

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        style={{ zoom: 0.9 }}
        className="px-0 btn btn-sm btn-link text-primary"
      >
        Add User
      </button>
      <AddUserToShiftModal open={isOpen} setOpen={setIsOpen} saveCallback={saveCallback} />
    </>
  );
}
