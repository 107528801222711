import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import { getShiftsByUid } from '../../redux/actions/userShiftActions';
import ExpiredFilter from '../common/ExpiredFilter';
import FalconCardHeader from '../common/FalconCardHeader';
import StartDateFilter from '../common/StartDateFilter';

const anchorFormatter = (description, { id }) => (
  <a href={`/shifts/${id}`}>
    <b>{description}</b>
  </a>
);

const badgeFormatter = (isFree, row) => {
  let color = '';
  let icon = '';
  let text = 'Is free';
  switch (isFree) {
    case true:
      color = 'secondary';
      text = 'Unclaimed';
      break;
    case false:
      color = 'success';
      icon = 'check';
      text = 'Claimed';
      break;
    default:
      color = 'warning';
      icon = 'ban';
      text = 'cancelled';
  }
  if (row.status === 'cancelled') {
    color = 'warning';
    icon = 'ban';
    text = 'Cancelled';
  }
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule">
      {text}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
    </Badge>
  );
};

const timeFormatter = (time) => <span>{time?.toDate().toLocaleString()}</span>;

function UserShiftsTable({ type, uid }) {
  const {
    shifts,
    loading,
    hasMore,
    field: stateField,
    order: stateOrder,
    filters,
  } = useSelector((state) => state.userShifts);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShiftsByUid(uid, type, filters, stateField, stateOrder));
    // adding deps will cause dupe fetches which can cause race conditions
    /* eslint-disable-next-line */
  }, [dispatch, type, uid]);

  const onSort = (field, order) => {
    if (field !== stateField || order !== stateOrder) {
      dispatch(getShiftsByUid(uid, type, filters, field, order));
    }
  };

  const columns = (userType) => {
    const description = {
      dataField: 'description',
      text: 'Description',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: anchorFormatter,
    };
    const start = {
      dataField: 'start',
      headerClasses: 'border-0',
      text: 'Start',
      classes: 'border-0 py-2 align-middle',
      align: 'right',
      formatter: timeFormatter,
      headerAlign: 'right',
      onSort,
      sort: true,
    };
    const end = {
      dataField: 'end',
      headerClasses: 'border-0',
      text: 'End',
      classes: 'border-0 py-2 align-middle',
      formatter: timeFormatter,
      align: 'right',
      headerAlign: 'right',
    };
    const isFree = {
      dataField: 'isFree',
      text: 'Is free',
      formatter: badgeFormatter,
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      align: 'center',
      headerAlign: 'center',
    };
    const nurseName = {
      dataField: 'nurseName',
      text: 'Nurse name',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      align: 'left',
    };
    const additionalNotes = {
      dataField: 'additionalnotes',
      text: 'Additional notes',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
    };
    if (userType === 'facility') {
      return [description, start, end, isFree, nurseName, additionalNotes];
    }
    return [description, start, end, additionalNotes];
  };

  return (
    <>
      <FalconCardHeader title="Shifts">
        <div className="d-flex">
          <ExpiredFilter
            onSubmit={(filter) => {
              dispatch(getShiftsByUid(uid, type, filter, stateField, stateOrder));
            }}
          />
          <StartDateFilter
            onSubmit={(filter) => {
              dispatch(getShiftsByUid(uid, type, filter, stateField, stateOrder));
            }}
          />
        </div>
      </FalconCardHeader>
      <CardBody>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={shifts}
          columns={columns(type)}
          sort={{ dataField: stateField, order: stateOrder }}
          bordered={false}
          classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
          loading={loading}
        />
        <Row noGutters className="px-1 py-3  d-flex justify-content-center">
          <Col xs="auto" className="pr-3">
            <Button
              size="sm"
              onClick={() => {
                dispatch(getShiftsByUid(uid, type, filters, stateField, stateOrder));
              }}
              className="px-4 ml-2 btn-light"
              disabled={!hasMore || loading}
            >
              {loading ? 'Loading...' : hasMore ? 'Load More' : 'No more items'}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </>
  );
}

export default UserShiftsTable;
