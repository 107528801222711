import React from 'react';
import { useState } from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ButtonIcon from './ButtonIcon';
import Datetime from 'react-datetime';
import moment from 'moment';
import { functions } from '../../firebase';
import { useDispatch } from 'react-redux';
import { getPayouts } from '../../redux/actions/payoutActions';
import { toast } from 'react-toastify';

function DateSelectModal({ open, setOpen }) {
  const toggle = () => setOpen(!open);

  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const dispatch = useDispatch();
  async function createTxrs() {
    const payoutCallByDates = functions.httpsCallable('payoutCallByDates');
    payoutCallByDates({
      start: startDate.toDate().toJSON(),
      end: endDate.toDate().toJSON(),
    })
      .then((res) => {
        console.log('payoutCallByDates', res);
        dispatch(getPayouts());
      })
      .catch((e) => {
        console.error('payoutCallByDates', e);
        toast('There was an error processing the request.', {
          type: 'error',
        });
      })
      .then(() => {
        toggle();
      });
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Filter Start Date between</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            From Date
          </Label>
          <Datetime
            timeFormat={true}
            value={startDate}
            onChange={(dateTime) => {
              if (dateTime._isValid) {
                setStartDate(dateTime);
              }
            }}
            dateFormat="MM-DD-YYYY"
            inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'eventStart' }}
          />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            End Date
          </Label>
          <Datetime
            timeFormat={true}
            value={endDate}
            onChange={(dateTime) => {
              if (dateTime._isValid) {
                setEndDate(dateTime);
              }
            }}
            dateFormat="MM-DD-YYYY"
            inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'eventEnd' }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => createTxrs()}>
          Create Transfers
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default function InstantPayoutButton({ data = [], fields = [] }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ButtonIcon
        onClick={() => {
          setIsOpen(true);
        }}
        icon="dollar-sign"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        className="ml-2"
      >
        Instant Payout
      </ButtonIcon>
      <DateSelectModal open={isOpen} setOpen={setIsOpen} />
    </>
  );
}
