import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import FalconCardHeader from './FalconCardHeader';
import _ from 'lodash';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { updateFacilityDataById } from '../../api/facility';

const AddTemplate = ({ onClick }) => {
  return (
    <Button style={{ marginRight: '10px' }} onClick={onClick} className="px-4">
      Add new shift template
    </Button>
  );
};

const FacilityShiftTemplateCard = ({ uid, facility, onUpdate }) => {
  const [shiftTemplates, setShiftTemplates] = useState([]);

  const convertDBTemplates = useCallback(
    (dbTemplate) => {
      return convertTz(
        (dbTemplate || []).map((t) => ({
          ...t,
          startTime: t.startTime.toDate(),
          endTime: t.endTime.toDate(),
        })),
        facility.tz,
        true
      );
    },
    [facility.tz]
  );

  useEffect(() => {
    if (facility.shiftTemplates) {
      const formattedShiftTemplates = convertDBTemplates(facility.shiftTemplates);
      setShiftTemplates(formattedShiftTemplates);
    }
  }, [convertDBTemplates, facility.shiftTemplates]);

  const convertToDate = (shiftTemplates) => {
    return shiftTemplates.map((template) => ({
      startTime: template.startTime.toDate(),
      endTime: template.endTime.toDate(),
      name: template.name,
    }));
  };

  const convertTz = (shiftTemplates, tz, changeTime) => {
    return shiftTemplates.map((template) => {
      const startTime = moment(template.startTime).tz(tz, !changeTime);
      const endTime = moment(template.endTime).tz(tz, !changeTime);
      return {
        startTime,
        endTime,
        name: template.name,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      await updateFacilityDataById(
        { shiftTemplates: convertToDate(convertTz(shiftTemplates, facility.tz, false)) },
        uid
      );
      await onUpdate();
      toast.success('Successfully updated shift templates');
    } catch (e) {
      toast.error('Failed to save shift template changes');
    }
  };

  const updateShiftTemplateState = ({ value, field, index }) => {
    const newShiftTemplates = _.cloneDeep(shiftTemplates);
    newShiftTemplates[index][field] = value;
    setShiftTemplates(newShiftTemplates);
  };

  const removeItem = (index) => {
    const newShiftTemplates = _.cloneDeep(shiftTemplates);
    newShiftTemplates.splice(index, 1);
    setShiftTemplates(newShiftTemplates);
  };

  const onAddTemplateClick = () => {
    const newShiftTemplates = _.cloneDeep(shiftTemplates);
    newShiftTemplates.push({
      name: '',
      endTime: '',
      startTime: '',
    });
    setShiftTemplates(newShiftTemplates);
  };

  const touched =
    shiftTemplates.length &&
    !_.isEqual(shiftTemplates, convertDBTemplates(facility.shiftTemplates)) &&
    shiftTemplates.every((template) => {
      return template.name && template.endTime && template.startTime;
    });

  return (
    <Card className="mt-3">
      <FalconCardHeader title="Shift Templates">
        <div className="d-flex">
          <AddTemplate onClick={onAddTemplateClick} />
          <Button disabled={!touched} onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </FalconCardHeader>
      <CardBody>
        {!!shiftTemplates.length && <p>{`All times are in facility timezone (${facility.tz})`}</p>}
        <Form onSubmit={handleSubmit}>
          {shiftTemplates.map(({ name, startTime, endTime }, index) => {
            return (
              <div
                key={index}
                style={{
                  borderRadius: '5px',
                  border: '1px solid #00000014',
                  padding: '10px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  position: 'relative',
                  boxShadow: '0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%)',
                }}
                className="bg-light"
              >
                <button
                  style={{ border: 'none', background: 'none' }}
                  onClick={(e) => {
                    e.preventDefault();
                    removeItem(index);
                  }}
                >
                  <FontAwesomeIcon
                    icon="times"
                    transform="shrink-4 down-1"
                    className="mr-1"
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                    }}
                  />
                </button>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    value={name}
                    onChange={({ target }) => updateShiftTemplateState({ value: target.value, field: 'name', index })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Start Time</Label>
                  <Datetime
                    timeFormat={true}
                    value={startTime}
                    onChange={(startTime) => {
                      if (startTime._isValid) {
                        updateShiftTemplateState({
                          value: startTime.toDate(),
                          field: 'startTime',
                          index,
                        });
                      }
                    }}
                    dateFormat={false}
                    inputProps={{ placeholder: 'H:mm', id: 'startTime' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>End Time</Label>
                  <Datetime
                    timeFormat={true}
                    value={endTime}
                    onChange={(endTime) => {
                      if (endTime._isValid) {
                        updateShiftTemplateState({
                          value: endTime.toDate(),
                          field: 'endTime',
                          index,
                        });
                      }
                    }}
                    dateFormat={false}
                    inputProps={{ placeholder: 'H:mm', id: 'endTime' }}
                  />
                </FormGroup>
              </div>
            );
          })}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FacilityShiftTemplateCard;
