import { auth } from '../../firebase';
import { USER_EMAIL_LOGIN_FAIL, USER_EMAIL_LOGIN_REQUEST, USER_EMAIL_LOGIN_SUCCESS } from '../constants/userConstants';
import { toast } from 'react-toastify';
import { getUserData } from '../../api/user';
import { UserType } from '../../constants';

// The login with email action
export const startEmailLogin = (email, password) => async (dispatch) => {
  dispatch({
    type: USER_EMAIL_LOGIN_REQUEST,
  });
  try {
    const { user } = await auth.signInWithEmailAndPassword(email, password);
    const profileDoc = await getUserData(user.uid);
    if (
      profileDoc.data().isAdmin &&
      profileDoc.data().userType === UserType.ADMIN &&
      profileDoc.data().status === 'active'
    ) {
      dispatch(emailLogin(user.uid, user.displayName));
      toast.success(`Logged in as ${profileDoc.data().firstName} ${profileDoc.data().lastName}`, {
        hideProgressBar: true,
      });
    } else {
      auth.signOut();
      toast.error('Only active admin users can login', { hideProgressBar: true });
    }
  } catch (error) {
    console.log(error);
    toast.error(`Invalid email and/or password`, { hideProgressBar: true });
    dispatch({
      type: USER_EMAIL_LOGIN_FAIL,
      payload: error.message,
    });
  }
};

export const emailLogin = (uid, displayName) => ({
  type: USER_EMAIL_LOGIN_SUCCESS,
  payload: {
    uid,
    displayName,
  },
});
