import {
  USER_SHIFT_LIST_FAIL,
  USER_SHIFT_LIST_REQUEST,
  USER_SHIFT_LIST_SUCCESS,
  USER_SHIFT_HAS_MORE,
} from '../constants/userShiftConstants';

const initialState = {
  shifts: [],
  filters: [],
  field: 'start',
  order: 'desc',
  loading: false,
  error: false,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_SHIFT_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case USER_SHIFT_LIST_SUCCESS:
      let shifts = [];
      if (payload.empty) {
        shifts = [...payload.shifts];
      } else {
        shifts = [...state.shifts, ...payload.shifts];
      }
      return { ...payload, shifts, loading: false };
    case USER_SHIFT_LIST_FAIL:
      return { ...state, loading: false, error: true };
    case USER_SHIFT_HAS_MORE:
      return { ...state, hasMore: payload };
    default:
      return state;
  }
};
