import { MouseEventHandler, useContext, useEffect, useState } from 'react';

import Loader from '../common/Loader';
import { getAllFacilities, getFacilityData, updateFacilityDataById } from '../../api/facility';
import { Facility, NurseType } from '../../types';
import Card from '../common/chakra/Card';
import { Button, Select, Checkbox, CheckboxGroup } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/modal';
import { Stack, Container } from '@chakra-ui/layout';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/form-control';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TestContext } from '../../context/Context';
import { toast } from 'react-toastify';

type HotMarketProps = {
  hotMarketList: Array<HotMarketType>;
  updateHotMarket: (data: FormValues) => void;
  loading: boolean;
  fetchHotMarketForFacility: (facilityId: string) => Promise<NurseType[]>;
  refreshHotMarkets: () => void;
  facilityOptions: { label: string; value: string }[];
};

interface EditButtonProps {
  onClick: MouseEventHandler;
}

const EditButton = ({ onClick }: EditButtonProps) => {
  return <Button onClick={onClick}>Edit</Button>;
};

type FormValues = {
  facilityId: string;
  positions: NurseType[];
};
interface EditHotMarketModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: SubmitHandler<FormValues>;
  fetchHotMarketForFacility: (facilityId: string) => Promise<NurseType[]>;
  facilityOptions: { label: string; value: string }[];
}

const EditHotMarketModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  fetchHotMarketForFacility,
  facilityOptions,
}: EditHotMarketModalProps) => {
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm();
  watch(async (data: any, { name, type }) => {
    if (name === 'facilityId' && type === 'change') {
      const existingNurseTypes = await fetchHotMarketForFacility(data.facilityId);
      setValue('positions', existingNurseTypes);
    }
  });
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add or Edit Hot Market</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing={4} align="stretch" mb={4}>
              <Container>
                <FormControl isInvalid={Boolean(errors.facilityId)}>
                  <FormLabel htmlFor="facilityId">Choose facility</FormLabel>
                  <Controller
                    rules={{
                      required: 'Facility is required',
                    }}
                    render={({ field }) => (
                      <Select {...field}>
                        {facilityOptions.map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.label}
                          </option>
                        ))}
                      </Select>
                    )}
                    name="facilityId"
                    control={control}
                  />
                  <FormErrorMessage>{errors.facilityId && errors.facilityId.message}</FormErrorMessage>
                </FormControl>
              </Container>
              <Container>
                <FormControl isInvalid={Boolean(errors.positions)}>
                  <FormLabel htmlFor="positions">Set positions</FormLabel>
                  <Controller
                    render={({ field }) => {
                      return (
                        <CheckboxGroup colorScheme="green" defaultValue={['naruto', 'kakashi']}>
                          <Stack>
                            {Object.values(NurseType)
                              .filter((value) => typeof value === 'string')
                              .map((nurseType) => (
                                <Checkbox
                                  onChange={(e) => {
                                    const value = field.value || [];
                                    if (e.target.checked) {
                                      field.onChange([...value, e.target.id]);
                                    } else {
                                      const index = value.indexOf(e.target.id);
                                      field.onChange(value.filter((_: any, i: number) => i !== index));
                                    }
                                  }}
                                  isChecked={(field.value || []).includes(nurseType)}
                                  key={nurseType}
                                  id={nurseType}
                                >
                                  {nurseType}
                                </Checkbox>
                              ))}
                          </Stack>
                        </CheckboxGroup>
                      );
                    }}
                    name="positions"
                    control={control}
                  />
                  <FormErrorMessage>{errors.positions && errors.positions.message}</FormErrorMessage>
                </FormControl>
              </Container>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => setIsOpen(false)} variant="ghost">
              Close
            </Button>
            <Button colorScheme="blue" mr={3} type="submit">
              Edit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const HotMarkets = ({
  hotMarketList,
  updateHotMarket,
  loading,
  fetchHotMarketForFacility,
  facilityOptions,
  refreshHotMarkets,
}: HotMarketProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  if (loading) {
    return <Loader />;
  }
  const onEditClick: MouseEventHandler = (event) => {
    setIsEditModalOpen(true);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      await updateHotMarket(data);
      await refreshHotMarkets();
      setIsEditModalOpen(false);
    } catch (e) {
      toast.error('Failed to update hot market');
    }
  };

  return (
    <>
      <Card titleText="Hot Markets" titleButtons={[<EditButton onClick={onEditClick} key={0} />]}>
        {hotMarketList.map(({ facilityName, hotPositions, facilityId }) => (
          <Card
            key={facilityId}
            titleText={facilityName}
            titleProps={{ as: 'h5', size: 'sm' }}
            boxProps={{ boxShadow: 'lg', bg: '#f2f3fc', py: 5, px: 5 }}
            centerProps={{ py: 3 }}
          >
            <span>{`${hotPositions.join(', ')}`}</span>
          </Card>
        ))}
      </Card>
      <EditHotMarketModal
        fetchHotMarketForFacility={fetchHotMarketForFacility}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        onSubmit={onSubmit}
        facilityOptions={facilityOptions}
      />
    </>
  );
};

export type HotMarketType = {
  facilityName: string;
  facilityId: string;
  hotPositions: Array<NurseType>;
};

const HotMarketsWrapper = () => {
  const { isTest } = useContext(TestContext);
  const [hotMarketList, setHotMarketList] = useState<Array<HotMarketType>>([]);
  const [facilityOptions, setFacilityOptions] = useState<{ label: string; value: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const updateHotMarket = ({ facilityId, positions }: FormValues) => {
    return updateFacilityDataById({ hotMarket: { nurseTypes: positions } }, facilityId);
  };
  const fetchHotMarketForFacility = async (facilityId: string) => {
    const { hotMarket } = (await getFacilityData(facilityId)) as Facility;
    if (!hotMarket) {
      return [];
    }
    return hotMarket.nurseTypes;
  };
  const getHotMarkets = async () => {
    setLoading(true);
    const facilities = await getAllFacilities(isTest);
    const facilityOptions = facilities.docs
      .map((facility) => ({
        label: facility.data().facilityName,
        value: facility.id,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1));
    setFacilityOptions(facilityOptions);
    const hotMarkets: { [facilityId: string]: HotMarketType } = facilities.docs.reduce((acc, facility) => {
      const { hotMarket, facilityName } = facility.data();
      if (!hotMarket || !hotMarket.nurseTypes.length) {
        return acc;
      }
      return {
        ...acc,
        [facility.id]: {
          facilityName,
          hotPositions: hotMarket.nurseTypes,
          facilityId: facility.id,
        },
      };
    }, {});
    setHotMarketList(Object.values(hotMarkets).sort((a, b) => (a.facilityName < b.facilityName ? -1 : 1)));
    setLoading(false);
  };

  useEffect(() => {
    getHotMarkets();
  }, [isTest]);
  return (
    <HotMarkets
      fetchHotMarketForFacility={fetchHotMarketForFacility}
      hotMarketList={hotMarketList}
      updateHotMarket={updateHotMarket}
      refreshHotMarkets={getHotMarkets}
      loading={loading}
      facilityOptions={facilityOptions}
    />
  );
};

export default HotMarketsWrapper;
