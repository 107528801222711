import React, { useState } from 'react';
import ReactDatetime from 'react-datetime';
import { Input, InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import ButtonIcon from './ButtonIcon';

function DataFilter({ columns, onSubmit }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState();
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const [query, setQuery] = useState('');
  const [fromQuery, setFromQuery] = useState('');
  const [toQuery, setToQuery] = useState('');
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (selectedFilter.type === 'string') {
          onSubmit([[selectedFilter.filter, '==', query]]);
        } else if (selectedFilter.type === 'date') {
          if (selectedFilter.conversion) {
            onSubmit([
              [selectedFilter.filter, '>=', selectedFilter.conversion(fromQuery.toDate())],
              [selectedFilter.filter, '<=', selectedFilter.conversion(toQuery.toDate())],
            ]);
          } else {
            onSubmit([
              [selectedFilter.filter, '>=', fromQuery.toDate()],
              [selectedFilter.filter, '<=', toQuery.toDate()],
            ]);
          }
        }
      }}
    >
      <InputGroup style={{ zoom: 0.9 }}>
        <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
          <DropdownToggle caret>{selectedFilter?.label || 'Filters'}</DropdownToggle>
          <DropdownMenu>
            {columns.flatMap((column, index) => (
              <DropdownItem
                key={index}
                onClick={() => {
                  setSelectedFilter(column);
                  if (column.type === 'custom') {
                    onSubmit(column.customFilter);
                  }
                }}
              >
                {column.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </InputGroupButtonDropdown>
        {selectedFilter?.type === 'string' ? (
          <Input
            placeholder="Query..."
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        ) : selectedFilter?.type === 'date' ? (
          <>
            <ReactDatetime
              timeFormat={true}
              value={fromQuery}
              onChange={(dateTime) => {
                if (dateTime._isValid) {
                  setFromQuery(dateTime);
                }
              }}
              dateFormat="MM-DD-YYYY"
              inputProps={{ required: true, placeholder: 'From', id: 'eventStart' }}
            />
            <ReactDatetime
              timeFormat={true}
              value={toQuery}
              onChange={(dateTime) => {
                if (dateTime._isValid) {
                  setToQuery(dateTime);
                }
              }}
              placeholder="To"
              dateFormat="MM-DD-YYYY"
              inputProps={{ required: true, placeholder: 'To', id: 'eventStart' }}
            />
          </>
        ) : (
          <> </>
        )}
        <ButtonIcon icon="search" />
      </InputGroup>
    </form>
  );
}
export default DataFilter;
