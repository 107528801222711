import { NURSE_LIST_FAIL, NURSE_LIST_REQUEST, NURSE_LIST_SUCCESS, NURSE_HAS_MORE } from '../constants/nurseConstants';

const initialState = {
  nurses: [],
  loading: false,
  error: false,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NURSE_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case NURSE_LIST_SUCCESS:
      let nurses = [];
      if (payload.empty || state.isTest !== payload.isTest) {
        nurses = [...payload.nurses];
      } else {
        nurses = [...state.nurses, ...payload.nurses];
      }
      return { nurses, loading: false, isTest: payload.isTest };
    case NURSE_LIST_FAIL:
      return { ...state, loading: false, error: true };
    case NURSE_HAS_MORE:
      return { ...state, hasMore: payload };
    default:
      return state;
  }
};
