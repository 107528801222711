import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { states } from '../../constants';
import CustomerDetailRow from './CustomerDetailRow';

interface UpdateUserData {
  licensedStates: string[];
}

interface LicensedStatesProps {
  licensedStates: string[];
  homeState: string;
  onSave: (data: UpdateUserData) => void;
  onComplete: () => void;
}

type FormValues = {
  licensedStates: string[];
};

export const LicensedStates = ({ licensedStates, onSave, onComplete, homeState }: LicensedStatesProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({ defaultValues: { licensedStates } });
  const toast = useToast();
  const onSubmit = async (data: FormValues) => {
    try {
      await onSave(data);
      setIsOpenModal(false);
      await onComplete();
    } catch (e) {
      toast({
        title: 'Failed to update licensed states',
        status: 'error',
      });
    }
  };
  const onClose = () => setIsOpenModal(false);
  return (
    <>
      <CustomerDetailRow
        title="Licensed States"
        text={
          <>
            <span>{licensedStates.join(', ')}</span>
            <button
              style={{
                background: 'none',
                color: 'inherit',
                border: 'none',
                padding: '0',
                font: 'inherit',
                cursor: 'pointer',
                outline: 'inherit',
                marginLeft: '10px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
              color="primary"
              onClick={() => setIsOpenModal(true)}
            >
              <FontAwesomeIcon icon="edit" className="mr-1" />
            </button>
          </>
        }
      />
      <Modal isOpen={isOpenModal} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Licensed States</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl isInvalid={Boolean(errors.licensedStates)}>
                <FormLabel htmlFor="licensedStates">Licensed States</FormLabel>
                <Controller
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <CheckboxGroup colorScheme="green">
                        <Stack>
                          {states.map((state) => (
                            <Checkbox
                              isDisabled={state === homeState}
                              onChange={(e) => {
                                const value = field.value || [];
                                if (e.target.checked) {
                                  field.onChange([...value, e.target.id]);
                                } else {
                                  const index = value.indexOf(e.target.id as string);
                                  field.onChange(value.filter((_: any, i: number) => i !== index));
                                }
                              }}
                              isChecked={(field.value || []).includes(state)}
                              key={state}
                              id={state}
                            >
                              {state}
                            </Checkbox>
                          ))}
                        </Stack>
                      </CheckboxGroup>
                    );
                  }}
                  name="licensedStates"
                  control={control}
                />
                <FormErrorMessage>{errors.licensedStates && 'At least one position must be allowed'}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} variant="ghost">
                Close
              </Button>
              <Button colorScheme="blue" mr={3} type="submit">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
