import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
  Modal,
} from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FalconCardHeader from './FalconCardHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import { getUserData, updateUserDataById } from '../../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { firebase } from '../../firebase';
import Input from 'reactstrap/lib/Input';
import { updateFacilityDataById } from '../../api/facility';

const anchorFormatter = (fullName, { id }) => (
  <a rel="noopener noreferrer" target="_blank" href={`/users/${id}`}>
    <b>{fullName}</b>
  </a>
);

const getFormattedEvents = (blockedEvents) => {
  return (blockedEvents || []).reduce((acc, evt) => {
    if (evt.action === 'blocked') {
      acc[evt.nurseId] = {
        reason: evt.reason,
        blockedAt: evt.timestamp,
      };
    }
    return acc;
  }, {});
};

export const removeFromBlockList = async ({ facilityId, employeeId, reason }) => {
  const now = new Date();
  await updateFacilityDataById(
    {
      blocklist: firebase.firestore.FieldValue.arrayRemove(employeeId),
      blocklistEvents: firebase.firestore.FieldValue.arrayUnion({
        timestamp: now,
        nurseId: employeeId,
        reason,
        action: 'unblocked',
      }),
    },
    facilityId
  );

  await updateUserDataById(
    {
      blocklistedBy: firebase.firestore.FieldValue.arrayRemove(facilityId),
      blocklistedByEvents: firebase.firestore.FieldValue.arrayUnion({
        timestamp: now,
        facilityId,
        reason,
        action: 'unblocked',
      }),
    },
    employeeId
  );
};

const UnblockEmployeeModal = ({ setIsOpen, isOpen, employeeName, uid, unblockEmployee }) => {
  const toggle = () => setIsOpen(!isOpen);
  const [reason, setReason] = useState('');

  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader close={closeBtn}>{`Unblock worker`}</ModalHeader>
      <ModalBody>
        <p>{`Why do you want to unblock ${employeeName}?`}</p>
        <Input onChange={(e) => setReason(e.target.value)} value={reason} />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={async () => {
            if (!reason) {
              toast.error('Reason is required');
              return;
            }
            await unblockEmployee(uid, employeeName, reason);
            setIsOpen(false);
          }}
          disabled={!reason}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const BlockedEmployeesTableActions = ({ unblockEmployee }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>

      <DropdownMenu right className="border py-2">
        <DropdownItem onClick={() => unblockEmployee()}>Unblock</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const BlockedEmployeesTable = ({ blockedEmployees, blockedEvents, uid, onUpdate }) => {
  const [blockedEmployeeData, setBlockedEmployeeData] = useState();
  const [employeeToUnblock, setEmployeeToUnblock] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const blockedDetails = getFormattedEvents(blockedEvents);

  const unblockEmployee = async (id, employeeName, reason) => {
    try {
      await removeFromBlockList({ facilityId: uid, employeeId: id, reason });
      onUpdate();
      toast.success(`${employeeName} has been unblocked and is eligible to pick future shifts`);
    } catch (e) {
      toast.error('Failed to unblock employee');
    }
  };
  useEffect(() => {
    const updateEmployees = async () => {
      setLoading(true);
      const employeePromises = blockedEmployees.map((eeID) => {
        return new Promise(async (resolve) => {
          const snap = await getUserData(eeID);
          const fullName = `${snap.data().firstName.trim()} ${snap.data().lastName.trim()}`;

          const eeBlockedDetails = blockedDetails[eeID] || { reason: null, blockedAt: null };
          resolve({ ...snap.data(), id: eeID, fullName, ...eeBlockedDetails });
        });
      });
      const employees = await Promise.all(employeePromises);
      setBlockedEmployeeData(employees);
      setLoading(false);
    };
    updateEmployees();
  }, [blockedDetails, blockedEmployees]);

  const actionFormatter = (uid, row) => (
    <BlockedEmployeesTableActions
      unblockEmployee={() => {
        setEmployeeToUnblock(row);
        setIsOpen(true);
      }}
    />
  );

  const timeFormatter = (time) => <span>{time ? time?.toDate().toLocaleString() : null}</span>;

  const columns = [
    {
      dataField: 'fullName',
      text: 'Employee Name',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: anchorFormatter,
    },
    {
      dataField: 'nurseType',
      text: 'Position',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
    },
    {
      dataField: 'blockedAt',
      text: 'Blocked At',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: 'reason',
      text: 'Reason',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: 'id',
      text: '',
      headerClasses: 'border-0',
      formatter: actionFormatter,
      classes: 'border-0 py-2 align-right',
      align: 'right',
    },
  ];

  const paginationProps = (blockedEmployeeData || []).length > 10 ? { pagination: paginationFactory() } : {};

  return (
    <>
      <Card className="mt-3">
        <FalconCardHeader title="Blocked Employees" light={false} />
        <CardBody className="p-0">
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={blockedEmployeeData || []}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                loading={loading}
                {...paginationProps}
              />
            </div>
          </Fragment>
        </CardBody>
      </Card>
      <UnblockEmployeeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        uid={employeeToUnblock?.id}
        employeeName={employeeToUnblock?.fullName}
        unblockEmployee={unblockEmployee}
      />
    </>
  );
};

export default BlockedEmployeesTable;
