import { Col } from 'reactstrap';
import { addOnMapper } from '../../helpers/utils';
import { BillRate } from '../../types';
import { CustomerDetailRow } from './ShiftDetail';
import Divider from '../common/Divider';

interface FacilityBillRateProps {
  billRate: BillRate;
}

const FacilityBillRate = ({ billRate }: FacilityBillRateProps) => {
  let hoursText = '';
  if (billRate.hours) {
    const hours = Math.floor(billRate.hours);
    const hoursLabel = hours === 1 ? 'hour' : 'hours';

    const minutes = Math.round((billRate.hours - Math.floor(billRate.hours)) * 60);
    const minutesLabel = minutes === 1 ? 'minute' : 'minutes';
    const minutesText = minutes === 0 ? '' : ` and ${minutes} ${minutesLabel}`;

    hoursText = `${hours} ${hoursLabel}` + minutesText;
  }
  return (
    <>
      <Col lg className="col-xxl-5">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h6 className="font-weight-semi-bold ls fs--1 mb-3 text-uppercase">Bill Rate</h6>
        </div>
        <CustomerDetailRow isLastItem={false} title="Base Rate" text={`$${billRate.baseRate.toFixed(2)} per hour`} />
        {billRate.addOns.map((addOn) => (
          <CustomerDetailRow
            key={addOn.value}
            isLastItem={false}
            title={addOnMapper(addOn.type).title}
            text={`$${addOn.value.toFixed(2)}${addOn.rateType === 'perShift' ? '' : ' per hour'}`}
          />
        ))}
        <Divider />
        <CustomerDetailRow isLastItem={false} title="Total Rate" text={`$${billRate.totalRate} per hour`} />
        {billRate.extraBonus && (
          <CustomerDetailRow
            isLastItem={false}
            title="Extra Bonus"
            text={`$${(billRate.extraBonus / 100).toFixed(2)}`}
          />
        )}
        {(billRate.hours || billRate.totalAmount) && <Divider />}
        {billRate.hours && <CustomerDetailRow isLastItem={false} title="Hours Worked" text={hoursText} />}
        {billRate.totalAmount && (
          <CustomerDetailRow
            isLastItem={false}
            title="Total Amount"
            text={`$${(billRate.totalAmount / 100).toFixed(2)}`}
          />
        )}
      </Col>
    </>
  );
};

export default FacilityBillRate;
