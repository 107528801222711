import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import { auth, analytics } from '../firebase';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import loadable from '@loadable/component';
import { useDispatch } from 'react-redux';
import { emailLogin } from '../redux/actions/loginWithEmailAction';
import Loader from '../components/common/Loader';
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const Landing = loadable(() => import('../components/landing/Landing'));
const WizardLayout = loadable(() => import('../components/auth/wizard/WizardLayout'));
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));
const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));

const Layout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
    Landing.preload();
    WizardLayout.preload();
    AuthCardRoutes.preload();
    AuthSplitRoutes.preload();
  }, []);
  const dispatch = useDispatch();

  const [checking, setChecking] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user?.uid) {
        analytics.setUserId(user.uid);
        dispatch(emailLogin(user.uid, user.displayName));
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setChecking(false);
    });
    return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting.
  }, [dispatch, setChecking, setIsLoggedIn]);

  if (checking) {
    return <Loader />;
  }
  console.log('Layout');
  console.log({ isLoggedIn });

  return (
    <>
      <Routes>
        <Route
          path="/authentication/basic/*"
          element={<PublicRoute isAuthenticated={isLoggedIn} component={AuthBasicLayout} />}
        />
        <Route path="*" element={<PrivateRoute isAuthenticated={isLoggedIn} />} />
      </Routes>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </>
  );
};

export default Layout;
