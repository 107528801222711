import { getUserRef } from '../../api/user';
import { UserType } from '../../constants';
import {
  APPLICANT_HAS_MORE,
  APPLICANT_LIMIT,
  APPLICANT_LIST_FAIL,
  APPLICANT_LIST_REQUEST,
  APPLICANT_LIST_SUCCESS,
} from '../constants/applicantConstants';

export const getApplicants = (filters, first, isTest) => async (dispatch, getState) => {
  dispatch({
    type: APPLICANT_LIST_REQUEST,
  });
  try {
    let applicantsSnap;
    let collection = getUserRef()
      .where('status', '==', 'inactive')
      .where('userType', '==', UserType.WORKER)
      .where('isTest', '==', isTest);
    const orders = new Set();
    filters.forEach((filter) => {
      collection = collection.where(...filter);
      if (filter[1] !== '==') orders.add(filter[0]);
    });
    const { applicants, isTest: stateIsTest } = getState().applicant;
    orders.forEach((order) => {
      collection = collection.orderBy(order);
    });
    collection = filters.find((f) => f[0] === 'firstName')
      ? collection.orderBy('lastName')
      : collection.orderBy('firstName');
    orders.forEach((order) => {
      if (applicants.length && applicants[applicants.length - 1] && !first && isTest === stateIsTest)
        collection = collection.startAfter(applicants[applicants.length - 1][order]);
    });
    collection.where('status', '==', 'inactive');
    if (applicants.length && applicants[applicants.length - 1] && !first && isTest === stateIsTest) {
      if (orders.size === 0) {
        collection = filters.find((f) => f[0] === 'firstName')
          ? collection.startAfter(applicants[applicants.length - 1].lastName)
          : collection.startAfter(applicants[applicants.length - 1].firstName);
        applicantsSnap = await collection.limit(APPLICANT_LIMIT).get();
      } else {
        applicantsSnap = await collection.limit(APPLICANT_LIMIT).get();
      }
    } else {
      applicantsSnap = await collection.limit(APPLICANT_LIMIT).get();
    }
    let applicantsArr = [];
    applicantsSnap.forEach((snapChild) => {
      const data = {
        ...snapChild.data(),
        id: snapChild.id,
      };
      applicantsArr.push(data);
    });
    dispatch({
      type: APPLICANT_LIST_SUCCESS,
      payload: {
        applicants: applicantsArr,
        isTest,
        empty: first,
      },
    });
    if (applicantsArr.length < APPLICANT_LIMIT) {
      dispatch({
        type: APPLICANT_HAS_MORE,
        payload: false,
      });
    } else {
      dispatch({
        type: APPLICANT_HAS_MORE,
        payload: true,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: APPLICANT_LIST_FAIL,
    });
  }
};
