import { Collection } from '../../constants';
import { db } from '../../firebase';
import {
  PAYOUT_EDIT,
  PAYOUT_HAS_MORE,
  PAYOUT_LIMIT,
  PAYOUT_LIST_FAIL,
  PAYOUT_LIST_REQUEST,
  PAYOUT_LIST_SUCCESS,
} from '../constants/payoutConstants';

export const getPayouts = (isTest) => async (dispatch, getState) => {
  dispatch({
    type: PAYOUT_LIST_REQUEST,
  });
  try {
    let payoutSnap;
    const { payouts } = getState().payout;
    if (payouts.length && payouts[payouts.length - 1]) {
      payoutSnap = await db
        .collection(Collection.PAYOUTS)
        .where('isTest', '==', isTest)
        .orderBy('invoice.created', 'desc')
        .startAfter(payouts[payouts.length - 1].invoice.created)
        .limit(PAYOUT_LIMIT)
        .get();
    } else {
      payoutSnap = await db
        .collection(Collection.PAYOUTS)
        .where('isTest', '==', isTest)
        .orderBy('invoice.created', 'desc')
        .limit(PAYOUT_LIMIT)
        .get();
    }
    const payoutsArr = [];
    payoutSnap.forEach((snapChild) => {
      const data = { ...snapChild.data(), id: snapChild.id };
      payoutsArr.push(data);
    });
    dispatch({
      type: PAYOUT_LIST_SUCCESS,
      payload: payoutsArr,
    });
    if (payoutsArr.length < PAYOUT_LIMIT) {
      dispatch({
        type: PAYOUT_HAS_MORE,
        payload: false,
      });
    } else {
      dispatch({
        type: PAYOUT_HAS_MORE,
        payload: true,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: PAYOUT_LIST_FAIL,
    });
  }
};

export const updatePayout = (payout) => async (dispatch) => {
  await db.collection(Collection.PAYOUTS).doc(payout.id).update({ status: payout.status });
  dispatch({
    type: PAYOUT_EDIT,
    payload: payout,
  });
};
