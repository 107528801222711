import { Collection } from '../../constants';
import { db } from '../../firebase';
import {
  DISCONTENT_HAS_MORE,
  DISCONTENT_LIMIT,
  DISCONTENT_LIST_FAIL,
  DISCONTENT_LIST_REQUEST,
  DISCONTENT_LIST_SUCCESS,
} from '../constants/discontentConstants';

export const getDiscontents = () => async (dispatch, getState) => {
  dispatch({
    type: DISCONTENT_LIST_REQUEST,
  });
  try {
    let discontentsSnap;
    const { discontents } = getState().discontent;
    if (discontents.length && discontents[discontents.length - 1]) {
      discontentsSnap = await db
        .collection(Collection.SHIFTDISCONTENTS)
        .startAfter(discontents[discontents.length - 1].time)
        .limit(DISCONTENT_LIMIT)
        .get();
    } else {
      discontentsSnap = await db.collection(Collection.SHIFTDISCONTENTS).limit(DISCONTENT_LIMIT).get();
    }
    const discontentsArr = [];
    discontentsSnap.forEach((snapChild) => {
      const data = {
        ...snapChild.data(),
        id: snapChild.id,
      };
      discontentsArr.push(data);
    });
    dispatch({
      type: DISCONTENT_LIST_SUCCESS,
      payload: {
        discontents: discontentsArr,
      },
    });
    if (discontentsArr.length < DISCONTENT_LIMIT) {
      dispatch({
        type: DISCONTENT_HAS_MORE,
        payload: false,
      });
    } else {
      dispatch({
        type: DISCONTENT_HAS_MORE,
        payload: true,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: DISCONTENT_LIST_FAIL,
    });
  }
};
