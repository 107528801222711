import React, { Fragment, useContext, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Badge from 'reactstrap/es/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap/lib';
import FalconCardHeader from '../common/FalconCardHeader';
import { getPayouts } from '../../redux/actions/payoutActions';
import { useNavigate } from 'react-router';
import PayoutExportButton from '../common/PayoutExportButton';
import InstantPayoutButton from '../common/InstantPayoutButton';
import PayoutTableActions from './PayoutTableActions';
import { TestContext } from '../../context/Context';

const actionFormatter = (_, payout) => <PayoutTableActions payout={payout} />;

const anchorFormatter = (id) => (
  <a href={'/invoices/' + id}>
    <b>{id}</b>
  </a>
);

const shiftFormatter = (id, { shift }) => (
  <a href={'/shifts/' + id}>
    <b>{shift.description}</b>
  </a>
);

const facilityFormatter = (_, { shift, facility }) => (
  <a href={'/facilities/' + shift.facilityIdentifier}>
    <b>{facility.facilityName}</b>
  </a>
);

const nurseFormatter = (_, { nurse }) => (
  <>
    {nurse.firstName} {nurse.lastName}
  </>
);

const badgeFormatter = (status) => {
  let color = '';
  let icon = '';
  switch (status) {
    case 'paid':
      color = 'success';
      icon = 'check';
      break;
    case 'unpaid':
      color = 'secondary';
      icon = 'ban';
      break;
    default:
      color = 'warning';
      icon = 'stream';
  }
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule fs--2 text-capitalize">
      {status}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
    </Badge>
  );
};

const amountFormatter = (amount) => <Fragment>${amount / 100}</Fragment>;

const dateFormatter = (date) => <Fragment>{moment.unix(date).toLocaleString()}</Fragment>;

const columns = [
  {
    dataField: 'facility.facilityName',
    text: 'Facility',
    formatter: facilityFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'nurse.firstName',
    text: 'Nurse',
    formatter: nurseFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'invoice.id',
    text: 'Invoice',
    formatter: anchorFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'shiftId',
    text: 'Shift',
    formatter: shiftFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'invoice.created',
    formatter: dateFormatter,
    text: 'Invoice Date',
    // formatter: customerFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: badgeFormatter,
    classes: 'border-0 py-2 align-middle fs-0',
    headerClasses: 'border-0',
    // sort: true
  },
  {
    dataField: 'payoutAmount',
    text: 'Amount',
    formatter: amountFormatter,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    // sort: true,
    align: 'right',
    headerAlign: 'right',
  },
  {
    dataField: 'actions',
    isDummyField: true,
    classes: 'border-0 py-2 align-middle',
    headerClasses: 'border-0',
    text: '',
    formatter: actionFormatter,
  },
];

const Payouts = ({ showLoadMore = true }) => {
  const { payouts, loading, hasMore } = useSelector((state) => state.payout);
  const history = useNavigate();
  const dispatch = useDispatch();
  const { isTest } = useContext(TestContext);
  useEffect(() => {
    dispatch(getPayouts(isTest));
  }, [dispatch, isTest]);

  return (
    <Card>
      <FalconCardHeader title="Payouts">
        <InstantPayoutButton />
        <PayoutExportButton />
      </FalconCardHeader>
      <CardBody className="fs--1 p-0">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={showLoadMore ? payouts : payouts.slice(0, 5)}
          columns={columns}
          bordered={false}
          classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
        />
        <Row noGutters className="px-1 py-3  d-flex justify-content-center">
          <Col xs="auto" className="pr-3">
            {showLoadMore ? (
              <Button
                size="sm"
                onClick={() => {
                  dispatch(getPayouts(isTest));
                }}
                className="px-4 ml-2 btn-light"
                disabled={!hasMore || loading}
              >
                {loading ? 'Loading...' : hasMore ? 'Load More' : 'No more items'}
              </Button>
            ) : (
              <Button
                size="sm"
                onClick={() => {
                  history.push('/payouts');
                }}
                className="px-4 ml-2 btn-light"
              >
                View All
              </Button>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Payouts;
