import { useNavigate } from 'react-router';
import React, { useContext, useEffect } from 'react';
import { Button, Card, CardBody, Col } from 'reactstrap';

import Loader from '../common/Loader';
import FalconCardHeader from '../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap/lib';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { getNurses } from '../../redux/actions/nurseActions';
import { TestContext } from '../../context/Context';
import { employeeRoutes } from '../../routes';
import WorkersExportButton from '../common/WorkersExportButton';

const anchorFormatter = (name, { id }) => (
  <a href={`/users/${id}`}>
    <b>{name}</b>
  </a>
);

const phoneFormatter = (phone) => <a href={`tel:${phone}`}>{phone}</a>;

const actionFormatter = (dataField, { id }) => (
  // Control your row with this id
  <UncontrolledDropdown>
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
      <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right className="border py-2">
      <DropdownItem onClick={() => console.log('Edit: ', id)}>Edit</DropdownItem>
      <DropdownItem onClick={() => console.log('Delete: ', id)} className="text-danger">
        Delete
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const columns = [
  {
    dataField: 'firstName',
    text: 'Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: anchorFormatter,
    sort: true,
  },
  {
    dataField: 'lastName',
    text: 'Last Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'streetAddress',
    headerClasses: 'border-0',
    text: 'Address',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'city',
    headerClasses: 'border-0',
    text: 'City',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'state',
    headerClasses: 'border-0',
    text: 'State',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'phoneNumber',
    headerClasses: 'border-0',
    text: 'Phone',
    classes: 'border-0 py-2 align-middle',
    formatter: phoneFormatter,
    sort: true,
  },
  {
    dataField: '',
    headerClasses: 'border-0',
    text: '',
    classes: 'border-0 py-2 align-middle',
    formatter: actionFormatter,
    align: 'right',
  },
];

const Nurses = ({ showLoadMore = true }) => {
  const { nurses, loading, hasMore } = useSelector((state) => state.nurse);
  const { isTest } = useContext(TestContext);
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNurses(isTest));
  }, [dispatch, isTest]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Card>
      <FalconCardHeader title="Nurses">
        <WorkersExportButton />
      </FalconCardHeader>
      <CardBody className="fs--1 p-0">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={showLoadMore ? nurses : nurses.slice(0, 5)}
          columns={columns}
          bordered={false}
          classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
        />
        <Row noGutters className="px-1 py-3  d-flex justify-content-center">
          <Col xs="auto" className="pr-3">
            {showLoadMore ? (
              <Button
                size="sm"
                onClick={() => {
                  dispatch(getNurses(isTest));
                }}
                className="px-4 ml-2 btn-light"
                disabled={!hasMore || loading}
              >
                {loading ? 'Loading...' : hasMore ? 'Load More' : 'No more items'}
              </Button>
            ) : (
              <Button
                size="sm"
                onClick={() => {
                  history.push(employeeRoutes.to);
                }}
                className="px-4 ml-2 btn-light"
              >
                View All
              </Button>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Nurses;
