import React from 'react';
import { useState } from 'react';
import ButtonIcon from './ButtonIcon';
import { generateInvoice } from '../../api/facility';
import { toast } from 'react-toastify';
import { FormGroup, ModalFooter, ModalBody, Modal, ModalHeader, CustomInput } from 'reactstrap';
import { Button } from '@chakra-ui/react';
import moment from 'moment-timezone';

const getOptions = () => {
  const weekStartDate = moment(new Date()).tz('America/Chicago');
  weekStartDate.date(weekStartDate.date() - (weekStartDate.day() % 7) - 7);
  weekStartDate.hour(0);
  weekStartDate.minutes(0);
  weekStartDate.seconds(0);
  const weekStartDateFormatted = moment(weekStartDate).format('MM/DD/YYYY');

  const weekEndDate = moment(new Date()).tz('America/Chicago');
  weekEndDate.date(weekEndDate.date() - ((weekEndDate.day() + 1) % 7));
  weekEndDate.hours(0);
  weekEndDate.minutes(0);
  weekEndDate.seconds(0);
  const weekEndDateFormatted = moment(weekEndDate).format('MM/DD/YYYY');

  const twoWeeksAgoStart = weekStartDate.clone();
  twoWeeksAgoStart.date(twoWeeksAgoStart.date() - 7);
  const twoWeeksAgoStartFormatted = moment(twoWeeksAgoStart).format('MM/DD/YYYY');

  const twoWeeksAgoEnd = weekEndDate.clone();
  twoWeeksAgoEnd.date(twoWeeksAgoEnd.date() - 7);
  const twoWeeksAgoEndFormatted = moment(twoWeeksAgoEnd).format('MM/DD/YYYY');

  return {
    lastWeek: {
      label: `${weekStartDateFormatted} - ${weekEndDateFormatted}`,
      value: {
        startDate: moment(weekStartDate).format('YYYY-MM-DD'),
        endDate: moment(weekEndDate).format('YYYY-MM-DD'),
      },
    },
    twoWeeksAgo: {
      label: `${twoWeeksAgoStartFormatted} - ${twoWeeksAgoEndFormatted}`,
      value: {
        startDate: moment(twoWeeksAgoStart).format('YYYY-MM-DD'),
        endDate: moment(twoWeeksAgoEnd).format('YYYY-MM-DD'),
      },
    },
  };
};

const options = getOptions();

const InvoiceModal = ({ isOpen, setIsOpen, facilityId }) => {
  const toggle = () => setIsOpen(!isOpen);

  const [loading, setLoading] = useState(false);
  const [datesRadio, setDatesRadio] = useState('lastWeek');

  const resetState = () => {
    setDatesRadio('lastWeek');
  };
  const save = async () => {
    try {
      setLoading(true);
      await generateInvoice({ facilityId, ...options[datesRadio].value });
      toast.success('Successfully generated invoice for last week');
      resetState();
      setIsOpen(false);
    } catch (e) {
      toast.error(e.message);
    }
    setLoading(false);
  };

  const formIsValid = Boolean(datesRadio);

  const onChange = (evt) => {
    setDatesRadio(evt.target.id);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Choose invoice dates</ModalHeader>
      <ModalBody>
        <FormGroup tag="fieldset">
          <div>
            <CustomInput
              checked={datesRadio === 'twoWeeksAgo'}
              onChange={onChange}
              type="radio"
              label={options.twoWeeksAgo.label}
              id="twoWeeksAgo"
              name="dates"
            />
            <CustomInput
              checked={datesRadio === 'lastWeek'}
              onChange={onChange}
              type="radio"
              label={options.lastWeek.label}
              id="lastWeek"
              name="dates"
            />
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button colorScheme="blue" isLoading={loading} disabled={!formIsValid} onClick={() => save()}>
          Generate Invoice
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default function GenerateInvoiceButton({ facilityId }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ButtonIcon
        onClick={() => setIsOpen(true)}
        icon="file-invoice-dollar"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        className="ml-2"
      >
        Generate Invoice
      </ButtonIcon>
      <InvoiceModal isOpen={isOpen} setIsOpen={setIsOpen} facilityId={facilityId} />
    </>
  );
}
