import { getUserRef } from '../../api/user';
import { UserType } from '../../constants';
import {
  NURSE_HAS_MORE,
  NURSE_LIMIT,
  NURSE_LIST_FAIL,
  NURSE_LIST_REQUEST,
  NURSE_LIST_SUCCESS,
} from '../constants/nurseConstants';

export const getNurses = (isTest) => async (dispatch, getState) => {
  dispatch({
    type: NURSE_LIST_REQUEST,
  });
  try {
    let nursesSnap;
    const { nurses, isTest: stateIsTest } = getState().nurse;
    if (nurses.length && nurses[nurses.length - 1] && stateIsTest === isTest) {
      nursesSnap = await getUserRef()
        .where('status', '==', 'active')
        .where('userType', '==', UserType.WORKER)
        .where('isTest', '==', isTest)
        .orderBy('firstName')
        .orderBy('lastName')
        .startAfter(nurses[nurses.length - 1].firstName, nurses[nurses.length - 1].lastName)
        .limit(NURSE_LIMIT)
        .get();
    } else {
      nursesSnap = await getUserRef()
        .where('status', '==', 'active')
        .where('userType', '==', UserType.WORKER)
        .where('isTest', '==', isTest)
        .orderBy('firstName')
        .orderBy('lastName')
        .limit(NURSE_LIMIT)
        .get();
    }
    const nursesArr = [];
    nursesSnap.forEach((snapChild) => {
      const data = {
        ...snapChild.data(),
        id: snapChild.id,
      };
      nursesArr.push(data);
    });
    dispatch({
      type: NURSE_LIST_SUCCESS,
      payload: {
        nurses: nursesArr,
        isTest,
      },
    });
    if (nursesArr.length < NURSE_LIMIT) {
      dispatch({
        type: NURSE_HAS_MORE,
        payload: false,
      });
    } else {
      dispatch({
        type: NURSE_HAS_MORE,
        payload: true,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: NURSE_LIST_FAIL,
    });
  }
};
