import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { db } from '../../firebase';
import { Collection, FacilityAdmin, UserStatus, WithId } from '../../types';
import FalconCardHeader from './FalconCardHeader';
import { getAuthUser } from '../../api/auth';
import { Button, IconButton, Tooltip } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { functions } from '../../firebase';

type DecoratedFacilityAdmin = WithId<FacilityAdmin> & {
  email: string;
};

interface FacilityAdminsTableWrapperProps {
  facilityId: string | undefined;
  facilityAdminIds: string[] | undefined;
}
interface FacilityAdminsTableProps {
  facilityId: string;
  facilityAdmins: DecoratedFacilityAdmin[];
}

const timeFormatter = (time: firebase.firestore.Timestamp | undefined) => (
  <span>{time?.toDate().toLocaleString()}</span>
);

const ResendFacilityAdminPassword = ({ uid, row }: any) => {
  const [loadingResend, setLoadingResend] = useState(false);
  const sendResetPassword = async () => {
    setLoadingResend(true);
    try {
      const resendFn = functions.httpsCallable('resendPasswordRequest');
      await resendFn({
        userId: uid,
      });
      toast.success('Successfully sent reset password email');
    } catch (e) {
      toast.error('Failed to send reset password email');
      console.error(e);
    } finally {
      setLoadingResend(false);
    }
  };
  return (
    <Button isLoading={loadingResend} size="sm" onClick={sendResetPassword}>
      Reset Password
    </Button>
  );
};

const RemoveFacilityAdmin = ({ uid, row }: any) => {
  const [loading, setLoading] = useState(false);
  const removeAdmin = async () => {
    setLoading(true);
    try {
      const removeFacilityAdminFn = functions.httpsCallable('removeFacilityAdmin');
      await removeFacilityAdminFn({
        userId: uid,
      });
      toast.success('Successfully removed facility admin');
    } catch (e) {
      toast.error('Failed to remove facility admin');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Tooltip placement="top" label="Remove facility admin">
      <IconButton
        isLoading={loading}
        size="sm"
        aria-label="remove admin"
        onClick={removeAdmin}
        colorScheme="red"
        icon={
          <FontAwesomeIcon
            icon="times"
            transform="shrink-4"
            style={{
              position: 'absolute',
            }}
          />
        }
      />
    </Tooltip>
  );
};

const resetPasswordFormatter = (uid: any, row: any) => <ResendFacilityAdminPassword uid={uid} row={row} />;
const removeAdminFormatter = (uid: any, row: any) => <RemoveFacilityAdmin uid={uid} row={row} />;

const columns = () => {
  const firstName = {
    dataField: 'firstName',
    text: 'First Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
  };
  const lastName = {
    dataField: 'lastName',
    text: 'Last Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
  };
  const email = {
    dataField: 'email',
    headerClasses: 'border-0',
    text: 'Email',
    classes: 'border-0 py-2 align-middle',
  };
  const createdAt = {
    dataField: 'createdAt',
    headerClasses: 'border-0',
    text: 'Accepted Invite',
    classes: 'border-0 py-2 align-middle',
    formatter: timeFormatter,
  };
  const passwordReset = {
    dataField: 'id',
    text: '',
    headerClasses: 'border-0',
    formatter: resetPasswordFormatter,
    classes: 'border-0 py-2 align-right',
    align: 'right',
  };
  const removeAdmin = {
    dataField: 'id',
    text: '',
    headerClasses: 'border-0',
    formatter: removeAdminFormatter,
    classes: 'border-0 py-2 align-right',
    align: 'right',
  };
  return [firstName, lastName, email, createdAt, passwordReset, removeAdmin];
};

const FacilityAdminsTable = ({ facilityId, facilityAdmins }: FacilityAdminsTableProps) => {
  return (
    <>
      <FalconCardHeader title="Facility Admins"></FalconCardHeader>
      <CardBody>
        <p>These users (as well as the facility account owner) have full access to the facility page</p>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={facilityAdmins}
          columns={columns()}
          bordered={false}
          classes="table-dashboard table-striped table-md fs--1 border-bottom border-200 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
        />
      </CardBody>
    </>
  );
};

const FacilityAdminsTableWrapper = ({ facilityId, facilityAdminIds }: FacilityAdminsTableWrapperProps) => {
  const [facilityAdmins, setFacilityAdmins] = useState<DecoratedFacilityAdmin[] | undefined>();

  useEffect(() => {
    if (!facilityAdminIds) {
      return;
    }
    const getFacilityAdmins = async () => {
      const admins = await Promise.all(
        facilityAdminIds.map((id) => {
          return new Promise(async (resolve: (admin: DecoratedFacilityAdmin) => void) => {
            const admin = await db.collection(Collection.USER).doc(id).get();
            const authUser = await getAuthUser(id);
            resolve({ ...(admin.data() as FacilityAdmin), id, email: authUser.data.email });
          });
        })
      );
      setFacilityAdmins(admins.filter((admin) => admin.status !== UserStatus.INACTIVE));
    };
    getFacilityAdmins();
  }, [facilityAdminIds]);

  if (!facilityId || !facilityAdmins) {
    return null;
  }
  return <FacilityAdminsTable facilityId={facilityId} facilityAdmins={facilityAdmins} />;
};

export default FacilityAdminsTableWrapper;
