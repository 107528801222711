import { getFacilityRef } from '../../api/facility';
import { FACILITY_LIST_FAIL, FACILITY_LIST_REQUEST, FACILITY_LIST_SUCCESS } from '../constants/facilityConstants';

const getFacilitiesFromDb = async (isTest) => {
  let facilitiesSnap = getFacilityRef()
    .orderBy('facilityName')
    .where('isTest', '==', isTest)
    .where('status', '==', 'active');
  facilitiesSnap = await facilitiesSnap.get();
  const facilitiesArr = [];
  facilitiesSnap.forEach((snapChild) => {
    const data = {
      ...snapChild.data(),
      id: snapChild.id,
    };
    facilitiesArr.push(data);
  });
  return facilitiesArr;
};

export const getFacilities = (isTest) => async (dispatch, getState) => {
  dispatch({
    type: FACILITY_LIST_REQUEST,
  });
  try {
    const facilitiesArr = await getFacilitiesFromDb(isTest);
    dispatch({
      type: FACILITY_LIST_SUCCESS,
      payload: {
        facilities: facilitiesArr,
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: FACILITY_LIST_FAIL,
    });
  }
};
