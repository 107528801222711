import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../../firebase';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Input, CustomInput } from 'reactstrap';
import { toast } from 'react-toastify';
import ButtonIcon from './ButtonIcon';
import Datetime from 'react-datetime';
import { getFacilities } from '../../redux/actions/facilityActions';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { NurseType, AdditionalRequirements } from '../../constants';
import moment from 'moment-timezone';
import { createShift } from '../../api/shifts';
import { TestContext } from '../../context/Context';
import { facilityShouldShowMedpass } from '../../helpers/utils';
import WorkerSelect from './WorkerSelect';

const FacilityInput = ({ facilityId, setFacilityId }) => {
  const dispatch = useDispatch();
  const { isTest } = useContext(TestContext);
  useEffect(() => {
    dispatch(getFacilities(isTest));
  }, [dispatch, isTest]);
  const { facilities } = useSelector((state) => state.facility);

  const options = facilities.map((facility) => ({ value: facility.id, label: facility.facilityName }));
  return (
    <Select
      value={facilityId}
      onChange={(value) => setFacilityId(value)}
      classNamePrefix="react-select"
      options={options}
      className="form-control"
      styles={{ container: (provided, state) => ({ ...provided, height: '40px', padding: '0px' }) }}
    />
  );
};

const Required = () => <span style={{ color: 'red' }}>&nbsp;*</span>;

const defaultState = {
  startDate: undefined,
  endDate: undefined,
  description: undefined,
  nurseType: NurseType.CAREGIVER,
  facilityId: undefined,
  shiftTemplateIndex: null,
  additionalNotes: undefined,
  requiresMedPass: false,
  requiresMedPassDisabled: false,
  workerId: '',
  numberOfShifts: '1',
};

function CreateShiftModal({ open, setOpen, saveCallback }) {
  const toggle = () => setOpen(!open);
  const { isTest } = useContext(TestContext);

  const [startDate, setStartDate] = useState(defaultState.startDate);
  const [endDate, setEndDate] = useState(defaultState.endDate);
  const [description, setDescription] = useState(defaultState.description);
  const [nurseType, setNurseType] = useState(defaultState.nurseType);
  const [facilityId, setFacilityId] = useState(defaultState.facilityId);
  const [shiftTemplateIndex, setShiftTemplateIndex] = useState(defaultState.shiftTemplateIndex);
  const [additionalNotes, setAdditionalNotes] = useState(defaultState.additionalNotes);
  const [numberOfShifts, setNumberOfShifts] = useState(defaultState.numberOfShifts);
  const [requiresMedPass, setRequiresMedPass] = useState(defaultState.requiresMedPass);
  const [requiresMedPassDisabled, setRequiresMedPassDisabled] = useState(defaultState.requiresMedPassDisabled);
  const [workerId, setWorkerId] = useState(defaultState.workerId);
  const { facilities } = useSelector((state) => state.facility);

  const facility = facilities.find((f) => f.id === facilityId?.value);
  const tz = facility?.tz || null;
  const facilityShiftTemplates = facility?.shiftTemplates;
  useEffect(() => {
    if (facilityShiftTemplates && facilityShiftTemplates.length) {
      setShiftTemplateIndex(0);
    }
  }, [facilityShiftTemplates]);

  const resetFormState = () => {
    setStartDate(defaultState.startDate);
    setEndDate(defaultState.endDate);
    setDescription(defaultState.description);
    setNurseType(defaultState.nurseType);
    setFacilityId(defaultState.facilityId);
    setShiftTemplateIndex(defaultState.shiftTemplateIndex);
    setAdditionalNotes(defaultState.additionalNotes);
    setRequiresMedPass(defaultState.requiresMedPass);
    setRequiresMedPassDisabled(defaultState.requiresMedPassDisabled);
  };

  const save = async () => {
    let start = startDate.toDate();
    let end;
    if (shiftTemplateIndex !== null && shiftTemplateIndex >= 0) {
      const shiftTemplate = facility.shiftTemplates[shiftTemplateIndex];
      start.setHours(shiftTemplate.startTime.toDate().getHours());
      start.setMinutes(shiftTemplate.startTime.toDate().getMinutes());
      end = new Date(start);
      end.setHours(shiftTemplate.endTime.toDate().getHours());
      end.setMinutes(shiftTemplate.endTime.toDate().getMinutes());
      if (end < start) {
        end.setDate(start.getDate() + 1);
      }
    } else if (tz) {
      end = moment(endDate.toDate()).tz(tz, true).toDate();
      start = moment(startDate.toDate()).tz(tz, true).toDate();
    } else {
      end = endDate.toDate();
    }
    const shift = {
      description: description,
      start,
      end,
      numberOfNurse: 1,
      nurseType,
      facilityIdentifier: facilityId.value,
      isFree: true,
      time: new Date().valueOf(),
      additionalRequirements: [],
      createdBy: auth.currentUser?.uid,
      isTest,
      numberOfShifts: Number(numberOfShifts),
    };
    if (additionalNotes) {
      shift.additionalnotes = additionalNotes;
    }
    if (requiresMedPass) {
      shift.additionalRequirements.push(AdditionalRequirements.MED_PASS);
    }
    if (facility.requiresCovidVaccination) {
      shift.additionalRequirements.push(AdditionalRequirements.COVID_VACCINATION);
    }
    if (workerId) {
      shift.isFree = false;
      shift.nurseId = workerId;
      shift.addedWorkerByAdmin = auth.currentUser?.uid;
    }
    try {
      await createShift(shift, facility);
      saveCallback({ wasSuccessful: true, errMsg: '' });
      toggle();
      resetFormState();
    } catch (e) {
      saveCallback({ wasSuccessful: false, errMsg: e.message });
    }
  };

  useEffect(() => {
    if ([NurseType.LPN, NurseType.RN].includes(nurseType)) {
      setRequiresMedPass(true);
      setRequiresMedPassDisabled(true);
    }
    if ([NurseType.CNA, NurseType.CAREGIVER].includes(nurseType)) {
      setRequiresMedPass(false);
      setRequiresMedPassDisabled(false);
    }
  }, [nurseType]);

  const formIsValid =
    description &&
    facilityId &&
    nurseType &&
    startDate &&
    Number(numberOfShifts) > 0 &&
    (endDate || (shiftTemplateIndex !== null && shiftTemplateIndex >= 0));

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Create New Shift</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="fs-0" for="shiftFacility">
            Facility
            <Required />
          </Label>
          <FacilityInput facilityId={facilityId} setFacilityId={setFacilityId} />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="shiftDescription">
            Description
            <Required />
          </Label>
          <Input
            className="form-control"
            placeholder="caregiver"
            value={description}
            onChange={({ target }) => setDescription(target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="eventNurseType">
            Nurse Type
            <Required />
          </Label>
          <CustomInput
            required
            type="select"
            id="eventNurseType"
            name="nurseType"
            onChange={({ target }) => setNurseType(target.value)}
          >
            <option value={NurseType.CAREGIVER}>{NurseType.CAREGIVER}</option>
            <option value={NurseType.CNA}>{NurseType.CNA}</option>
            <option value={NurseType.LPN}>{NurseType.LPN}</option>
            <option value={NurseType.RN}>{NurseType.RN}</option>
          </CustomInput>
        </FormGroup>
        {facilityShouldShowMedpass(facility) && (
          <FormGroup className="form-check">
            <Input
              type="checkbox"
              id="requiresMedPass"
              name="requiresMedPass"
              checked={requiresMedPass}
              disabled={requiresMedPassDisabled}
              onChange={({ target }) => setRequiresMedPass(target.checked)}
            />
            <Label check>Requires Med Pass</Label>
          </FormGroup>
        )}
        {facility?.shiftTemplates && (
          <FormGroup>
            <Label for="shiftTemplate">
              Shift Template
              <Required />
            </Label>
            <CustomInput
              required
              type="select"
              id="shiftTemplate"
              name="shiftTemplate"
              onChange={({ target }) => setShiftTemplateIndex(parseInt(target.value))}
            >
              {facility.shiftTemplates.map((t, index) => (
                <option key={index} value={index}>
                  {t.name}
                </option>
              ))}
              <option value={-1}>Custom</option>
            </CustomInput>
          </FormGroup>
        )}

        {facility?.shiftTemplates && shiftTemplateIndex >= 0 && (
          <FormGroup>
            <Label className="fs-0" for="shiftStart">
              Start Date
              <Required />
            </Label>
            <Datetime
              timeFormat={false}
              value={startDate}
              onChange={(date) => {
                if (date._isValid) {
                  setStartDate(date);
                }
              }}
              dateFormat="MM-DD-YYYY"
              inputProps={{ required: true, placeholder: 'MM-DD-YYYY', id: 'shiftStart' }}
            />
          </FormGroup>
        )}
        {(!facility?.shiftTemplates || shiftTemplateIndex === -1) && (
          <>
            <FormGroup>
              <Label className="fs-0" for="shiftStart">
                Start
                <Required />
              </Label>
              <Datetime
                timeFormat={true}
                value={startDate}
                onChange={(dateTime) => {
                  if (dateTime._isValid) {
                    setStartDate(dateTime);
                  }
                }}
                dateFormat="MM-DD-YYYY"
                inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'shiftStart' }}
              />
            </FormGroup>
            <FormGroup>
              <Label className="fs-0" for="shiftEnd">
                End
                <Required />
              </Label>
              <Datetime
                timeFormat={true}
                value={endDate}
                onChange={(dateTime) => {
                  if (dateTime._isValid) {
                    setEndDate(dateTime);
                  }
                }}
                dateFormat="MM-DD-YYYY"
                inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:m', id: 'shiftEnd' }}
              />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label className="fs-0" for="shiftAdditionalNotes">
            Number of shifts
          </Label>
          <Input
            type="number"
            className="form-control"
            value={numberOfShifts}
            onChange={({ target }) => setNumberOfShifts(target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="shiftAdditionalNotes">
            Additional Notes
          </Label>
          <Input
            type="textarea"
            className="form-control"
            placeholder="Covid vaccine required..."
            value={additionalNotes}
            onChange={({ target }) => setAdditionalNotes(target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="assignedWorker">
            Assigned Worker
          </Label>
          <WorkerSelect workerId={workerId} setWorkerId={setWorkerId} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!formIsValid} color="primary" onClick={() => save()}>
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default function CreateShiftButton({ data = [], fields = [] }) {
  const [isOpen, setIsOpen] = useState(false);

  const saveCallback = (status) => {
    if (status.wasSuccessful) {
      toast.success('New shift was created successfully!', { position: 'bottom-right' });
    } else if (status.wasSuccessful === false) {
      toast.error(`There was an error creating shift: ${status.errMsg}`, { position: 'bottom-right' });
    }
  };
  return (
    <>
      <ButtonIcon
        onClick={() => {
          setIsOpen(true);
        }}
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        className="ml-2 d-flex align-items-center"
        style={{ marginRight: '10px' }}
      >
        Create New
      </ButtonIcon>
      <CreateShiftModal open={isOpen} setOpen={setIsOpen} saveCallback={saveCallback} />
    </>
  );
}
