export const AdditionalCapabilities = {
  MED_PASS: 'MeD Pass',
  COVID_VACCINATION: 'COVID Vaccination',
};

export const AdditionalRequirements = {
  MED_PASS: 'MeD Pass',
  COVID_VACCINATION: 'COVID Vaccination',
};

export const NurseType = {
  CAREGIVER: 'Caregiver',
  CNA: 'CNA',
  LPN: 'LPN',
  RN: 'RN',
};

export const states = ['IL', 'MN', 'WI', 'MO'];

export const ShiftReviewQuestion = {
  RATING: 'rating',
  ON_TIME: 'onTime',
  ADDITIONAL_COMMENTS: 'additionalComments',
};

export const shiftReviewQuestions = {
  [ShiftReviewQuestion.RATING]: {
    text: 'How would you rate your PT Nurse healthcare professional for this shift?',
    type: 'numericScale',
    bounds: [1, 5],
    required: true,
  },
  [ShiftReviewQuestion.ON_TIME]: {
    text: 'Was your PT Nurse healthcare professional on time?',
    required: true,
    type: 'boolean',
  },
  [ShiftReviewQuestion.ADDITIONAL_COMMENTS]: {
    text: 'Is there anything else you want to share about this shift?',
    required: false,
    type: 'textarea',
  },
};

export const shiftReviewQuestionsOrder = ['rating', 'onTime', 'additionalComments'];

export const Collection = {
  CHATS: 'chats',
  COMPANIES: 'companies',
  FACILITIES: 'facilities',
  INVOICES: 'invoices',
  PAYOUTS: 'payouts',
  REVIEWS: 'reviews',
  SHIFT_IMPORTS: 'shift_imports',
  SHIFTDISCONTENTS: 'shiftdiscontents',
  SHIFTS: 'shifts',
  STATS: 'stats',
  STRIPE_ACCOUNTS: 'stripe_accounts',
  STRIPE_CUSTOMERS: 'stripe_customers',
  USER: 'user',
};

export const ReviewerType = {
  FACILITY: 'facility',
};

export const RevieweeType = {
  WORKER: 'worker',
};

export const UserType = {
  WORKER: 'worker',
  ADMIN: 'admin',
  FACILITY: 'facility',
};
